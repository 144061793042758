<!--
    Copyright Compunetix Incorporated 2017-2024
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author: Ramadevi Gavirneni
  -->
<div class="operator-info-container">
  <div class="operator-info">
    <div *ngIf="endpoint?.theme">
      <p><span><b>Page Style:</b></span> {{endpoint?.theme}}</p>
    </div>
    <div *ngIf="endpoint?.language">
      <p><span><b>Language:</b></span> {{endpoint?.language}}</p>
    </div>
    <div *ngIf="endpoint?.skillSet">
      <p><span><b>Categories:</b></span> {{getSkillCategoryString(endpoint.skillSet)}}</p>
      <p><span><b>Languages:</b></span> {{getSkillLanguageString(endpoint.skillSet)}}</p>
      <p><span><b>In conference with:</b></span> {{getTargetEndpointsList(endpoint)}}</p>
      <p><span><b>Held parties:</b></span> {{getHoldEndpointsList(endpoint)}}</p>
    </div>
    <div *ngIf="!endpoint?.skillSet">
      <p><span><b>No Queue Data</b></span></p>
    </div>
    <div *ngIf="statsEps?.length > 1">
      <span><b>Connection Quality:</b></span><br />
      <ng-container *ngFor="let endpoint of statsEps; trackBy: trackByEndpoint">
        <div *ngIf="connectionInfoHtml(endpoint)">
          <span style='margin-left: .5rem' ><b>{{endpoint.uiName | safeHtml}}</b></span>
          <span *ngIf="localizationService.myLocalizationData?.diagnostic_panel?.infoDialogQualityGauges">
            <i class="fas fa-arrow-down"> In:</i>
            <signal-bars
            [quality]="epCallQualityInboundValue(endpoint)">
            </signal-bars></span>
          <span *ngIf="localizationService.myLocalizationData?.diagnostic_panel?.infoDialogQualityGauges">
            <i class="fas fa-arrow-up"> Out:</i>
            <signal-bars
            [quality]="epCallQualityOutboundValue(endpoint)">
            </signal-bars></span>
          <p style='margin-left: .5rem' [innerHTML]="connectionInfoHtml(endpoint) | trustHtml"></p>
        </div>
      </ng-container>
    </div>
    <div *ngIf="statsEps?.length == 1">
      <ng-container *ngFor="let endpoint of statsEps; trackBy: trackByEndpoint">
        <div *ngIf="connectionInfoHtml(endpoint)">
          <span><b>Connection Quality:</b></span>
          <br />
          <span>
            <i class="fas fa-arrow-down"> In:</i>
            <signal-bars
            [quality]="epCallQualityInboundValue(endpoint)">
            </signal-bars>
          </span>
          <span>
            <i class="fas fa-arrow-up"> Out:</i>
            <signal-bars
            [quality]="epCallQualityOutboundValue(endpoint)">
            </signal-bars>
          </span>
          <br />
          <p style='margin-left: .5rem; margin-right: .5rem' [innerHTML]="connectionInfoHtml(endpoint) | trustHtml"></p>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf='canSendLogs' class="operator-tools">
    <button
      *ngIf="canSendLogs"
      tabindex
      [title]="'Send Logs'"
      class="btn btn-primary"
      (click)="sendLogs()">
      Send Logs
    </button>
  </div>
  <div>
    <span><p style='margin-left: .1rem'>{{getOperatorIdentString(endpoint?.rtcId, endpoint?.twilioCallId)}}</p></span>
  </div>

</div>
