/**
 * Copyright Compunetix Incorporated 2017-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IRTCServiceStreamEventHandler } from "../services/rtc.service.stream-event-handler.interface";
import { IConferenceService } from "./conference.service.interface";
import { ConferenceService } from "./conference.service";
import { IEndpoint, PresenceStatus } from "../endpoint/endpoint.interface";
import { IEndpointService } from "../endpoint/endpoint.service.interface";
import { EndpointService } from "../endpoint/endpoint.service";
import { VideoMediaConnectionMode } from "../services/rtc.client.interface";
import { EasyRTCService } from "../services/rtc.service.easy";
import { Endpoint } from "../endpoint/endpoint";
import { AlertCode} from "../alert/alert.interface";
import { ActiveConference, ConferenceUtil, ExpandedActiveConference } from "./conference";
import { IExpandedActiveConference } from "./conference.interface";
export class RTCServiceStreamEventHandler implements IRTCServiceStreamEventHandler {
  constructor(
    private endpointService: IEndpointService = EndpointService.getSharedInstance(),
    private conferenceService: IConferenceService = ConferenceService.getSharedInstance(),
  ) {
    // nothing needed here
  }

  /**
   * dict to cache track muted status
   */
  private trackMutedStatus: {[id: string]: boolean} = {};

  /**
   * timer to delay the remote stream update
   */
  private delayUpdateRemoteStreamTimer: {[id: string]: any} = {};

  get activeConf(): ExpandedActiveConference {
    return this.conferenceService.currentActiveConference;
  }

  /**
   * update remote stream on delay
   */
  updateRemoteStreamOnDelay(rtcId, track, seconds = 1) {
    if (this.delayUpdateRemoteStreamTimer[track.id]) {
      return;
    }
    this.delayUpdateRemoteStreamTimer[track.id] = setTimeout(() => {
      this.delayUpdateRemoteStreamTimer[track.id] = clearTimeout(this.delayUpdateRemoteStreamTimer[track.id]);
      if (this.trackMutedStatus[track.id] != track.muted) {
        this.trackMutedStatus[track.id] = track.muted;
        this.updateRemoteStreamListener(rtcId);
      }
    }, seconds * 1000);
  }

  /**
   * Handler on stream received
   * @param rtcId: string - the rtcId of whom sending the stream
   * @param stream: any - stream received
   */
  streamAcceptListener(rtcId: string, stream: any) {
    this.conferenceService.alertHandler(
      AlertCode.streamReceived, JSON.stringify({ rtcId: rtcId, streamName: stream ? stream.streamName : null })
    );
    stream.onremovetrack = ({track}) => {
      this.streamClosedListener(rtcId, stream);
    };
    stream.getTracks().forEach((track) => {
      this.trackMutedStatus[track.id] = track.muted;
      
      track.onmute = (event) => {
        // this may be a temporary network inturruption, so delay removing it from the scene
        //this.updateRemoteStreamOnDelay(rtcId, track, 3);
      };
      
      track.onunmute = (event) => {
        // this may be a new stream or a recover from a temporary network inturruption, either way, add it to the scene immediately.
        this.updateRemoteStreamOnDelay(rtcId, track, 0);
      };
    });
    let ep = this.endpointService.getEndpointById(rtcId);
    if (!ep) {
        // must need to create the endpoint based on rtcId
      let ep: IEndpoint = new Endpoint(rtcId);
      // Not sure what we are doing with this yet, set transmit mode to None
      ep.transmitMode = VideoMediaConnectionMode.None;
      // Register it in hopes we might use it
      this.endpointService.create(ep);
    }

    let existingStreamCount = ep?.streams ? _.keys(ep.streams).length : 0;
    if (existingStreamCount === 0) {
      if (
        
        (EasyRTCService.getSharedInstance().rtcClient.videoMuted ||
        EasyRTCService.getSharedInstance().rtcClient.secondaryCameraEnabled ||
        EasyRTCService.getSharedInstance().rtcClient.screenShareEnabled)
      ) {
        let updateStreamToOthersTimer = setTimeout(() => {
          clearTimeout(updateStreamToOthersTimer);
          EasyRTCService.getSharedInstance().updateLocalStreamToOthers([rtcId]).catch((error: any) => {
            // Should be caught in updateLocalStreamToOthers
          });
        }, 1000);
      }
    }
    this.updateRemoteStreamListener(rtcId);
  }

  /**
   * Handler on stream lost
   * @param rtcId: string - the rtcId of whose stream lost
   * @param stream: any - stream lost
   */
  streamClosedListener(rtcId: string, stream: any) {
    this.conferenceService.alertHandler(AlertCode.streamClosed, JSON.stringify({ rtcId: rtcId, streamName: stream ? stream.streamName : null }));
    this.updateRemoteStreamListener(rtcId);
  }

  /**
   * handler on local stream change
   */
  updateLocalStreamListener(): void {
    this.endpointService.updateMyEndpointStreams();
    // The update of my endpoint streams cause the unmute of rtc channel that
    // has to be muted for voice calls
    if (ConferenceUtil.isVoiceConferenceRef(this.activeConf)) {
      EasyRTCService.getSharedInstance().muteMicrophone(true);
    }
    this.conferenceService.localVideoUpdateHandler();
    this.updateLocalStreamToOthers();
  }

  /**
   * handler on remote stream change
   */
  updateRemoteStreamListener(rtcId: string): void {
    this.endpointService.updateEndpointStreamsAccordingToReceivers(rtcId);
    this.conferenceService.emitRemoteVideoUpdateEvent();
  }

  /**
   * update local stream names with others
   */
  updateLocalStreamToOthers(): void {
    if (_.keys(EasyRTCService.getSharedInstance().rtcClient.defaultStreams).length > 0) {
      let others: IEndpoint[] = _.filter(this.activeConf?.active || [], (ep: IEndpoint) => {
        return ep.rtcId !== this.endpointService.myEndpoint.rtcId;
      });
      if (others.length > 0) {
        EasyRTCService.getSharedInstance().updateLocalStreamToOthers(_.map(_.filter(others, ep => !ep.isVoiceEp), "rtcId"))
        .then(() => {
          _.forEach(others, (ep: IEndpoint) => {
            if (ep.isVoiceEp) {
              this.endpointService.sendUpdateVoiceAudioDeviceToServer(ep.rtcId);
            }
          });
        }).catch((error: any) => {
          // Should be caught in updateLocalStreamToOthers
        });
      }
    }
  }

  /**
   * handler on remove local stream
   */
  removeLocalStreamListener(stream: any): void {
    this.endpointService.updateMyEndpointStreams();
    this.conferenceService.localVideoUpdateHandler();
    let others: IEndpoint[] = _.filter(this.activeConf.active, (ep: IEndpoint) => {
      return ep.rtcId !== this.endpointService.myEndpoint.rtcId;
    });
    if (others.length > 0) {
      EasyRTCService.getSharedInstance().removeStreamFromCalls(_.map(others, "rtcId"), stream);
    }
  }

  /**
   * handler on renegotiation required
   */
  renegotiationRequiredListener(): void {
    let others: IEndpoint[] = _.filter(this.activeConf.active, (ep: IEndpoint) => {
      return ep.rtcId !== this.endpointService.myEndpoint.rtcId;
    });
    _.forEach(others, (ep: IEndpoint) => {
      EasyRTCService.getSharedInstance().renegotiate(ep.rtcId);
    });
  }

  /**
   * handler on second channel started
   * @param rtcId: string - id of endpoint
   */
  remoteSecondChannelStarted(rtcId: string): void {
    let endpoint: IEndpoint = this.endpointService.getEndpointById(rtcId);
    if (endpoint) {
      endpoint.isPresenting = true;
      this.conferenceService.emitRemoteVideoUpdateEvent();
    }
  }

  /**
   * handler on second channel ended
   * @param rtcId: string - id of endpoint
   */
  remoteSecondChannelStopped(rtcId: string): void {
    let endpoint: IEndpoint = this.endpointService.getEndpointById(rtcId);
    if (endpoint) {
      endpoint.isPresenting = false;
      this.conferenceService.emitRemoteVideoUpdateEvent();
    }
  }
}
