<!--
    Copyright Compunetix Incorporated 2017-2024
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, amaggi, kbender
  -->
<div class="dialout-container">
  <div class="dialout-body">
    <form
      #dialOutForm="ngForm"
      autocomplete="off"
      class="dialout-form"
    >
      <div>
        <div class="form-group">
          <label
            for="nameInput"
            class="col-form-label"
          >
            Name:
          </label>
          <input
            type="text"
            class="form-control"
            id="nameInput"
            placeholder="(Optional)"
            [disabled]="myEndpoint.status === PresenceStatus.connecting"
            [(ngModel)]="name"
            name="nameInput"
          >
        </div>
        <div class="form-group">
          <label
            for="phoneInput"
            class="col-form-label"
          >
            Phone number:
          </label>
          <input
            type="tel"
            class="form-control"
            id="phoneInput"
            required
            [disabled]="myEndpoint.status === PresenceStatus.connecting"
            [(ngModel)]="phoneNumber"
            (ngModelChange)="checkNumber($event)"
            name="phoneInput"
          >
        </div>

        <div class="form-group"
          *ngIf="myEndpoint?.skillSet?.languages &&
                 myEndpoint.skillSet.languages.length > 1"
          [hidden]="(categoriesList?.length === 1 && myEndpoint.skillSet.languages.length === 1)">
          Language:
          <select id="skill-language"
                  name="skill-language"
                  class="form-control"
                  required
                  [disabled]="myEndpoint.status === PresenceStatus.connecting"
                  [(ngModel)]="currentSkillTags.language">
            <option
              *ngFor="let language of myEndpoint.skillSet.languages"
              [ngValue]="language">{{language}}</option>
          </select>
        </div>

        <div class="form-group"
          *ngIf="categoriesList?.length > 1"
          [hidden]="(categoriesList?.length === 1 && myEndpoint.skillSet?.languages.length === 1)">
          Category:
          <select id="skill-category"
                  name="skill-category"
                  class="form-control"
                  required
                  [disabled]="myEndpoint.status === PresenceStatus.connecting"
                  [(ngModel)]="currentSkillTags.category">
            <option
              *ngFor="let category of categoriesList"
              [ngValue]="category">{{category}}</option>
          </select>
        </div>
      </div>

      <div
        class="dialout-valid-number"
        *ngIf="parsedPhoneNumber && regionCode">
        Valid {{regionCode}} number
      </div>

      <div class="">
        <button *ngIf="myEndpoint.status !== PresenceStatus.connecting"
                class="btn btn-success btn-lg text-uppercase full-width"
                [disabled]="!dialOutForm.form.valid || !validPhoneNumber || submitted"
                (click)="dialOut()"
        >
          <i class="fas fa-phone"></i>
          Call
        </button>
        <button *ngIf="myEndpoint.status === PresenceStatus.connecting"
                class="btn btn-danger btn-lg text-uppercase full-width"
                [disabled]="voiceService.acceptedCall()"
                (click)="hangUp()"
        >
          <i class="fa  fa-phone-slash"></i>
          Hang up
        </button>
      </div>
    </form>

    <div class="dialout-keypad">
      <keypad
        [isKeyEnteredVisible]="false"
        [disabled]="myEndpoint.status === PresenceStatus.connecting"
        (pressedKey)="pressedKey($event)">
      </keypad>
    </div>
  </div>
  <div class="dialout-status">
    <div *ngIf="myEndpoint.status === PresenceStatus.connecting">Dialing {{internationalPhoneNumber}}<span class="dotdotdot"></span></div>
  </div>

</div>
