<!--
    Copyright Compunetix Incorporated 2024
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  jporter
-->
<div
  class="modal fade"
  id="passcodeModal"
  role="dialog"
  aria-labelledby="passcodeModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-body"
      [style.backgroundColor]="styleData?.backgroundColor"
      [style.color]="styleData?.textColor">
        <div class="container-fluid">
          <div class="row form-header">
            <div class="col-md-12 text-center">
              <a
                (click)="close()"
                aria-label="Close"
              >
                <i
                  class="fas fa-times"
                  aria-hidden="true"
                ></i><br>
                <label>
                  {{styleData.exitText}}
                </label>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {{styleData.modalTitle}}
              </h2>
            </div>
          </div>
          <passcode-form
            #passcodeForm
            (validate)="validate($event)"
            [styleData]="styleData"
          ></passcode-form>
        </div>
      </div>
    </div>
  </div>
</div>
