/**
 * Copyright Compunetix Incorporated 2020
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component } from "@angular/core";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { Alert, AlertLevel } from "companion";

@Component({
  selector: "login",
  templateUrl: "./login.component.html"
})
/**
 * login component
 */
export class LoginComponent {
  constructor() {}
  
  /**
   * user successful log in event handler
   */
  login(errorMessage: string): void {
    if (errorMessage) {
      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert(
          "FAIL_TO_LOGIN",
          errorMessage,
          AlertLevel.warning
        )
      });
      return;
    }
    Dispatcher.dispatch(ActionType.OpenDefaultScreen);
  }
}
