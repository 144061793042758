/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
/**
 * Class Cookie - Holds static functions to deal with Cookies
 */
export class Cookie {
  /**
   * Retrieves a single cookie by it's name
   *
   * @param  {string} name Identification of the Cookie
   * @returns The Cookie's value
   */
  public static getCookie(name: string): string {
    let myWindow: any = window;
    name = myWindow.escape(name);
    let regexp = new RegExp("(?:^" + name + "|;\\s*" + name + ")=(.*?)(?:;|$)", "g");
    let result = regexp.exec(document.cookie);
    return result === null ? null : myWindow.unescape(result[1]);
  }

  /**
   * Save the Cookie
   *
   * @param  {string} name Cookie's identification
   * @param  {string} value Cookie's value
   * @param  {number} expires Cookie's expiration date in days from now. If it's undefined the cookie is a session Cookie
   * @param  {string} path Path relative to the domain where the cookie should be avaiable. Default /
   * @param  {string} domain Domain where the cookie should be avaiable. Default current domain
   */
  public static setCookie(name: string, value: string, expires?: number, path?: string, domain?: string) {
    let myWindow: any = window;
    let cookieStr = myWindow.escape(name) + "=" + myWindow.escape(value) + ";";

    if (expires) {
      let dtExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
      cookieStr += "Expires=" + dtExpires.toUTCString() + ";";
    }

    if (path) {
      cookieStr += "Path=" + path + ";";
    }

    if (domain) {
      cookieStr += "Domain=" + domain + ";";
    }

    // secure cookie
    cookieStr += "Secure;";
    // http only
    cookieStr += "HttpOnly;"

    // samesite cookie
    cookieStr += "SameSite=Lax;";
    document.cookie = cookieStr;
  }
}
