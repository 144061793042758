/**
 * Copyright Compunetix Incorporated 2018-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender, jporter
 */
import { IReportDefinition } from "../report/report.interface";
import { ISkillSet, ISkillTags, ISkillsSet } from "../skills/skill.interface"
import { IVccConfig } from "../settings/config.interface";

export interface IGroup {
  // group name
  name: string;
  // timed expiration enabled
  expireEnabled?: boolean;
  // expire date
  expireDate?: Date;
  // themes
  themes?: string[];
  // kiosk unlock secret
  kioskUnlock?: string;
  // user uploaded theme package
  themePackage?: string;
  // parent id
  parentId?: string;
  // children_model this is ephemeral data generated at the time of fetching
  children_model?: IGroup[];
  // report definitions
  report_definitions?: { [key: string]: IReportDefinition };
  // message config
  messagingConfig?: IMessagingConfig;
  // message template
  messagingTemplate?: IMessagingTemplate;
  // the authentication confi
  AuthenticationConfig?: IAuthenticationConfig;
  // license key
  licenseKey?: string;
  // license data
  license?: ILicense;
  // max online operators count
  maxOpCount?: number;
  // online op count
  onlineOpCount?: number;
  // license expire or over limit alert
  licenseAlertEmails?: string[];
  // Twilio account
  twilioAccount?: ITwilioAccount;
  // license activation proof
  licenseProof?: string;
  // Supplementary to system level VCC configuration
  VccConfig?: IVccConfig;
  // the skills based routing queue config
  queueConfig?: IQueueConfig;
  // the remote desktop connections configured for the group.
  remoteDesktopConnection?: IRemoteDesktopConfig;
  // Third party API Inegration Settings
  thirdPartyAPIIntegration?: IThirdPartyAPIIntegration;
}

export interface IMessagingConfig {
  status?: IGroupStatus;
  TextApiKey?: string;
  TextApiSecret?: string;
  TextFromNumber?: string;
  AccountId?: string;
  AccountPassword?: string;
  SMSProvider?: string;
  SenderId?: string;

  EmailHost?: string;
  EmailPort?: number;
  EmailSecure?: boolean;
  EmailUser?: string;
  EmailPass?: string;
  EmailFrom?: string;
}

export interface IMessagingTemplate {
  status?: IGroupStatus;
  // create conference
  guestCreateEmailTemplate?: string;
  guestCreateEmailSubject?: string;
  // edit conference
  guestEditEmailTemplate?: string;
  guestEditEmailSubject?: string;
  // delete conference
  guestCancelEmailTemplate?: string;
  guestCancelEmailSubject?: string;
  // remind conference
  guestReminderEmailTemplate?: string;
  guestReminderEmailSubject?: string;
  // invitation
  guestInviteEmailTemplate?: string;
  guestInviteEmailSubject?: string;
  // host confirmation
  hostConfirmationEmailTemplate?: string;
  hostConfirmationEmailSubject?: string;
}

export interface IQueueConfig
{
  // the status of the queue config (inherited, disabled, custom)
  status?: IGroupStatus;
  // skill set for the queues
  skillSet?: ISkillsSet;
  // default skill tags for queue entry for entrants with unspecified tags
  skillTags?: ISkillTags;
}

export interface IQueueAccessData {
  groupId: string,
  themes: string[],
  queues: string[],
  defaultSkillTags: ISkillTags,
  overrideSkillSet: ISkillSet
}

export interface IAuthenticationConfig {
  passwordPolicyStatus?: IGroupStatus;
  passwordPolicy?: IPasswordPolicy;
  thirdPartyAuthConfigs?: IThirdPartyAuthConfig[];
  thirdPartyAuthStatus?: IGroupStatus;
  twoFactorConfig?: ITwoFactorConfig;
}

export interface IThirdPartyAuthConfig {
  provider?: ThirdPartyAuthProvider;
  protocol?: ThirdPartyAuthProtocol;
  authorizationUrl?: string;
  tokenUrl?: string;
  callbackUrl?: string;
  issuer?: string;
  clientID?: string;
  // loginUrl?: string;
  // logoutUrl?: string;
  // signatureAlgorithm?: string;
  cert?: string;
  secret?: string;
  defaultUrl?: string;
}

export enum ThirdPartyAuthProtocol {
  oauth2 = "oauth2" // ONLY ONE SUPPORTED PRESENTLY
}

export enum ThirdPartyAuthProvider {
  openidc = "openidc" // ONLY ONE SUPPORTED PRESENTLY
}

export interface ITwoFactorConfig {
  isRequired?: boolean;
}

export interface ILicense {
  name?: string;
  key?: string;
  expiry?: Date;
  uses?: number;
  suspended?: boolean;
  scheme?: string;
  encrypted?: boolean;
  strict?: boolean;
  floating?: boolean;
  concurrent?: boolean;
  protected?: boolean;
  maxMachines?: number;
  maxUses?: number;
  requireCheckIn?: boolean;
  lastCheckIn?: Date;
  nextCheckIn?: Date;
  metadata?: ILicenseMetadata;
  created?: Date;
  updated?: Date;
  isValid?: boolean;
}

export interface ILicenseMetadata {
  seedData: any;
  maxOnlineOperatorCount: number;
  featureList: any;
  reportDefinitions: any;
  messagingTemplate?: any;
  pstnAudioIntegrationEnabled?: boolean;
  ssoEnabled?: boolean;
  copyright?: any;
  appTitle?: any;
}

export enum Feature {
  Reservation,
  Voice,
  Theme,
  Config,
  Connector,
  StoredConference,
  ConferenceControl,
  Contact,
  Role,
  Group,
  User,
  Report
}

export enum RoleName {
  System_Admin,
  Group_Admin,
  VCC_Operator,
  VCC_Specialist,
  VCC_Supervisor,
  Basic
}

export interface IPasswordPolicy {
  // the minimum length of the passcode.
  minimumLength?: number;
  // the maximum length of the passcode.
  maximumLength?: number;
  // the mimimum number of uppercase letters.
  minimumUppercase?: number;
  // the mimimum number of lowercase letters.
  minimumLowercase?: number;
  // the mimimum number of numeric characters.
  minimumNumbers?: number;
  // the minimum number of special characters.
  minimumSpecialCharacters?: number;
  // A list of forbidden characters that cannot be used in a password.
  forbiddenCharacters?: string[];
  // enable password expiration
  enablePasswordExpiration?: boolean;
  // number of days a password is valid for.
  passwordValidDays?: number;
  // enable expiration warning
  enableExpirationWarning?: boolean;
  // number of days until you warn the user their password is going to expire.
  passwordWarnDays?: number;
  // force a user to reset their password upon first login.
  forceResetOnFirstLogin?: boolean;
  // enable lockouts
  enableLockout?: boolean;
  // lockout user after # of failed attempts
  failedAttemptsLockout?: number;
  // the duration of the lockout, in mins.
  lockoutDuration?: number;
  // whether to disable inactive accounts
  disableInactiveAccounts?: boolean;
  // how many days later to disable an inactive account
  disableInactiveDuration?: number;
  // whether we store old passwords
  storeOldPasswords?: boolean;
  // prevent reuse of last # of passwords, requires storeOldPasswords = true;
  preventReuseNumber?: number;
}

export interface ITwilioAccount {
  status?: IGroupStatus;
  accountSid?: string;
  authToken?: string;
  twimlAppSid?: string;
  phoneNumbers?: string[];
  edgeLocations?: string[];
}

export enum IGroupStatus {
  custom = "custom",
  disabled = "disabled",
  inherited = "inherited"
}

export interface IRemoteDesktopConfig
{
  enabled: boolean;

  secretkey: string;
  brokerConnectionString: string;
  remoteDesktopConnections : IRemoteDesktopConn[];
}

export interface IRemoteDesktopConn {
  /**
   * The identifier to map to the connection, the guest is responsible 
   * for supplying this to match a guest to these creds.
   */
  /**
   remoteIdentity: String;
   * The host of the remote system
   */
  hostname: String;
  /**
   * The username to connect
   */
  username: String;
  /**
   * The password to use on connection
   */
  password: String;
  /**
   * The portnumber to use for connections
   */
  port: number;
}

export interface IThirdPartyAPIIntegration {
  enabled?: boolean;
  googleMapsAPIKey?: string;
}

