/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

import {
  IEndpoint,
  PresenceState,
  PresenceStatus,
  IEndpointService,
  Companion,
  IUserService,
  PermissionType,
  IUser,
  IConferenceService,
  IConnectingOperator,
  Endpoint,
  IPushToTalkEvent,
  ConferenceUtil,
  StatsUtil,
  IEndpointRef
} from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { UserManagementService } from "../../user-management/user-management.service";
import { CallCenterService } from "client/scripts/call-center/call-center.service";
import { SafeHtmlPipe } from "client/scripts/shared/pipes/safe-html.pipe";

@Component({
  selector: "operator-item",
  templateUrl: "./operator-item.template.html"
})
export class OperatorItemComponent implements OnInit {
  @Input()
  endpoint: IEndpoint;
  @Input()
  transferHasSelected: boolean;

  /**
   * flag if able to make phone call
   */
  @Input() canMakePhoneCall: boolean;

  /**
   * flag if reached maximum participants to connect
   */
  @Input() isMaxParticipantsReached: boolean;

  @Input() numberOfParticipants: number;

  @Input() canTransferTo: (endpoint: IEndpoint) => boolean;
  @Input() canConferenceIn: (endpoint: IEndpoint) => boolean;

  @Output("peerChat")
  peerChatEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("peerVideoChat")
  peerVideoChatEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("disconnectPeer")
  disconnectPeerEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("toggleEndpointVideo")
  toggleEndpointVideoEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("dialOut")
  dialOutEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("pushToTalk")
  pushToTalkEmitter: EventEmitter<IPushToTalkEvent> = new EventEmitter<IPushToTalkEvent>();
  @Output("cancelCall")
  cancelCallEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("openKeypad")
  openKeypadEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("openOperatorInfoModal")
  openOperatorInfoModalEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  /**
   * the event emitter to send event to parent to start monitor on the endpoint
   */
  @Output("startMonitor")
  startMonitorEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to exit monitor on the endpoint
   */
  @Output("exitMonitor")
  exitMonitorEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  public presenceStateClass = PresenceState;
  public presenceStatusClass = PresenceStatus;
  /**
   * flag if current user has permission to monitor this endpoint
   */
  hasPermissionToMonitor: boolean = false;
  isWhispering: boolean = false;
  endpointService: IEndpointService = Companion.getEndpointService();
  conferenceService: IConferenceService = Companion.getConferenceService();
  userService: IUserService = Companion.getUserService();
  rtcService = Companion.getRTCService();
  user: IUser;

  avatarImageUrl: string;

  /**
   * flag to disable action buttons on the component (used to temporarily prevent accidental double clicks)
   * 
   */
  disableActions : boolean = false;
  disableActionsTimeout : NodeJS.Timeout;

  get name(): string {
    return this.endpoint.name || this.endpoint.uiName || this.endpoint.rtcId;
  }

  getAvatarImageUrl() {
    return (this.avatarImageUrl)?this.avatarImageUrl:"/images/avatar.png";
  }
  
  /**
   * Gets the inbound call quality score for the endpoint.
   * is a value from 0-5
   */
  epCallQuality(): number {
    // Do something different if this is us.
    if (this.endpoint.rtcId == this.endpointService.myEndpoint.rtcId) {
      let sum = 0;
      let count = 0;
      _.forEach(this.conferenceService.currentActiveConference?.active, (ep: IEndpoint) => {
        if (ep.rtcId !== this.endpointService.myEndpoint.rtcId) {
          let liveEp = this.endpointService.getEndpointById(ep.rtcId);
          if (liveEp.callQuality) {
            count += 1;
            sum += liveEp.callQuality?.mos?.[0]?.outbound;
          }
        }
      });
      //return average
      if (count) {
        return ((sum / count)); // Score 0-5 so multiply by factor for max value;
      } else {
        return 0;
      }
    } else {
      let liveEp = this.endpointService.getEndpointById(this.endpoint?.rtcId);
      if (liveEp?.callQuality) {
        return liveEp.callQuality?.mos?.[0]?.inbound;
      } else {
        return 0;
      }
    }
  }

  /** 
   * Show call quality if it is ourself and we are connected to someone... 
   * Or it's another operator we are connected
   */
  showCallQuality()
  {
    if(this.endpoint.rtcId == this.endpointService.myEndpoint.rtcId)
    {
      if(!this.localizationService.myLocalizationData?.diagnostic_panel?.onScreenOutboundQualityGauges)
      {
        return false;
      }

      return this.conferenceService.currentActiveConference?.active?.length > 1 &&
             _.some(this.conferenceService.currentActiveConference?.active, (ep : IEndpointRef) => 
              { return (ep.rtcId === this.endpointService.myEndpoint.rtcId)})
    }
    else
    {
      if(!this.localizationService.myLocalizationData?.diagnostic_panel?.onScreenInboundQualityGauges)
      {
        return false;
      }
      return !this.endpoint.isVoiceEp && this.endpointService.connectedToMe(this.endpoint);
    }
  }

  peerChat() {
    this.peerChatEmitter.emit(this.endpoint);
  }
  peerVideoChat() {
    this.disableButtonsTemporarily();
    this.peerVideoChatEmitter.emit(this.endpoint);
  }
  disconnectPeer(): void {
    this.disableButtonsTemporarily();
    this.disconnectPeerEmitter.emit(this.endpoint);
  }

  constructor(
    public localizationService: LocalizationService,
    private userManagementService: UserManagementService,
    private callCenterService: CallCenterService,
    protected safeTextPipe: SafeHtmlPipe
  ) {
    // nothing needed here
  }
  ngOnInit() {
    if (this.endpoint) {
      if (this.endpoint.userId) {
        this.avatarImageUrl = "";
        this.userManagementService.getUserById(this.endpoint.userId)
        .then((user: IUser) => {
          if (!user) {
            console.log("DATA ERROR, NO USER FOUND!");
            return
          }
          this.user = user;
          this.avatarImageUrl = this.user.avatar;
          this.checkPermissionToMonitor();
        }).catch((error: any) => {
          console.log(`Failed to getUserById: ${JSON.stringify(error)}`);
        });
      } else {
        console.log("DATA ERROR, NO USER ID FOR OP!");
      }
    } else {
      console.log("DATA ERROR, NO ENDPOINT FOR OP!");
    }
  }

  toggleEndpointAudio(): void {
    let videoElement: any = document.getElementById("ep-video-" + this.endpoint.rtcId);
    this.endpoint.isAudioMuted = !this.endpoint.isAudioMuted;
    if (videoElement) {
      videoElement.muted = this.endpoint.isAudioMuted;
    }
  }

  toggleEndpointVideo(): void {
    this.endpoint.showVideo = !this.endpoint.showVideo;
    this.toggleEndpointVideoEmitter.emit(this.endpoint);
  }

  /**
   * start monitor
   */
  startMonitor(): void {
    this.disableButtonsTemporarily();
    this.startMonitorEmitter.emit(this.endpoint);
  }

  /**
   * exit monitor
   */
  exitMonitor(): void {
    this.disableButtonsTemporarily();
    this.exitMonitorEmitter.emit(this.endpoint);
  }

  /**
   * Emit push to talk event
   */
  pushToTalk(event: IPushToTalkEvent) {
    this.isWhispering = event.talk;
    this.pushToTalkEmitter.emit(event);
  }

  /**
   * check permission of current user to monitor this endpoint
   */
  checkPermissionToMonitor(): void {
    if (!this.endpoint || !this.endpoint.userId) {
      this.hasPermissionToMonitor = false;
      return;
    }
    this.hasPermissionToMonitor = this.userManagementService.hasPermissionOfActionOnUser(
      PermissionType.silentMonitorCall,
      this.user
    );
  }

  /**
   * Emit dial out event
   */
  dialOut() {
    this.disableButtonsTemporarily();
    this.dialOutEmitter.emit();
  }

  /**
   * Emit cancel call event
   */
  cancelCall() {
    this.disableButtonsTemporarily();
    this.cancelCallEmitter.emit(this.endpoint);
  }

  openKeypad() {
    this.openKeypadEmitter.emit();
  }

  get isVoiceConference() {
    return this.conferenceService?.currentActiveConference?.active.some((ep) => ep.isVoiceEp);
  }

  /**
   * returns true if the operator item is our own.
   */
  get isMyEndpoint()
  {
    return this.endpoint.rtcId === this.endpointService.myEndpoint.rtcId;
  }

  /**
   * Return true if we are in a Conference and it's owner.
   */
  get isConferenceOwner()
  {
    let conf = Companion.getConferenceService().getConferenceFromEndpoint(this.endpoint);
    if(!conf)
    {
      return false;
    }
    else
    {
      return this.endpoint.userId === conf.ownerId;
    }
  }

  openOperatorInfoModal() {
    this.openOperatorInfoModalEmitter.emit(this.endpoint);
  }

  /**
   * temporarily disables all actions to prevent accidentally double click 
   */
  disableButtonsTemporarily()
  {
    clearTimeout(this.disableActionsTimeout);
    this.disableActions = true;
    this.disableActionsTimeout = setTimeout(function () {
      this.disableActions = false;
      clearTimeout(this.disableActionsTimeout);
    }.bind(this), 800);
  }

   getTargetEndpointsList(): string {
      return this.getSafeText(this.callCenterService.getTargetEndpointsList(this.endpoint));
   }

   getState() : PresenceState
   {
     return Endpoint.getPresenceStateByStatus(this.endpoint.status);
   }
 
   getMyState() : PresenceState
   {
     return Endpoint.getPresenceStateByStatus(this.endpointService.myEndpoint.status);
   }

  /**
   * Gets the owner name of the endpoints associated conference.
   */
  getAssociatedConfName()
  {
    return this.getSafeText(this.getTargetEndpointsList());
  }

  getSafeText(text: string): string {
    if (text) {
        return this.safeTextPipe.nonScrub(text) as string;
    }
    return "";
  }

  /**
   * If we are in a voice conference.
   */
  get connectedToVoice() : boolean {
     return ConferenceUtil.isVoiceConferenceRef(this.conferenceService?.currentActiveConference);
  }

  showConnectButton() : boolean
  {
    return !this.isMyEndpoint &&
            this.canConferenceIn(this.endpoint) &&
            this.endpointService.myEndpoint.status !== PresenceStatus.ringing &&
            this.endpointService.myEndpoint.status !== PresenceStatus.connecting &&
            this.endpoint.status === PresenceStatus.available &&
            !this.endpointService.connectedWithMeInMyConf(this.endpoint) &&
            !this.endpointService.monitoring(this.endpoint) &&
            !this.isMaxParticipantsReached &&
            !this.rtcService.rtcClient.monitoring &&
            !this.transferHasSelected
  }

  showSelectButton() : boolean
  {
    return !this.isMyEndpoint &&
           this.canTransferTo(this.endpoint) &&
           this.endpoint.status === PresenceStatus.available &&
           this.endpointService.myEndpoint.status !== PresenceStatus.ringing &&
           this.endpointService.myEndpoint.status !== PresenceStatus.connecting &&
           this.transferHasSelected;
  }

  ngIf_unselectButton(): boolean {
    return (this.endpointService.connectingOperator &&
            this.endpointService.connectingOperator?.endpoint.rtcId == this.endpoint.rtcId &&
            this.endpointService.connectingOperator?.isCaller === true);
  }

  ngIf_monitorButton() : boolean {
    return !this.isMyEndpoint &&
           this.hasPermissionToMonitor &&
           !this.endpointService.monitoring(this.endpoint) &&
           this.rtcService.rtcClient.monitoring != true && // can only monitor if i don't have a monitor
           !this.transferHasSelected &&
           this.endpointService.myEndpoint.status !== PresenceStatus.busy &&
           this.endpointService.myEndpoint.status !== PresenceStatus.onhold &&
           this.endpointService.myEndpoint.status !== PresenceStatus.connecting &&
           this.endpointService.myEndpoint.status !== PresenceStatus.disconnecting &&
           this.endpointService.myEndpoint.status !== PresenceStatus.ringing &&
           !this.endpointService.connectingOperator && 
           this.getState() !== PresenceState.xa; // make sure endpoint is online
  }

  ngIf_exitMonitorButton(): boolean {
    return this.hasPermissionToMonitor &&
           this.endpointService.monitoring(this.endpoint);
  }

  ngIf_whisperButton(): boolean {
    return !this.isMyEndpoint &&
           this.hasPermissionToMonitor &&
           this.endpointService.monitoring(this.endpoint);
  }

  ngIf_disconnectButton(): boolean {
    return (!this.isMyEndpoint &&
           this.endpointService.connectedWithMeInMyConf(this.endpoint)) ||
           (this.endpointService.myEpConnectedAsGuest() &&
            this.isMyEndpoint);
  }

}
