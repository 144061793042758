/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, OnInit } from "@angular/core";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { Companion, ILocalization, PresenceStatus, RoomType, IEndpoint, 
  GlobalService, IUser, IEndpointService, IUserService, IConferenceService, Cookie, StatusReason,
  EndpointService,  UserService,  ConferenceService,  AlertCode, AlertLevel, ConferenceUtil } from "companion";
import { CallCenterService } from "./call-center.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalizationService } from "./../localization/localization.service";
import { RestService } from "../shared/services/rest.service";
import { UserManagementService } from "../user-management/user-management.service";
import { NavBarMenuItemKey } from "../layout/nav-bar/nav-bar.service";
import { PassportLocalDocument } from "mongoose";
import { SafeHtmlPipe } from "../shared/pipes/safe-html.pipe";
import { ConfigService } from "client/scripts/config/config.service";
import { IClientSettings } from "companion/settings/config.interface";
import { LogUtil } from "companion/util/log";
import { ClientLogService } from "client/scripts/shared/services/client-log.service";
import {AlertService} from "../alert/alert.service";


@Component({
  selector: "call-center-list",
  templateUrl: "./call-center-list.component.html"
})
/**
 * call-center list component
 */
export class CallCenterListComponent implements OnInit {
  protected style: string;
  protected language: string;
  protected endpointService: IEndpointService;
  protected conferenceService: IConferenceService;
  protected userService: IUserService;
  readyComplete: boolean;
  /**
   * flag if the call center has been fully initialized.
   */
  initialized: boolean;

  constructor(
    public callCenterService: CallCenterService,
    private router: Router,
    private localizationService: LocalizationService,
    private restService: RestService,
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private configService: ConfigService,
    private logService: ClientLogService,
    protected safeTextPipe: SafeHtmlPipe
  ) {
    this.initialized = false;
    this.endpointService = EndpointService.getSharedInstance();
    this.conferenceService = ConferenceService.getSharedInstance();
    this.userService = UserService.getSharedInstance();
    Dispatcher.register(ActionType.UpdateSkillSetDisplay, this.updateSkillSetDisplay.bind(this), "updateSkillSet");
  }
  get hasPermissionToReloadClient() {
    let currentUser = this.userService.currentUser;
    return currentUser.permissions && currentUser.permissions.modifyActiveEndpointInQueue > 0;
  }

  get sortedInRoomCalls(): IEndpoint[] {
    return _.filter(this.callCenterService.globalInRoomCalls, ((endpoint: IEndpoint) => {
             return ((((endpoint?.skillTags) &&
                       (this.currentCategory == null ||
                        this.currentCategory == "ALL" ||
                        endpoint.skillTags.category == this.currentCategory) &&
                       (this.currentLanguage == null ||
                        this.currentLanguage == "ALL" ||
                        endpoint.skillTags.language == this.currentLanguage)) ||
                      ((endpoint?.skillSet) &&
                       (this.currentCategory == null ||
                        this.currentCategory == "ALL" ||
                        !endpoint?.skillSet?.categories ||
                        endpoint?.skillSet?.categories?.length == Number(0) ||
                        ConferenceUtil.getCategoryListFromSkillProficiencyList(endpoint.skillSet.categories)?.includes(this.currentCategory)) &&
                       (this.currentLanguage == null ||
                        this.currentLanguage == "ALL" ||
                        endpoint?.skillSet?.languages.length == Number(0) ||
                        endpoint.skillSet.languages.includes(this.currentLanguage)))) &&
                     ((!this.searchString) ||
                      (this.searchString == "") ||
                      (endpoint?.name?.startsWith(this.searchString))));
         })).sort((a, b) => a?.name?.localeCompare(b?.name));
  }

  get sortedHungCalls(): IEndpoint[] {
    return (this.callCenterService.globalHungCalls as IEndpoint[]).sort((a, b) => a?.name?.localeCompare(b?.name));
  }

  getCallTagLine(endpoint: IEndpoint) {
    let PresenceStatusDisplay = " PresenceStatus:" + (this.localizationService.myLocalizationData?.presenceStatus ? this.localizationService.myLocalizationData.presenceStatus[PresenceStatus[endpoint.status]] : PresenceStatus[endpoint.status]);
    if (endpoint.skillTags) {
      return endpoint.name + " (" + endpoint.theme + " - " + endpoint.skillTags.category + "/" + endpoint.skillTags.language + ")" + PresenceStatusDisplay;
    } else if (endpoint.skillSet) {
      // Not sure this path is needed, but to cover our bases.
      return endpoint.name + " (" + endpoint.theme + " - [" + ConferenceUtil.getSkillSetCategoryToString(endpoint.skillSet) + '] x [' + ConferenceUtil.getSkillSetLanguageToString(endpoint.skillSet) +"])" + PresenceStatusDisplay;
    } else {
      return endpoint.name + PresenceStatusDisplay;
    }
  }

  ngOnInit() {
    this.configService.getClientSettings()
    .then((settings: IClientSettings) => {
      this.conferenceService.alertHandler(
        AlertCode.getSettingsDataSuccess,
        "Collect logs enabled: " + settings.CollectLogs.enabled,
        AlertLevel.info
      );
      LogUtil.getLogInstance().init(settings.CollectLogs);    
    })
    .catch(error => this.conferenceService.alertHandler(AlertCode.getSettingsDataError, error.error, AlertLevel.info));

    this.style = this.route.snapshot.params["style"] || this.localizationService.myLocalizationData.style || "default";
    let currentUser = Companion.getUserService().currentUser;
    if (!currentUser.isAuthenticated) {
      // not yet authenticated!
      return;
    }
    if (
      !currentUser.isAuthenticated ||
      !currentUser.permissions ||
      !(currentUser.permissions.seeActiveEndpointInQueue > 0)
    ) {
      //call center list authenticated but do not have permissions?!
      this.logout();
      return;
    }
    this.init();
  }

  init(): Promise<void> {
    if (this.initialized) {
      // already initialized, no need to do anything.
      return Promise.resolve();
    }
    setInterval(() => {
      Dispatcher.dispatch(ActionType.LoadHangingCalls);
    }, 5 * 1000);
    Dispatcher.dispatch(ActionType.LoadTopBarMenu);
    Companion.getEndpointService().myEndpoint.theme = this.style;
    this.restService.getNewRtcId()
    .then((rtcId) => {
      Companion.getConferenceService().init(rtcId,
                                            this.callCenterService.alertHandler.bind(this.callCenterService));
      this.callCenterService.setConferenceUpdateCallback((data: any) => {
        console.log("Call-Center-List processing new update data");
      });
      this.ready();
    })
    .catch((error) => {
      console.log("Failed to getNewRtcId: ", error);
    }).then(() => {
      this.initialized = true;
    });
  }
  
  reload(ep: IEndpoint) {
      // confirm that we want to delete the role
      AlertService.createAlertWithButtons("Are you sure you want to reload " + ep.name + "'s client page?", {
        confirm: {
          label: "Yes",
          className: "btn-success"
        },
        cancel: {
          label: "No",
          className: "btn-default"
        }
      }).then((result: boolean) => {
        if (result) {
            Companion.getEndpointService().sendReloadCommandToClient(ep.rtcId);
        }
      });
  }
  /**
   * update flavor-language data
   */
  updateLocalizationData(languageCode?: string, save: boolean = true): Promise<ILocalization> {
    this.language = languageCode;
    if (!languageCode) {
      let cookieUser: IUser = GlobalService.getSessionUser();
      if (cookieUser && cookieUser.preferedLanguage) {
        this.language = cookieUser.preferedLanguage;
      }
    }
    if (languageCode && save) {
      this.userService.currentUser.preferedLanguage = languageCode;
      GlobalService.setSessionUser(this.userService.currentUser);
    }
    return this.localizationService.getLocalizationData(this.style, this.language);
  }
  /**
   * user successful log in event handler
   */
  login(errorMessage: string): void {
    if (errorMessage) {
      this.conferenceService.alertHandler(AlertCode.loginFailed, errorMessage, AlertLevel.warning);
      return;
    }
    let currentUser = this.userService.currentUser;
    console.log("call center list login currentUser:", currentUser);
    this.init();
  }

  ready() {
    // connect rtc service.
    Companion.getRTCService().connect();
    let currentUser = this.userService.currentUser;
    this.endpointService.myEndpoint.theme = this.style;
    if (currentUser.isAuthenticated) {
      this.endpointService.myEndpoint.name = currentUser.name || currentUser.username;
      this.endpointService.myEndpoint.userId = (currentUser as PassportLocalDocument)._id;
      // Force load data (updates our own currentUser)
      this.userManagementService.getMyAccessibleUsersWithRolesAndGroups()
      .then((userData) => {
        this.callCenterService.LoadVCCNewData(userData, () => {
          Companion.getEndpointService().sendEndpointUpdate();
          Companion.getEndpointService().markClientUnready(StatusReason.work);
          //console.log("call-center-list ready currentUser: ", currentUser);
          //console.log("call-center-list ready this.endpointService.myEndpoint: ", this.endpointService.myEndpoint);
          //console.log("call-center-list ready style:", this.style);
          this.callCenterService.joinVisibleQueues(this.style)
            .then(() => {
              console.log("Logged in data: ", currentUser, this.endpointService.myEndpoint);
              this.readyComplete = true;
              this.callCenterService.enableConferenceUpdates(true);
              Dispatcher.dispatch(ActionType.LoadVCCListAfterJoinNavBar, { active: NavBarMenuItemKey.VCC });
              Dispatcher.dispatch(ActionType.LoadTopBarMenu);
            })
            .catch((error) => {
              this.conferenceService.alertHandler(AlertCode.joinQueueFail, error, AlertLevel.warning);
            });
        });
      })
      .catch((error) => {
        this.conferenceService.alertHandler(AlertCode.loginFailed, error, AlertLevel.warning);
      });
    }
  }

  /**
   * user log out event handler
   */
  logout(): void {
    let currentUser = this.userService.currentUser;
    console.log("call center list logout currentUser:", currentUser);
    Companion.getEndpointService().markClientGone()
    .then(() => {
      this.callCenterService.clearUIData();
      Dispatcher.dispatch(ActionType.LogOut);
      Dispatcher.dispatch(ActionType.LoadHostUserNavBar);
      Dispatcher.dispatch(ActionType.LoadTopBarMenu);
    })
    .catch((error) => {
      alert("Problem logging out. Please refresh.");
    })
  }

  /**
   * language selection changed event
   */
  languageChanged(languageCode: string) {
    this.updateLocalizationData(languageCode)
    .then((data: ILocalization) => {
      this.conferenceService.alertHandler(
        AlertCode.getLocalizationDataSuccess, this.style + ", " + languageCode, AlertLevel.info
      );
    })
    .catch((err: Error) => {
      this.conferenceService.alertHandler(
        AlertCode.getLocalizationDataFail, this.style + ", " + languageCode, AlertLevel.warning
      );
    });
  }

  themeChanged() {
    if (this.userService.currentUser.isAuthenticated) {
      const newTheme = this.route.snapshot.params["style"];
      if (newTheme) {
        this.endpointService.myEndpoint.theme = newTheme;
        this.style = newTheme;
        this.language = newTheme.language;
        if (this.readyComplete) {
          Dispatcher.dispatch(ActionType.LoadVCCListAfterJoinNavBar, { active: NavBarMenuItemKey.VCC });
          Dispatcher.dispatch(ActionType.LoadTopBarMenu);
        }
        this.endpointService.sendEndpointUpdate();
      }
    }
  }

  themeFilterChanged($event) {
    const selectedTheme = $event;
    if (selectedTheme) {
      const commands = this.router.url.split("/");
      if (commands.length > 1) {
        const curTheme = this.style;
        if (curTheme != commands[1] && curTheme == "default") {
          commands.splice(1, 0, "default");
        }
        commands[1] = selectedTheme;
        this.router.navigate(commands).then();
      }
    }
    this.style = selectedTheme;
    this.changeRooms();
  }

  changeRooms() {
    const previousQueues: string[] = _.clone(this.callCenterService.visibleQueues);
    
    this.callCenterService.getAccessibleQueues(this.style, this.endpointService.myEndpoint.skillTags)
    .then(() => {
      const nextQueues: string[] = this.callCenterService.visibleQueues;
      const intersection = _.intersection(previousQueues, nextQueues);
      const toLeaveQueues = previousQueues.filter(x => !intersection.includes(x));
      const toJoinQueues = nextQueues.filter(x => !intersection.includes(x));
      console.log("Room Change!", JSON.stringify({style: this.style, toLeave: toLeaveQueues, toJoin: toJoinQueues}));
    
      let leaveRoomPromises = [];
      toLeaveQueues.forEach(queue => {
        console.log("Attempting to leave room: ", queue);
        leaveRoomPromises.push(this.endpointService.removeFromRoomPromise(ConferenceUtil.newConferenceData(RoomType.GuestWaitRoom, {name: queue})));
      })
      function getJoinRoomPromises() {
        let joinRoomPromises = [];
        toJoinQueues.forEach(queue=> {
          console.log("Attempting to join room: ", queue);
          joinRoomPromises.push(new Promise((resolve, reject) => {
            Companion.getEndpointService().addToRoom(ConferenceUtil.newConferenceData(RoomType.GuestWaitRoom, {name: queue}), (roomName: string) => {resolve(roomName)}, (errorCode: string, errorText: string, roomName:string) => {
              console.log("Failed to join room!", roomName, errorCode, errorText);
              reject(errorText);
            });
          }));
        })
        return joinRoomPromises;
      }
      
      return Promise.all(leaveRoomPromises).then(() => {
        return Promise.all(getJoinRoomPromises.bind(this)()).then(() => {
          Dispatcher.dispatch(ActionType.LoadVCCNewData, null, "updateAccessibleQueues");
          Dispatcher.dispatch(ActionType.LoadVCCNewData, null, "loadParticipants");
          Dispatcher.dispatch(ActionType.UpdateSkillSetDisplay, null, "updateSkillSet");
          console.log("Current SkillSet: ", Companion.getEndpointService().myEndpoint.skillSet);  
        });
      });
    })
    .catch((error) => {
      console.log("Failed to changeRooms: ", error);
    });
  }

  // endpoint associated to guest/operator info dialog
  infoDialogEp: IEndpoint;

  /**
   * open guest info modal
   */
  openGuestInfoModal(endpoint: IEndpoint) {
    this.infoDialogEp = this.endpointService.getEndpointById(endpoint.rtcId);
    Dispatcher.dispatch(ActionType.OpenModalDialog, "guestInfoModal");
  }

  /**
   * open operator info modal
   */
  openOperatorInfoModal(endpoint: IEndpoint) {
    this.infoDialogEp = this.endpointService.getEndpointById(endpoint.rtcId);
    Dispatcher.dispatch(ActionType.OpenModalDialog, "operatorInfoModal");
  }

  /**
   * open info modal
   */
  openInfoModal(endpoint: IEndpoint) {
    if (this.endpointService.isOperator (endpoint))
    {
        this.openOperatorInfoModal(endpoint);
    }
    else
    {
        this.openGuestInfoModal(endpoint);
    }
  }

  /**
   * returns info modal info button label based on the type of client
   */
  getInfoButtonLabel(endpoint: IEndpoint) {
    // TBD: Localization of these things.
    return " Info";
  }

  /**
   * determines if reload button needs to be displayed based on the client type
   */
  isReloadButtonAvailable(endpoint: IEndpoint) {
    return ((endpoint?.rtcId != null) && 
            (endpoint?.theme != null) && 
            (endpoint?.status != PresenceStatus.offline));
  }

  /**
   * determines if log collection is configured
   */
  isLogCollectionEnabled() {
    return LogUtil.getLogInstance()?.logsSettings?.enabled;
  }

  /**
   * determines if send logs button needs to be displayed in the info dialog based on the configured settings
   */
  isInfoDialogSendsLogsButtonAvailable() {
    return (this.isLogCollectionEnabled() == true && 
            this.isReloadButtonAvailable(this.infoDialogEp));
  }

  /**
   * sends the request to send the logs to the client
   */
  requestToSendLogs(endpoint: IEndpoint) {
    this.endpointService.sendRequestToSendLogs(endpoint.rtcId);
  }

  /**
   * cleans up the text to display
   */
  getSafeText(text: string): string {
    if (text) {
        return this.safeTextPipe.nonScrub(text) as string;
    }
    return "";
  }

  // available categories to filter on.
  availableCategories: string[] = [];

  // the selected category (OR ALL)
  currentCategory: string;

  // available languages to filter on.
  availableLanguages: string[] = [];

  // the selected language (OR ALL)
  currentLanguage: string;

  // current theme
  currentTheme: string;

  // the current search string to filter the clients on
  searchString: string;

  /*
   * a new skill or lanaguage to filter on was selected; save the selection
   */
  updateSkillSetFilter() {
    // update the cookies
    Cookie.setCookie(this.currentTheme+"currentCategory", this.currentCategory);
    Cookie.setCookie(this.currentTheme+"currentLanguage", this.currentLanguage);
  }

  /*
   * a new search string to filter on was identified
   */
  searchStringUpdated() {
     //console.log("searchStringUpdated searchString:", this.searchString);
  }

  /*
   * get localized category text
   */
  getLocalizedCategoryText(category:string) {
    // TBD: Localization of these things.
    return (category == "ALL" ? "ALL Categories": category);
  }

  /*
   * get localized language text
   */
  getLocalizedLanguageText(lang:string) {
    // TBD: Localization of these things.
    return (lang == "ALL" ? "ALL Languages":lang);
  }

  /*
   * Updates the SkillSet selection options
   */
  updateSkillSetDisplay() {
    //console.log("CallCenterListComponent updateSkillSetDisplay...");
    this.availableCategories = _.clone(ConferenceUtil.getCategoryListFromSkillProficiencyList(EndpointService.getSharedInstance().myEndpoint?.skillSet?.categories));
    this.availableLanguages = _.clone(EndpointService.getSharedInstance().myEndpoint?.skillSet?.languages);
    this.currentTheme = _.clone(EndpointService.getSharedInstance().myEndpoint?.theme);
    this.currentTheme = (this.currentTheme && this.currentTheme.length > 3) ? this.currentTheme.substring(0,this.currentTheme.length-3) : this.currentTheme;
    //Companion.getEndpointService().sendMyStatusToOthers();
    // i'm not sure this is necessary...
    //console.log("updateSkillSetDisplay availableCategories:", this.availableCategories);
    //console.log("updateSkillSetDisplay availableLanguages:", this.availableLanguages);
    //console.log("updateSkillSetDisplay theme:", this.currentTheme);
    if(this.availableCategories?.length > 1)
    {
      this.availableCategories?.unshift("ALL");
      this.currentCategory = Cookie.getCookie(this.currentTheme+"currentCategory") || "ALL";
    }
    else if (this.availableCategories?.length == 1) {
      this.currentCategory = this.availableCategories[0];
      this.availableCategories = null;
    }
    else
    {
      this.currentCategory = null;
      this.availableCategories = null;
    }
    //console.log("updateSkillSetDisplay availableCategories:", this.availableCategories);
    if(this.availableLanguages?.length > 1)
    {
      this.availableLanguages?.unshift("ALL");
      this.currentLanguage = Cookie.getCookie(this.currentTheme+"currentLanguage") || "ALL";
    } else if (this.availableLanguages?.length == 1) {
      this.currentLanguage = this.availableLanguages[0];
      this.availableLanguages = null;
    } else {
      this.currentLanguage = null;
      this.availableLanguages = null;
    }
    //console.log("updateSkillSetDisplay availableLanguages:", this.availableLanguages);
    //console.log("updateSkillSetDisplay currentTheme:", this.currentTheme, "currentCategory:", this.currentCategory, "currentLanguage:", this.currentLanguage);
    this.updateSkillSetFilter();
  }


  ngOnDestroy(): void {
    this.deinit();
  }

  deinit(): Promise<void> {
    this.callCenterService.reset();
    this.conferenceService.reset();
    return Promise.resolve();
  }

}
