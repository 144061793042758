/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
  IEndpoint,
  IEndpointService,
  Companion,
  PresenceState,
  PresenceStatus,

  IExpandedActiveConference,
  Endpoint,
  IPushToTalkEvent
 } from "companion";
import { SortByPipe } from "../../shared/pipes/sort-by.pipe";
import { LocalizationService } from "../../localization/localization.service";
import { ActionType, Dispatcher } from "../../shared/services/dispatcher";

@Component({
  selector: "endpoint-list",
  templateUrl: "./endpoint-list.template.html"
})
export class EndpointListComponent {
  @Input() listType: string;
  @Input() viewMode: string;
  @Input() transferHasSelected: boolean;
  @Input() endpoints: IEndpoint[];
  @Input() showMyself: boolean;
  @Input() conference: IExpandedActiveConference;
  /**
   * flag if able to make phone call
   */
  @Input() canMakePhoneCall: boolean;


  /**
   * flag if reached maximum participants to connect
   */
  @Input() isMaxParticipantsReached: boolean;

  @Input() numberOfParticipants: number;

  /**
   * endpoint to answer
   */
  @Input() guestToAnswerEp: IEndpoint;

  @Input() canTransferTo: (endpoint: IEndpoint) => boolean;
  @Input() canConferenceIn: (endpoint: IEndpoint) => boolean;

  @Output("peerChat") peerChatEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("peerVideoChat") peerVideoChatEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("disconnectPeer") disconnectPeerEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("resumeSubConference") resumeGuestConferenceEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("hangupRingingCall") hangupRingingCallEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output("retrievePeer") retrievePeerEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("toggleTransferSelection") toggleTransferSelectionEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("toggleEndpointVideo") toggleEndpointVideoEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("dialOut") dialOutEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("pushToTalk") pushToTalkEmitter: EventEmitter<IPushToTalkEvent> = new EventEmitter<IPushToTalkEvent>();
  @Output("cancelCall") cancelCallEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("openKeypad") openKeypadEmitter: EventEmitter<any> = new EventEmitter<any>();
  /**
   * the event emitter to send event to parent to start monitor on the endpoint
   */
  @Output("startMonitor") startMonitorEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to exit monitor on the endpoint
   */
  @Output("exitMonitor") exitMonitorEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to toggle notepad on the endpoint
   */
  @Output("toggleNotepad") toggleNotepadEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to open guest info dialog
   */
  @Output("openGuestInfoModal")
  openGuestInfoModalEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to open operator info dialog
   */
  @Output("openOperatorInfoModal")
  openOperatorInfoModalEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  endpointService: IEndpointService = Companion.getEndpointService();

  myEndpoint: IEndpoint = this.endpointService.myEndpoint;
  PresenceState: any = PresenceState;

  /**
   * view model for filter
   */
  viewModel: { filter: string } = { filter: null };

  /**
   * sort endpoints
   */
  public get sortedEndpoints(): IEndpoint[] {
    // override list type sort?
    _.forEach(this.endpoints, (ep: any) => {
      // update for special case
      if (ep.rtcId === Companion.getEndpointService().myEndpoint.rtcId) {
        ep.sortWeight = 203; // myself should have higher prio than any other case.
      }
      if ((ep.status === PresenceStatus.busy || ep.status === PresenceStatus.callback_connected_external)) {
        if (this.endpointService.inMyConf(ep)) {
          ep.sortWeight = 201;
        }
        // Update higher prio after lower prio... more specific.
        if (this.endpointService.connectedToSameConf(ep)) {
          ep.sortWeight = 202;
        }
      } else {
        if (this.endpointService.onHoldToMe(ep) || this.endpointService.onHoldInMyConf(ep)) {
          ep.sortWeight = 200;
        }
      }
    });
    if (this.localizationService.getValueByPath(".participant_panel.sortByEnterTime")) {
      return this.sortPipe.transform(this.filteredEndpoints, ["sortWeight", "enteredOn", "index"], ["desc", "asc", "asc"]);
    } else {
      return this.sortPipe.transform(this.filteredEndpoints, ["sortWeight", "index"], ["desc", "asc"]);
    }
  }

  /**
   * filter endpoints by name
   */
  public get filteredEndpoints(): IEndpoint[] {
    // filter our data
    return _.filter(this.endpoints, (ep: any) => {
      return (ep.index && this.viewModel.filter && ep.index.indexOf(this.viewModel.filter.toLowerCase()) > -1) || !this.viewModel.filter;
    });
  }

  /**
   * check if theme allow user to initiate chat with participant
   * if theme enabled, user can initiate chat with participant
   * if not, user can only reply message to the existing chat rooms
   */
  get canChat(): boolean {
    let result = false;
    if (
      this.localizationService.myLocalizationData.chat_panel &&
      this.localizationService.myLocalizationData.toolbar.toolbar_items.chat
    ) {
      result = true;
      if (
        this.localizationService.myLocalizationData.toolbar.toolbar_items.chat.hostOnly &&
        !Companion.getEndpointService().myEndpoint.isHost
      ) {
        result = false;
      } 
      if (this.localizationService.myLocalizationData.chat_panel.disableChatWithWebUser) {
        result = false;
      }
    }
    return result;
  }

  get canChatWithAPIUser(): boolean {
    return (
      !!this.localizationService.myLocalizationData.chat_panel &&
      this.localizationService.myLocalizationData.chat_panel.chatWithAPIUser
    );
  }

  constructor(private sortPipe: SortByPipe, public localizationService: LocalizationService) {
    // nothing needed here
  }

  isEndpointHidden(endpoint: IEndpoint): boolean {
    let result: boolean = false;
    if (!this.showMyself && endpoint.rtcId === this.myEndpoint.rtcId) {
      result = true;
    }
    if (
        (
          (this.listType === "publicWait" || this.listType == "ep") &&
          Endpoint.getPresenceStateByStatus(endpoint.status) === PresenceState.xa &&
          !this.localizationService.getValueByPath(".participant_panel.showOfflineEndpoints")
        )
      ||
      (
        (this.listType === "op" || this.listType === "sp") &&
        Endpoint.getPresenceStateByStatus(endpoint.status) === PresenceState.xa &&
        !this.localizationService.getValueByPath(".participant_panel.showOfflineUsers")
      )
    ) {
      result = true;
    }
    return result;
  }

  /**
   * trigger message chat to another endpoint
   * @param endpoint: IEndpoint - the endpoint to start message chat with
   */
  peerChat(endpoint: IEndpoint): void {
    this.peerChatEmitter.emit(endpoint);
  }

  /**
   * trigger video chat to another endpoint
   * @param endpoint: IEndpoint - the endpoint to start video chat with
   */
  peerVideoChat(endpoint: IEndpoint): void {
    this.peerVideoChatEmitter.emit(endpoint);
  }

  /**
   * trigger disconnect to a peer
   * @param endpoint: IEndpoint - the endpoint to disconnect
   */
  disconnectPeer(endpoint: IEndpoint): void {
    this.disconnectPeerEmitter.emit(endpoint);
  }
  /**
   * trigger resume guest-sub-conference
   * @param endpoint: IEndpoint - the endpoint to disconnect
   */
  resumeSubConference(endpoint: IEndpoint): void {
    this.resumeGuestConferenceEmitter.emit(endpoint);
  }

  hangupRingingCall(phoneNumber: string): void {
    this.hangupRingingCallEmitter.emit(phoneNumber);
  }

  /**
   * trigger retrieve peer data
   * @param endpoint: IEndpoint - the endpoint
   */
  retrievePeerData(endpoint: IEndpoint): void {
    this.retrievePeerEmitter.emit(endpoint);
  }

  /**
   * trigger dial out
   */
  dialOut(): void {
    this.dialOutEmitter.emit();
  }

  /**
   * Emit cancel call event
   */
  cancelCall(endpoint: IEndpoint) {
    this.cancelCallEmitter.emit(endpoint);
  }

  /**
   * trigger open keypad
   */
  openKeypad(): void {
    this.openKeypadEmitter.emit();
  }

  /**
   * toggle operator selected
   * @param endpoint: IEndpoint - the endpoint to toggle selection
   */
  toggleTransferSelection(endpoint: IEndpoint): void {
    this.toggleTransferSelectionEmitter.emit(endpoint);
  }

  /**
   * mute or unmute the endpoint's video
   * @param endpoint: IEndpoint - the endpoint to toggle video
   */
  toggleEndpointVideo(endpoint: IEndpoint): void {
    this.toggleEndpointVideoEmitter.emit(endpoint);
  }

  /**
   * start monitor
   */
  startMonitor(endpoint: IEndpoint): void {
    this.startMonitorEmitter.emit(endpoint);
  }

  /**
   * exit monitor
   */
  exitMonitor(endpoint: IEndpoint): void {
    this.exitMonitorEmitter.emit(endpoint);
  }

  /**
   * Emit push to talk event
   */
  pushToTalk(event: IPushToTalkEvent) {
    this.pushToTalkEmitter.emit(event);
  }

  /**
   * toggle notepad
   */
  toggleNotepad(endpoint: IEndpoint) {
    this.toggleNotepadEmitter.emit(endpoint);
  }

  /**
   * Request to open guest info modal
   */
  openGuestInfoModal(endpoint: IEndpoint) {
    this.openGuestInfoModalEmitter.emit(endpoint);
  }

  /**
   * Request to open operator info modal
   */
  openOperatorInfoModal(endpoint: IEndpoint) {
    this.openOperatorInfoModalEmitter.emit(endpoint);
  }

  trackByEndpoint(index: number, endpoint: IEndpoint) {
    return endpoint.rtcId;
  }

  /**
   * reset filter
   */
  resetSearch() {
    this.viewModel.filter = null;
  }

  /**
   * prompt dialog to create new group chat
   */
  createNewGroupChat() {
    Dispatcher.dispatch(ActionType.ToggleChatParticipantSelectList, {targetId: "new-group-chat-button", forceOpen: true});
  }
}
