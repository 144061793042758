/**
 * Copyright Compunetix Incorporated 2018-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { ActionType, Dispatcher } from "../../shared/services/dispatcher";
import { Injectable } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { Companion, PresentationMode, IUser, Browser, PresenceStatus, VideoAspect, Cookie } from "companion";
import {Store} from "client/scripts/Store/store.service";

@Injectable()
export class NavBarService {
  get leftStaticMenu(): NavBarMenuItem[] {
    return _.filter(this.menus, { position: NavBarMenuPosition.LeftStatic });
  }
  get leftMenu(): NavBarMenuItem[] {
    return _.filter(this.menus, (menu: NavBarMenuItem) => {
      return menu.position === NavBarMenuPosition.Left || menu.position === NavBarMenuPosition.LeftStatic;
    });
  }
  get rightMenu(): NavBarMenuItem[] {
    return _.filter(this.menus, { position: NavBarMenuPosition.Right });
  }
  get allMenu(): NavBarMenuItem[] {
    return _.filter(this.menus, (menu: NavBarMenuItem) => {
      return menu.position === NavBarMenuPosition.Right ||
        menu.position === NavBarMenuPosition.Left ||
        menu.position === NavBarMenuPosition.LeftStatic ;
    });
  }
  get chatMenuItem(): NavBarMenuItem {
    return _.find(this.allMenu, { key: NavBarMenuItemKey.Chat });
  }

  menus: NavBarMenuItem[] = [];

  /**
   * if this is a mobile device
   */
  isMobileDevice: boolean = Browser.isMobile();

   chatAudioAlertContext: AudioContext = new AudioContext();
   chatAudioAlertOscillator: OscillatorNode;
   chatAudioAlertGain: GainNode;
   chatAudioAlertActive: boolean = false;
   chatAudioAlertOption: string = "none";
   chatAudioAlertType: string = "triangle";
   chatAudioAlertFrequency: number = 800;
   chatAudioAlertDuration: number = 500;
   chatAudioAlertInterval: number = 3000;

  
  constructor(private localizationService: LocalizationService, private store: Store) {
    Dispatcher.register(ActionType.LoadConferenceBeforeJoinNavBar, this.LoadConferenceBeforeJoinNavBar.bind(this));
    Dispatcher.register(ActionType.LoadEmptyNavBar, this.LoadEmptyNavBar.bind(this));
    Dispatcher.register(ActionType.LoadVCCGuestAfterJoinNavBar, this.LoadVCCGuestAfterJoinNavBar.bind(this));
    Dispatcher.register(ActionType.LoadVCCOperatorAfterJoinNavBar, this.LoadVCCOperatorAfterJoinNavBar.bind(this));
    Dispatcher.register(ActionType.LoadVCCListAfterJoinNavBar, this.LoadVCCListAfterJoinNavBar.bind(this));
    Dispatcher.register(ActionType.LoadGuestCallbackNavBar, this.LoadGuestCallbackNavBar.bind(this));
    Dispatcher.register(ActionType.LoadHostUserNavBar, this.LoadHostUserNavBar.bind(this));
    Dispatcher.register(ActionType.IncreaseNavBarNotification, this.IncreaseNavBarNotification.bind(this));
    Dispatcher.register(ActionType.ResetNavBarNotification, this.ResetNavBarNotification.bind(this));
    Dispatcher.register(ActionType.UpdateMenuItem, this.updateMenuItem.bind(this));
    Dispatcher.register(ActionType.AutoUpdateMenuItems, this.updateAllMenuItems.bind(this));
    Dispatcher.register(ActionType.ToggleSilenceChatAudioAlerts, this.toggleSilenceChatAudioAlerts.bind(this));
  }

  /**
   * load empty nav bar
   */
  LoadEmptyNavBar(): void {
    this.menus = [];
  }

  /**
   * load guest nav bar
   */
  loadGuestNavBar(): void {
    this.menus = [
      {
        key: NavBarMenuItemKey.SignIn,
        position: this.localizationService.getValueByPath(".loginPanel.loginText")
          ? NavBarMenuPosition.Right
          : NavBarMenuPosition.None,
        title: ".loginPanel.loginText",
        defaultTitle: "Sign In",
        icon: "fas fa-sign-in-alt",
        clickAction: ActionType.OpenLoginModal,
        payload: { callback: ActionType.OpenDefaultScreen },
      },
    ];
  }

  /**
   * load conference before join nav bar
   */
  LoadConferenceBeforeJoinNavBar(): void {
    this.menus = [
      {
        key: NavBarMenuItemKey.Settings,
        position: this.isToolbarItemVisible("settings") ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.settings.text",
        toolbarItemKey: "settings",
        defaultTitle: "Settings",
        icon: "fas fa-cog",
        clickAction: ActionType.ToggleSettingsPanel,
      },
      {
        key: NavBarMenuItemKey.Audio,
        position: NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.audio.text",
        defaultTitle: "Audio On",
        icon: Companion.getRTCClient().audioMuted ? "fas fa-microphone-slash" : "fas fa-microphone",
        clickAction: ActionType.ToggleAudio,
      },
      {
        key: NavBarMenuItemKey.Video,
        position: NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.video.text",
        defaultTitle: "Video On",
        icon: Companion.getRTCClient().videoMuted ? "fas fa-video-slash" : "fas fa-video",
        clickAction: ActionType.ToggleVideo,
      },
      {
        key: NavBarMenuItemKey.VirtualBackground,
        position: NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.virtualBackground.text",
        defaultTitle: "Virtual Background",
        icon: "fas fa-hand-sparkles",
        clickAction: ActionType.ToggleVirtualBackground,
      }
    ];
  }

  LoadGuestCallbackNavBar(): void {
    this.menus = [
      {
        key: NavBarMenuItemKey.Leave,
        position: this.isToolbarItemVisible("enter") ? NavBarMenuPosition.LeftStatic : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.enter.altText",
        toolbarItemKey: "enter",
        defaultTitle: "Leave",
        icon: "fas fa-times",
        clickAction: ActionType.LeaveConference,
      }
    ];
  }

  /**
   * load conference after join nav bar
   */
  LoadConferenceAfterJoinNavBar(payload: any): void {
  }

  LoadVCCGuestAfterJoinNavBar(payload: any = {}): void {
    this.LoadVCCOperatorAfterJoinNavBar(payload);
    this.updateMenuItem({
      key: NavBarMenuItemKey.Chat,
      position: NavBarMenuPosition.None,
    });
    this.updateMenuItem({
      key: NavBarMenuItemKey.IncomingQueue,
      position: NavBarMenuPosition.None,
    });
    this.updateMenuItem({
      key: NavBarMenuItemKey.Operators,
      position: NavBarMenuPosition.None,
    });
    this.updateMenuItem({
      key: NavBarMenuItemKey.Map,
      position: NavBarMenuPosition.None,
    });
  }

  LoadVCCOperatorAfterJoinNavBar(payload: any = {}): void {
    let currentUser: IUser = Companion.getUserService().currentUser;

    let isWindowed = (!document["fullscreenElement"] && !document["webkitFullscreenElement"]);
    
    this.menus = [
      {
        key: NavBarMenuItemKey.Leave,
        position: this.isToolbarItemVisible("enter") ? NavBarMenuPosition.LeftStatic : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.enter.altText",
        toolbarItemKey: "enter",
        defaultTitle: "Leave",
        icon: "fas fa-times",
        clickAction: ActionType.LeaveConference,
      },
      {
        key: NavBarMenuItemKey.Chat,
        position:
          this.isToolbarItemVisible("chat")
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.chat.text",
        toolbarItemKey: "chat",
        defaultTitle: "Chat",
        icon: "fas fa-comments",
        clickAction: ActionType.ToggleChatPanel,
      },
      {
        key: NavBarMenuItemKey.IncomingQueue,
        position:
          this.isToolbarItemVisible("incomingQueue")
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.incomingQueue.text",
        toolbarItemKey: "incomingQueue",
        defaultTitle: "Incoming Queue",
        icon: "fas fa-user",
        clickAction: ActionType.ToggleIncomingQueuePanel,
      },
      {
        key: NavBarMenuItemKey.Operators,
        position:
          this.isToolbarItemVisible("operators")
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.operators.text",
        toolbarItemKey: "operators",
        defaultTitle: "Operators",
        icon: "fas fa-headset",
        clickAction: ActionType.ToggleOperatorsPanel,
      },
      {
        key: NavBarMenuItemKey.ShareScreen,
        position:
          this.isToolbarItemVisible("shareScreen") && this.isScreenCaptureCapable
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.shareScreen.text",
        toolbarItemKey: "shareScreen",
        defaultTitle: "Share Screen",
        defaultAriaLabel: "Press Enter to Start Screen Sharing.",
        icon: "fas fa-desktop",
        clickAction: ActionType.ToggleShareScreen,
        activeColor: "#d9534f",
        disabled: true, // by default disabled until we connect.
      },
      {
        key: NavBarMenuItemKey.ShareFiles,
        position: this.isToolbarItemVisible("sharedFolder")
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.sharedFolder.text",
        toolbarItemKey: "sharedFolder",
        defaultTitle: "Share Files",
        icon: "fas fa-folder-open",
        clickAction: ActionType.ToggleShareFilesPanel,
        tagCount: 0,
        disabled: false, // by default disabled until we go ready.
      },
      {
        key: NavBarMenuItemKey.ContentShare,
        position:
          this.isToolbarItemVisible("contentShare") && this.isScreenCaptureCapable
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.contentShare.text",
        toolbarItemKey: "shareContent",
        defaultTitle: "Share Content",
        defaultAriaLabel: "Press Enter to Start Content Sharing.",
        icon: "fas fa-chalkboard-user",
        clickAction: ActionType.ToggleContentShare,
        activeColor: "#d9534f",
        disabled: true, // by default disabled until we connect.
      },
      {
        key: NavBarMenuItemKey.Dashboard,
        position:
          currentUser.permissions &&
          currentUser.permissions.seeBillingData > 0 &&
          currentUser.isAuthenticated &&
          this.store.getState().dashboardEnabled 
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.dashboard.text",
        toolbarItemKey: "dashboard",
        defaultTitle: "Dashboard & Reports",
        icon: "fas fa-chart-line",
        clickAction: ActionType.OpenDashboard,
      },
      {
        key: NavBarMenuItemKey.Map,
        position:
          this.isToolbarItemVisible("map")
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.map.text",
        toolbarItemKey: "map",
        defaultTitle: "Map",
        icon: "fas fa-map",
        clickAction: ActionType.ToggleMapScreen,
      },
      {
        key: NavBarMenuItemKey.Record,
        position:
          this.isToolbarItemVisible("record") &&
          !this.localizationService.getValueByPath(".record_panel.autoRecord")
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.record.text",
        toolbarItemKey: "record",
        defaultTitle: "Record",
        icon: "fas fa-bullseye",
        clickAction: ActionType.ToggleRecording,
        disabled: true, // by default disabled until we connect.
      },
      {
        key: NavBarMenuItemKey.Snapshot,
        position:
          this.isToolbarItemVisible("snapshot")
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.snapshot.text",
        toolbarItemKey: "snapshot",
        defaultTitle: "Snapshot",
        icon: "fas fa-camera",
        clickAction: ActionType.ToggleSnapshot,
        disabled: true, // by default disabled until we connect.
      },
      {
        key: NavBarMenuItemKey.Audio,
        position: this.isToolbarItemVisible("audio") ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.audio.text",
        toolbarItemKey: "audio",
        defaultTitle: "Audio On",
        icon: Companion.getRTCClient().audioMuted ? "fas fa-microphone-slash" : "fas fa-microphone",
        clickAction: ActionType.ToggleAudio,
        disabled: Companion.getRTCClient().monitoring
      },
      {
        key: NavBarMenuItemKey.Video,
        position: this.isToolbarItemVisible("video") ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.video.text",
        toolbarItemKey: "video",
        defaultTitle: "Video On",
        icon: Companion.getRTCClient().videoMuted ? "fas fa-video-slash" : "fas fa-video",
        clickAction: ActionType.ToggleVideo,
      },
      {
        key: NavBarMenuItemKey.Inspector,
        position:
          this.isToolbarItemVisible("inspector")
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.inspector.text",
        toolbarItemKey: "inspector",
        defaultTitle: "Inspector",
        icon: "fas fa-tachometer-alt",
        clickAction: ActionType.ToggleInspectorPanel,
      },
      {
        key: NavBarMenuItemKey.VirtualBackground,
        position: NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.virtualBackground.text",
        defaultTitle: "Virtual Background",
        icon: "fas fa-hand-sparkles",
        clickAction: ActionType.ToggleVirtualBackground,
      },
      {
        key: NavBarMenuItemKey.Settings,
        position: this.isToolbarItemVisible("settings") ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.settings.text",
        toolbarItemKey: "settings",
        defaultTitle: "Settings",
        icon: "fas fa-cog",
        clickAction: ActionType.ToggleSettingsPanel,
      },
      {
        key: NavBarMenuItemKey.FullScreen,
        position: this.isToolbarItemVisible("fullscreen") && !this.isMobileDevice ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        title:  isWindowed ? 
          ".toolbar.toolbar_items.fullscreen.text": ".toolbar.toolbar_items.fullscreen.altText",
        toolbarItemKey: "fullscreen",
        defaultTitle: "Full Screen",
        icon: isWindowed ? "fas fa-expand-arrows-alt" : "fas fa-compress",
        clickAction: ActionType.ToggleFullScreen,
      },
      {
        key: NavBarMenuItemKey.VideoAspect,
        position: this.isToolbarItemVisible("videoAspect") ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        title: currentUser.preferedVideoAspect === VideoAspect.fill ?
          ".toolbar.toolbar_items.videoAspect.altText" : ".toolbar.toolbar_items.videoAspect.text",
        defaultTitle: "Video Aspect",
        icon: "fas fa-external-link-square-alt",
        clickAction: ActionType.ToggleVideoAspect,
      },
      {
        key: NavBarMenuItemKey.Lock,
        position: this.isToolbarItemVisible("lock") && !this.isHost ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        title: ".toolbar.toolbar_items.lock.altText",
        toolbarItemKey: "lock",
        defaultTitle: "Unlocked",
        icon: "fas fa-lock-open",
        clickAction: ActionType.ToggleLock,
      },
    ];
     this.LoadChatAudioAlertOptions();
    if (payload && payload.menus) {
      _.forEach(payload.menus, (menu: NavBarMenuItem) => {
        this.updateMenuItem(menu);
      });
    }
  }

  LoadVCCListAfterJoinNavBar(payload: any = {}): void {
    this.LoadHostUserNavBar(payload);
  }

  /**
   * load host user nav bar
   */
  LoadHostUserNavBar(payload: any): void {
    let currentUser: IUser = Companion.getUserService().currentUser;
    this.menus = [
      {
        key: NavBarMenuItemKey.VCC,
        position:
          currentUser.permissions && currentUser.permissions.seeActiveEndpointInQueue > 0 && currentUser.isAuthenticated
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        defaultTitle: "Video Call Center",
        icon: "fas fa-headset",
        clickAction: ActionType.OpenVCCList,
        active: payload && payload.active === NavBarMenuItemKey.VCC,
      },
      {
        key: NavBarMenuItemKey.AgentInterface,
        position:
          currentUser.permissions && currentUser.permissions.seeActiveEndpointInQueue > 0 && currentUser.isAuthenticated
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        defaultTitle: "Agent Interface",
        icon: "fas fa-headset",
        clickAction: ActionType.OpenVCC,
        active: payload && payload.active === NavBarMenuItemKey.AgentInterface,
      },
      {
        key: NavBarMenuItemKey.Dashboard,
        position:
          currentUser.permissions &&
          currentUser.permissions.seeBillingData > 0 &&
          currentUser.isAuthenticated &&
          this.store.getState().dashboardEnabled
            ? NavBarMenuPosition.Left
            : NavBarMenuPosition.None,
        defaultTitle: "Dashboard & Reports",
        icon: "fas fa-chart-line",
        clickAction: ActionType.OpenDashboard,
        active: payload && payload.active === NavBarMenuItemKey.Dashboard,
      },
      {
        key: NavBarMenuItemKey.ThemeManager,
        position:
          currentUser.permissions && currentUser.permissions.seeThemes > 0 && currentUser.isAuthenticated
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        defaultTitle: "Theme Manager",
        icon: "fas fa-palette",
        clickAction: ActionType.OpenThemeManager,
        active: payload && payload.active === NavBarMenuItemKey.ThemeManager,
      },
      {
        key: NavBarMenuItemKey.SystemConfiguration,
        position:
          currentUser.permissions && currentUser.permissions.seeMcuConfig > 0 && currentUser.isAuthenticated
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        defaultTitle: "System Configuration",
        icon: "fas fa-cog",
        clickAction: ActionType.OpenSystemConfiguration,
        active: payload && payload.active === NavBarMenuItemKey.SystemConfiguration,
      },
      {
        key: NavBarMenuItemKey.UserManagement,
        position:
          currentUser.permissions && currentUser.permissions.createUsers > 0 && currentUser.isAuthenticated
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        defaultTitle: "User Management",
        icon: "fas fa-users-cog",
        clickAction: ActionType.OpenUserManagement,
        active: payload && payload.active === NavBarMenuItemKey.UserManagement,
      },
      {
        key: NavBarMenuItemKey.RoleManagement,
        position:
          currentUser.permissions && currentUser.permissions.modifyRoles > 0 && currentUser.isAuthenticated
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        defaultTitle: "Role Management",
        icon: "fas fa-tasks",
        clickAction: ActionType.OpenRoleManagement,
        active: payload && payload.active === NavBarMenuItemKey.RoleManagement,
      },
      {
        key: NavBarMenuItemKey.GroupManagement,
        position:
          currentUser.permissions && currentUser.permissions.createChildGroups > 0 && currentUser.isAuthenticated
            ? NavBarMenuPosition.Right
            : NavBarMenuPosition.None,
        defaultTitle: "Group Management",
        icon: "fas fa-sitemap",
        clickAction: ActionType.OpenGroupManagement,
        active: payload && payload.active === NavBarMenuItemKey.GroupManagement,
      },
      {
        key: NavBarMenuItemKey.MyAccount,
        position: currentUser.isAuthenticated ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        defaultTitle: "My Account",
        icon: "fas fa-user-circle",
        clickAction: ActionType.OpenMyAccount,
        active: payload && payload.active === NavBarMenuItemKey.MyAccount,
      },
      {
        key: NavBarMenuItemKey.SignIn,
        position: currentUser.isAuthenticated ? NavBarMenuPosition.None : NavBarMenuPosition.Right,
        title: ".loginPanel.loginText",
        defaultTitle: "Sign In",
        icon: "fas fa-sign-in-alt",
        clickAction: ActionType.OpenLoginModal,
        payload: { callback: ActionType.OpenDefaultScreen },
      },
      {
        key: NavBarMenuItemKey.SignOut,
        position: currentUser.isAuthenticated ? NavBarMenuPosition.Right : NavBarMenuPosition.None,
        title: ".loginPanel.logoutText",
        defaultTitle: "Sign Out",
        icon: "fas fa-sign-out-alt",
        clickAction: ActionType.LogOut,
        payload: { callback: ActionType.OpenDefaultScreen },
      },
    ];
  }

  /**
   * update menu item by key
   */
  updateMenuItem(item: NavBarMenuItem) {
    if (!item) {
      return;
    }
    let curItems: NavBarMenuItem[] = _.filter(this.menus, { key: item.key });
    _.forEach(curItems, (curItem: NavBarMenuItem) => {
      _.assignIn(curItem, item);
    });
  }

  /**
   * increase nav bar notification
   */
   IncreaseNavBarNotification(payload: any) {
    this.increaseTagCount(payload.key, payload.step, payload.color);
  }

  /**
   * reset nav bar notification
   */
  ResetNavBarNotification(payload: any) {
    this.resetTagCount(payload.key, payload.value, payload.color, payload.alert);
  }

  /**
   * increase tag count
   * @param itemKey
   * @param step
   * @param color
   */
  increaseTagCount(itemKey: NavBarMenuItemKey, step: number = 1, color: string = "danger") {
    let curItems: NavBarMenuItem[] = _.filter(this.menus, { key: itemKey });
    _.forEach(curItems, (curItem: NavBarMenuItem) => {
      curItem.tagCount = curItem.tagCount ? curItem.tagCount + step : step;
      curItem.tagColor = color;
    });
  }

  /**
   * reset tag count
   */
  resetTagCount(itemKey: NavBarMenuItemKey, value: number = 0, color: string = "danger", alert: boolean = false) {
    this.updateMenuItem({ key: itemKey, tagCount: value, tagColor: color, alert: alert });
    if (itemKey == NavBarMenuItemKey.Chat)
    {
       if (value > 0 && 
           this.chatAudioAlertOption != "none" &&
           !Companion.getRTCClient().silenceChatAudioAlerts)
       {
          this.startChatAudioAlert();
       }
       else
       {
          this.stopChatAudioAlert();
       }
    }
  }

  /**
   * update tag content
   */
  updateTagContent(itemKey: NavBarMenuItemKey, content: string) {
    this.updateMenuItem({ key: itemKey, tagContent: content});
  }

  /**
   * browser capable of screen capture
   */
  get isScreenCaptureCapable(): boolean {
    if (this.isMobileDevice) {
      return false;
    }
    return (
      Browser.whichBrowser() === "Firefox" || (navigator.mediaDevices as any).getDisplayMedia || (window as any).chrome
    );
  }


  /**
   * flag if the current endpoint is conference host
   */
  get isHost(): boolean {
    return Companion.getEndpointService().myEndpoint.isHost;
  }

  /**
   * check if the toolbar item visible
   */
  isToolbarItemVisible(key: string): boolean {
    let result: boolean = false;
    let isAvailable: boolean = this.localizationService.getValueByPath(".toolbar.toolbar_items." + key + ".text");
    if (isAvailable) {
      result = true;
      if (this.localizationService.getValueByPath(".toolbar.toolbar_items." + key + ".hostOnly") && !this.isHost) {
        result = false;
      }
    }
    return result;
  }

  getMenuItem(key: NavBarMenuItemKey): NavBarMenuItem {
    return _.find(this.menus, {key: key});
  }

  /**
   * update all menu items that could have potentially changed...
   */
  updateAllMenuItems(): void {
    let rtcClient = Companion.getRTCClient();
    let isParticipatingInConf = 0 < Companion.getConferenceService().countParticipatingConferences();
    let isMonitoring = 0 < Companion.getConferenceService().countMonitoringConferences();
    let isRecording = Companion.getConferenceService().isRecording();
    let canRecord = isMonitoring || isParticipatingInConf;
    let canSnapshot = isParticipatingInConf || isMonitoring;

    if (rtcClient.screenShareEnabled) {
      this.updateMenuItem({
        key: NavBarMenuItemKey.ShareScreen,
        active: rtcClient.screenShareEnabled,
        title: ".toolbar.toolbar_items.shareScreen.altText",
        defaultTitle: "Stop Share Screen",
        defaultAriaLabel: "Screen Sharing is in progress. Press Enter to Stop Screen Sharing.",
        subMenu: [
          {
            key: NavBarMenuItemKey.OptimizeForVideo,
            title: ".toolbar.toolbar_items.optimizeForVideo.text",
            defaultTitle: "Optimize for Video",
            clickAction: ActionType.ToggleOptimizeForVideo,
            displayCheckbox: true,
            isChecked: rtcClient.presentationMode === PresentationMode.Motion_First
          }
        ],
        disabled : false,
      });
    } else {
      this.updateMenuItem({
        key: NavBarMenuItemKey.ShareScreen,
        active: rtcClient.screenShareEnabled,
        title: ".toolbar.toolbar_items.shareScreen.text",
        defaultTitle: "Share Screen",
        defaultAriaLabel: "Press Enter to Start Screen Sharing.",
        subMenu: null,
        disabled: !isParticipatingInConf,
      });
    }

    if (rtcClient.showContentEnabled) {
      this.updateMenuItem({
        key: NavBarMenuItemKey.ContentShare,
        active: rtcClient.showContentEnabled,
        title: ".toolbar.toolbar_items.contentShare.altText",
        defaultTitle: "Stop Content Share",
        defaultAriaLabel: "Content Sharing is in progress. Press Enter to Stop Content Sharing.",
        disabled: false
      });
    } else {
      this.updateMenuItem({
        key: NavBarMenuItemKey.ContentShare,
        active: rtcClient.showContentEnabled,
        title: ".toolbar.toolbar_items.contentShare.text",
        defaultTitle: "Share Content",
        defaultAriaLabel: "Press Enter to Start Screen Sharing.",
        subMenu: null,
        disabled: !isParticipatingInConf,
      });
    }

    // update button enablement if we are connected. (Record, Screen shot, file share)
    if (!isRecording) {
    this.updateMenuItem({
      key: NavBarMenuItemKey.Record,
      title: ".toolbar.toolbar_items.record.text",
      icon: "fas fa-bullseye",
      color: this.localizationService.getValueByPath(".toolbar.fontColor"),
      disabled: !canRecord,
    });
    } else {
      this.updateMenuItem({
        key: NavBarMenuItemKey.Record,
        title: ".toolbar.toolbar_items.record.altText",
        icon: "fas fa-pause-circle",
        color: "#d9534f",
      });
    }
    this.updateMenuItem({
      key: NavBarMenuItemKey.Snapshot,
      disabled: !canSnapshot,
    });

    if (rtcClient.audioMuted || !rtcClient.microphoneAccessible) {
      this.updateMenuItem({
        key: NavBarMenuItemKey.Audio,
        title: ".toolbar.toolbar_items.audio.altText",
        defaultTitle: "Audio Off",
        defaultAriaLabel: "Audio is Off, Press Enter to Turn Audio On.",
        icon: "fas fa-microphone-slash",
        disabled: Companion.getRTCClient().monitoring
      });
    } else {
      this.updateMenuItem({
        key: NavBarMenuItemKey.Audio,
        title: ".toolbar.toolbar_items.audio.text",
        defaultTitle: "Audio On",
        defaultAriaLabel: "Audio is On, Press Enter to Turn Audio Off.",
        icon: "fas fa-microphone",
        disabled: Companion.getRTCClient().monitoring
      });
    }
    if (rtcClient.videoMuted || !rtcClient.cameraAccessible) {
      this.updateMenuItem({
        key: NavBarMenuItemKey.Video,
        title: ".toolbar.toolbar_items.video.altText",
        defaultTitle: "Video Off",
        defaultAriaLabel: "Video is Off, Press Enter to Turn Video On.",
        icon: "fas fa-video-slash",
      });
    } else {
      this.updateMenuItem({
        key: NavBarMenuItemKey.Video,
        title: ".toolbar.toolbar_items.video.text",
        defaultTitle: "Video On",
        defaultAriaLabel: "Video is On, Press Enter to Turn Video Off.",
        icon: "fas fa-video",
      });
    }
    if (!rtcClient.filteredStreamEnabled) {
     this.updateMenuItem({
       key: NavBarMenuItemKey.VirtualBackground,
       title: ".toolbar.toolbar_items.virtualBackground.text",
       defaultTitle: "Virtual Background",
       icon: "fas fa-hand-sparkles",
       color: this.localizationService.getValueByPath(".toolbar.fontColor")
     });
    } else {
     this.updateMenuItem({
       key: NavBarMenuItemKey.VirtualBackground,
       title: ".toolbar.toolbar_items.virtualBackground.altText",
       defaultTitle: "Stop Virtual Background",
       icon: "fas fa-window-close",
       color: "#d9534f"
     });
    }
  }

  /**
   * get menu item icon class
   * @param menu: NavBarMenuItem - menu item
   */
  getIcon(menu: NavBarMenuItem) {
    if (!menu) {
      return null;
    }
    let result = "", themeIcon;
    result = menu.icon;
    if (menu.toolbarItemKey) {
      themeIcon = this.localizationService.getValueByPath(".toolbar.toolbar_items." + menu.toolbarItemKey + ".icon");
      if (themeIcon && themeIcon !== "") {
        result = themeIcon;
      }
    }
    if (menu.active && menu.activeIcon) {
      result = menu.activeIcon;
    }
    return result;
  }

  /**
   * get menu item title
   * @param menu: NavBarMenuItem - menu item
   */
  getTitle(menu: NavBarMenuItem) {
    if (!menu) {
      return null;
    }
    return menu.title ? this.localizationService.getValueByPath(menu.title) || menu.defaultTitle : menu.defaultTitle;
  }

  /**
   * get menu item aria label
   * @param menu: NavBarMenuItem - menu item
   */
  getAriaLabel(menu: NavBarMenuItem) {
    let result;
    if (!menu) {
      return null;
    }
    if (menu.defaultAriaLabel) {
      result = menu.defaultAriaLabel;
    }
    if (menu.ariaLabel) {
      if (this.localizationService.getValueByPath(menu.ariaLabel)) {
        return this.localizationService.getValueByPath(menu.ariaLabel);
      }
    }
    if (!result) {
      result = this.getTitle(menu);
    }
    return result;
  }

  /**
   * get menu item color
   */
  getColor(menu?: NavBarMenuItem) {
    if (!menu) {
      return this.localizationService.getValueByPath(".toolbar.fontColor");
    }
    return menu.active
      ? this.localizationService.getValueByPath(".toolbar.activeColor") || menu.activeColor
      : menu.hover
        ? this.localizationService.getValueByPath(".toolbar.hoverColor") || menu.hoverColor
        : this.localizationService.getValueByPath(".toolbar.fontColor") || menu.color;
  }

   LoadChatAudioAlertOptions() 
   {
      // Load configuration settings for chat audio alerts
      this.chatAudioAlertOption = this.localizationService.myLocalizationData?.chat_panel?.chatAudioAlertOption ?? this.chatAudioAlertOption;
      this.chatAudioAlertType = this.localizationService.myLocalizationData?.chat_panel?.chatAudioAlertType ?? this.chatAudioAlertType;
      this.chatAudioAlertFrequency = this.localizationService.myLocalizationData?.chat_panel?.chatAudioAlertFrequency ?? this.chatAudioAlertFrequency;
      this.chatAudioAlertDuration = this.localizationService.myLocalizationData?.chat_panel?.chatAudioAlertDuration ?? this.chatAudioAlertDuration;
      this.chatAudioAlertInterval = this.localizationService.myLocalizationData?.chat_panel?.chatAudioAlertInterval ?? this.chatAudioAlertInterval;
      /*
      console.log("option:", this.localizationService.myLocalizationData.chat_panel.chatAudioAlertOption,
                  "type:", this.localizationService.myLocalizationData.chat_panel.chatAudioAlertType,
                  "frequency:", this.localizationService.myLocalizationData.chat_panel.chatAudioAlertFrequency,
                  "duration:", this.localizationService.myLocalizationData.chat_panel.chatAudioAlertDuration,
                  "interval:", this.localizationService.myLocalizationData.chat_panel.chatAudioAlertInterval);
      console.log("Option:", this.chatAudioAlertOption,
                  "Type:", this.chatAudioAlertType,
                  "Frequency:", this.chatAudioAlertFrequency,
                  "Duration:", this.chatAudioAlertDuration,
                  "Interval:", this.chatAudioAlertInterval);
      */
      if (this.allowSilenceChatAudioAlerts())
      {
         // If we are allowing the chat audio alert to be silenced then load the saved setting from the cookie
         if (Companion.getRTCClient())
         {
            Companion.getRTCClient().silenceChatAudioAlerts = ((Cookie.getCookie("SilenceChatAudioAlerts") || "false") == "true");
         }
         // As we are allowing the chat audio alert to be silenced, add the toggle option to the chat sub menu
         this.updateMenuItem({
            key: NavBarMenuItemKey.Chat,
            position:
              this.isToolbarItemVisible("chat")
                ? NavBarMenuPosition.Left
                : NavBarMenuPosition.None,
            title: ".toolbar.toolbar_items.chat.text",
            toolbarItemKey: "chat",
            defaultTitle: "Chat",
            icon: "fas fa-comments",
            clickAction: ActionType.ToggleChatPanel,
            subMenu: [
               {
                  key: NavBarMenuItemKey.SilenceChatAudioAlerts,
                  title: ".chat_panel.silenceChatAudioAlerts.text",
                  defaultTitle: "Silence Chat Alerts",
                  clickAction: ActionType.ToggleSilenceChatAudioAlerts,
                  displayCheckbox: true,
                  isChecked: Companion.getRTCClient().silenceChatAudioAlerts
               }
            ],
         });
      }
   }

   allowSilenceChatAudioAlerts(): boolean {
      let result = false;
      if (this.localizationService.myLocalizationData.chat_panel &&
          this.localizationService.myLocalizationData.toolbar.toolbar_items.chat &&
          this.localizationService.myLocalizationData.chat_panel.silenceChatAudioAlerts &&
          this.chatAudioAlertOption != "none") 
      {
         result = true;
         if (this.localizationService.myLocalizationData.chat_panel.silenceChatAudioAlertsHostOnly &&
            !Companion.getEndpointService().myEndpoint.isHost)
         {
            result = false;
         }
      }
      return result;
   }

   toggleSilenceChatAudioAlerts(menuItem: NavBarMenuItem) 
   {
      if (menuItem?.displayCheckbox)
      {
         if (menuItem.isChecked) 
         {
            Companion.getRTCClient().silenceChatAudioAlerts = true;
            this.stopChatAudioAlert();
         } 
         else 
         {
            Companion.getRTCClient().silenceChatAudioAlerts = false;
         }
         Cookie.setCookie("SilenceChatAudioAlerts", Companion.getRTCClient().silenceChatAudioAlerts ? "true" : "false");
      }
   }

   displayAlertIcon(menu: NavBarMenuItem) 
   {
      if (!menu) 
         return false;
      if (menu.key == NavBarMenuItemKey.Chat)
         return Companion.getRTCClient().silenceChatAudioAlerts;
      else
         return false;
   }

   getAlertIcon(menu: NavBarMenuItem) 
   {
      if (!menu) 
         return "";
      if (menu.key == NavBarMenuItemKey.Chat)
          return Companion.getRTCClient().silenceChatAudioAlerts ? "fa fa-volume-mute" : "";
      else
         return "";
   }

   playChatAudioAlert()
   {
      //console.log("playChatAudioAlert ...");
      this.chatAudioAlertOscillator = this.chatAudioAlertContext.createOscillator();
      this.chatAudioAlertGain = this.chatAudioAlertContext.createGain();
      this.chatAudioAlertOscillator.connect(this.chatAudioAlertGain);
      this.chatAudioAlertGain.connect(this.chatAudioAlertContext.destination);
      this.chatAudioAlertOscillator.type = <OscillatorType>(this.chatAudioAlertType);
      this.chatAudioAlertOscillator.frequency.value = this.chatAudioAlertFrequency;
      this.chatAudioAlertGain.gain.value = 0.10;
      this.chatAudioAlertOscillator.start(this.chatAudioAlertContext.currentTime);
      this.chatAudioAlertOscillator.stop(this.chatAudioAlertContext.currentTime + this.chatAudioAlertDuration/1000)
      this.chatAudioAlertOscillator.addEventListener('ended', () => {
         //console.log("playChatAudioAlert ended ... this.chatAudioAlertActive:", this.chatAudioAlertActive);
         setTimeout(() => { 
                      //console.log("playChatAudioAlert ended timeout  ... this.chatAudioAlertActive:", this.chatAudioAlertActive);
                      if (this.chatAudioAlertActive)
                      {
                         if (this.chatAudioAlertOption == "repetitive")
                         {
                            //console.log("playChatAudioAlert ended timeout ... calling playChatAudioAlert");
                            this.playChatAudioAlert(); 
                         }
                         else
                         {
                            // played it once; no need to continue
                            //console.log("playChatAudioAlert ended timeout ... clearing chatAudioAlertActive");
                            this.chatAudioAlertActive = false;
                         }
                      }
                    }, 
                    this.chatAudioAlertInterval);
      });
   }

   startChatAudioAlert()
   {
      if (!this.chatAudioAlertActive)
      {
         //console.log("startChatAudioAlert ...");
         this.chatAudioAlertActive = true;
         this.playChatAudioAlert();
      }
   }

   stopChatAudioAlert()
   {
      if (this.chatAudioAlertActive)
      {
         //console.log("stopChatAudioAlert ...");
         this.chatAudioAlertActive = false;
      }
   }

}

export enum NavBarMenuItemKey {
  SignIn,
  SignOut,
  Settings,
  Leave,
  Chat,
  ShareScreen,
  ContentShare,
  RemoteDesktop,
  ShareFiles,
  ShareLink,
  Participants,
  IncomingQueue,
  Operators,
  Map,
  Record,
  Snapshot,
  Audio,
  Video,
  Inspector,
  FullScreen,
  VideoAspect,
  ThemeManager,
  SystemConfiguration,
  UserManagement,
  RoleManagement,
  GroupManagement,
  MyAccount,
  VCC,
  Lock,
  Unlock,
  EditProfile,
  OptimizeForVideo,
  Blur,
  VirtualBackground,
  Dashboard,
  SilenceChatAudioAlerts,
  AgentInterface
}
export interface NavBarMenuItem {
  key: NavBarMenuItemKey;
  position?: NavBarMenuPosition;
  displayWithTagContent?: boolean;
  title?: string;
  ariaLabel?: string;
  defaultAriaLabel?: string;
  toolbarItemKey?: string;
  defaultTitle?: string;
  icon?: string;
  color?: string;
  clickAction?: ActionType;
  tagColor?: string;
  tagCount?: number;
  tagContent?: string;
  active?: boolean;
  payload?: any;
  hoverColor?: string;
  activeColor?: string;
  activeIcon?: string;
  hover?: boolean;
  alert?: boolean;
  displayCheckbox?: boolean;
  isChecked?: boolean;
  subMenu?: NavBarMenuItem[];
  disabled?: boolean;
}
export enum NavBarMenuPosition {
  None,
  LeftStatic,
  Left,
  Right,
}
