<!--
    Copyright Compunetix Incorporated 2016-2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<ul class="list-group participants-container">
  <li class="form-inline row" *ngIf="(listType === 'ep' || listType === 'main' || listType === 'sub' || listType === 'confWait')">
    <div [ngClass]="{'col-7 pr-0': canChat, 'col-12': !canChat}">
      <input
        type="text"
        class="form-control w-100"
        placeholder="Type to filter the participants"
        [(ngModel)]="viewModel.filter"
      />
      <a
        class="btn reset-btn"
        (click)="resetSearch()"
        [hidden]="!viewModel.filter"
      ><i class="fas fa-times"></i></a>
    </div>
    <div
      class="col-5 pl-0"
      *ngIf="canChat"
    >
      <button class="btn btn-primary w-100" (click)="createNewGroupChat()" id="new-group-chat-button">
        {{localizationService.myLocalizationData.chat_panel.startGroupChatButtonText || "New Group Chat"}}
      </button>
    </div>
  </li>
  <li
    class="list-group-item"
    tabindex="0"
    *ngIf="(listType === 'ep' || listType === 'main' || listType === 'sub' || listType === 'confWait') && canChatWithAPIUser"
  >
    <div class="row participant-list-row">
      <div class="name-section col-6">
        <label> <i class="fas fa-user-circle"></i> </label>
        <label [title]="endpointService.externalOperatorEndpoint.name">
          {{ endpointService.externalOperatorEndpoint.name | truncate:20:'...' }}
        </label>
      </div>
      <div class="present-section col-6 no-padding">
        <a
          class="text-dark cursor-pointer"
          (click)="peerChat(endpointService.externalOperatorEndpoint)"
        ><i class="fas fa-comments"></i></a>
      </div>
    </div>
  </li>
  <li
    class="list-group-item"
    tabindex="0"
    [style.color]="localizationService.myLocalizationData.participant_panel.fontColor"
    [style.background]="localizationService.myLocalizationData.participant_panel.backgroundColor"
    *ngFor="let endpoint of sortedEndpoints; trackBy: trackByEndpoint"
    [hidden]="isEndpointHidden(endpoint)"
    [ngStyle]="{'border-left':
    !endpointService.isOperator(endpoint)
    && endpointService.connectedToMe(endpoint) ?
    '8px solid ' + localizationService.myLocalizationData?.footer?.backgroundColor || '' : '1px solid #ddd',
    'padding':
    !endpointService.isOperator(endpoint)
    && endpointService.connectedToMe(endpoint) ?
    '10px 10px 10px 3px' : '10px 10px 10px 10px'
    }"
  >
    <guest-item
      *ngIf="
        (listType === 'publicWait')
        && endpoint.state !== PresenceState.xa"
      [viewMode]="viewMode"
      [listType]="listType"
      [transferHasSelected]="transferHasSelected"
      (toggleTransferSelection)="toggleTransferSelection($event)"
      [endpoint]="endpoint"
      [isMaxParticipantsReached]="isMaxParticipantsReached"
      [numberOfParticipants]="numberOfParticipants"
      [guestToAnswerEp]="guestToAnswerEp"
      (peerChat)="peerChat($event)"
      (peerVideoChat)="peerVideoChat($event)"
      (disconnectPeer)="disconnectPeer($event)"
      (hangupRingingCall)="hangupRingingCall($event)"
      (retrieveData)="retrievePeerData($event)"
      (toggleEndpointVideo)="toggleEndpointVideo($event)"
      (toggleNotepad)="toggleNotepad($event)"
      (openGuestInfoModal)="openGuestInfoModal($event)"
    ></guest-item>
    <operator-item
      *ngIf="listType === 'op' || listType === 'sp'"
      [viewMode]="viewMode"
      [listType]="listType"
      [transferHasSelected]="transferHasSelected"
      [endpoint]="endpoint"
      [isMaxParticipantsReached]="isMaxParticipantsReached"
      [numberOfParticipants]="numberOfParticipants"
      [canMakePhoneCall]="canMakePhoneCall"
      (peerChat)="peerChat($event)"
      (peerVideoChat)="peerVideoChat($event)"
      (disconnectPeer)="disconnectPeer($event)"
      (toggleEndpointVideo)="toggleEndpointVideo($event)"
      (startMonitor)="startMonitor($event)"
      (exitMonitor)="exitMonitor($event)"
      (pushToTalk)="pushToTalk($event)"
      (dialOut)="dialOut()"
      (cancelCall)="cancelCall($event)"
      (openKeypad)="openKeypad()"
      [canTransferTo]="canTransferTo"
      [canConferenceIn]="canConferenceIn"
      (openOperatorInfoModal)="openOperatorInfoModal($event)"
    ></operator-item>
  </li>
  <!-- Add some padding so we don't get vertical scroll constantly-->
  <div class="p-5"></div>
</ul>
