/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { TreeViewItem } from "./tree-item";

@Component({
  selector: "tree-item",
  templateUrl: "./tree-item.template.html"
})
export class TreeItemComponent {
  @Input()
  item: TreeViewItem;

  @Input()
  disabled: boolean = false;

  @Input()
  isLast: boolean;

  @Input()
  unselectAvailable: boolean = false;

  @Input()
  multiSelectAvailable: boolean = false;

  @Input()
  deleteItemAvailable: boolean = false;

  @Output("select")
  onSelectEventEmitter: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

  @Output("remove")
  onRemoveEventEmitter: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

  @Input() levelSelectionAvailable: boolean = false;

  @Input() levelOptions: any[];

  @Output("levelSelectionChanged")
    onLevelSelectionChangedEventEmitter: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

  onSelect(selectedItem: TreeViewItem) {
    if (!this.disabled) {
      this.onSelectEventEmitter.emit(selectedItem);
    }
  }

  removeTreeViewItem(removeItem: TreeViewItem) {
    this.onRemoveEventEmitter.emit(removeItem);
  }

  treeItemLevelSelectionChangedTreeViewItem(levelSelectionChangedItem: TreeViewItem, value: number) {
    levelSelectionChangedItem.value.proficiencyLevel = Number(value);
    this.onLevelSelectionChangedEventEmitter.emit(levelSelectionChangedItem);
  }

  treeViewLevelSelectionChangedTreeViewItem(levelSelectionChangedItem: TreeViewItem) {
    this.onLevelSelectionChangedEventEmitter.emit(levelSelectionChangedItem);
  }

}
