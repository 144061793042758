/**
 * Copyright Compunetix Incorporated 2016-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import {
  IEndpoint,
  Companion,
  PresenceState,
  PresenceStatus,
  Cookie,
  VideoMediaConnectionMode,
  EndpointService,
  ConferenceUtil,
  IConferenceExpanded,
  Endpoint,
  IPushToTalkEvent
} from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { ParticipantList } from "./participant-list";
import { GroupManagementService } from "client/scripts/group-management/group-management.service";
import { Dispatcher, ActionType } from "client/scripts/shared/services/dispatcher";


@Component({
  selector: "participant-panel",
  templateUrl: "./participant-panel.template.html"
})
/**
 * participant panel
 */
export class ParticipantPanelComponent implements OnInit {
  /**
   * the conference this panel belongs to
   */
  @Input() conference: IConferenceExpanded;

  /**
   * all list types this panel should display
   */
  @Input() listTypes: string[];

  /**
   * the current view mode
   */
  @Input() viewMode: string;

  /**
   * the position of this panel
   */
  @Input() position: string;

  /**
   * flag if operator is selected
   */
  @Input() transferHasSelected: boolean;

  /**
   * the layout to display lists: tab or list
   */
  @Input() layout: string;

  /**
   * flag if able to make phone call
   */
  @Input() canMakePhoneCall: boolean;

  /**
   * endpoint to answer
   */
  @Input() guestToAnswerEp: IEndpoint;


  @Input() canTransferTo : (endpoint: IEndpoint) => boolean;
  @Input() canConferenceIn : (endpoint: IEndpoint) => boolean;


  /**
   * the event emitter to send event to parent for showPeerChatbox event
   */
  @Output("showPeerChatbox") showPeerChatboxEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent for toggleParticipantPanel event
   */
  @Output("toggleParticipantPanel") toggleParticipantPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * the event emitter to send event to parent for toggleMenuPanel event
   */
  @Output("toggleMenuPanel") toggleMenuPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * the event emitter to send event to parent for peerChat event
   */
  @Output("peerChat") peerChatEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent for peerVideoChat event
   */
  @Output("peerVideoChat") peerVideoChatEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent for disconnectPeer event
   */
  @Output("disconnectPeer") disconnectPeerEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent for resumeSubConference event
   */
  @Output("resumeSubConference") resumeGuestConferenceEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  @Output("hangupRingingCall") hangupRingingCallEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * the event emitter to send event to parent for retrievePeer event
   */
  @Output("retrievePeer") retrievePeerEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent for toggleTransferSelection event
   */
  @Output("toggleTransferSelection") toggleTransferSelectionEmitter: EventEmitter<IEndpoint> = new EventEmitter<
    IEndpoint
  >();

  /**
   * the event emitter to send event to parent for toggleEndpointVideo event
   */
  @Output("toggleEndpointVideo") toggleEndpointVideoEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to start monitor on the endpoint
   */
  @Output("startMonitor")
  startMonitorEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to exit monitor on the endpoint
   */
  @Output("exitMonitor")
  exitMonitorEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to push to talk to monitored endpoint
   */
  @Output("pushToTalk")
  pushToTalkEmitter: EventEmitter<IPushToTalkEvent> = new EventEmitter<IPushToTalkEvent>();

  /**
   * the event emitter to send event to parent to toggle notepad on the endpoint
   */
  @Output("toggleNotepad")
  toggleNotepadEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to open dial out dialog
   */
  @Output("dialOut")
  dialOutEmitter: EventEmitter<any> = new EventEmitter<any>();

  /**
   * the event emitter to send event to parent to cancel call
   */
  @Output("cancelCall")
  cancelCallEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to open keypad dialog
   */
  @Output("openKeypad")
  openKeypadEmitter: EventEmitter<any> = new EventEmitter<any>();

  /**
   * the event emitter to send event to parent to open guest info dialog
   */
  @Output("openGuestInfoModal")
  openGuestInfoModalEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the event emitter to send event to parent to open operator info dialog
   */
  @Output("openOperatorInfoModal")
  openOperatorInfoModalEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();

  /**
   * the actual lists of participants (endpoints) to display
   */
  lists: ParticipantList[] = [];

  /**
   * the current tab view index
   */
  tabIndex: number = 0;

  // get the available skills to filter on.
  availableSkills: string[] = [];
  // get the skill (OR ALL)
  currentSkill: string;

  // get the available skills to filter on.
  availableLanguages: string[] = [];
  // get the language index (the current skill selected.) (OR ALL)
  currentLanguage: string;

  dataLoaded: boolean = false;

  /**
   * flag if it's mobile app
   */
  @Input() isMobileApp: boolean;

  get ngStyleBottom() : string {
    let result: string;
    if (this.localizationService.myLocalizationData.participant_panel.bottom){
      result = this.localizationService.myLocalizationData.participant_panel.bottom.toString()+"px";
    } else if (!this.localizationService.myLocalizationData?.footer) {
     result = '0px !important'
    }
    return result;
  }

  /**
   * count online endpoints
   */
  public onlineEndpointsCount(list: ParticipantList): number {
    if (!list || !list._endpoints) {
      return 0;
    }
    
    return _.filter(list._endpoints, (ep: IEndpoint) => {
      //  Filter out offline users and also those on custom break (i.e. specifically incommunicable)
      return ep?.status && Endpoint.getPresenceStateByStatus(ep.status) !== PresenceState.xa &&
        (ep.status !== PresenceStatus.custom1 &&
          ep.status !== PresenceStatus.custom2 &&
          ep.status !== PresenceStatus.custom3 &&
          ep.status !== PresenceStatus.custom4 &&
          ep.status !== PresenceStatus.away);
    }).length;
  }

  constructor(public localizationService: LocalizationService, private groupManagementService: GroupManagementService) {
    Dispatcher.register(ActionType.LoadVCCNewData, this.LoadVCCNewData.bind(this), "loadParticipants");
  }

  /**
   * component load handler
   */
  ngOnInit() {
    this.lists = [];
    Promise.resolve()
    .then(() => {
      if (Companion.getUserService().currentUser.isAuthenticated) {
        return this.groupManagementService.getAllAccessibleGroups();
      }
    })
    .catch(() => {
      // nothing needed here
    })
    .then(() => {
      this.dataLoaded = true;
      this.LoadVCCNewData();
      this.updateLists();
    });
  }


  LoadVCCNewData() {
    this.availableSkills = _.clone(ConferenceUtil.getCategoryListFromSkillProficiencyList(EndpointService.getSharedInstance().myEndpoint?.skillSet?.categories));
    this.availableLanguages = _.clone(EndpointService.getSharedInstance().myEndpoint?.skillSet?.languages);

    this.currentSkill = this.currentSkill || Cookie.getCookie("currentSkill") || "ALL";
    if(this.availableSkills?.length > 1)
    {
      this.availableSkills?.unshift("ALL");
      if(!this.availableSkills.includes(this.currentSkill))
      {
        this.currentSkill = "ALL";
      }
    }
    else if (this.availableSkills?.length == 1) {
      this.currentSkill = this.availableSkills[0];
      this.availableSkills = null;
    }
    else
    {
      this.currentSkill = null;
      this.availableSkills = null;
    }

    this.currentLanguage = this.currentLanguage || Cookie.getCookie("currentLanguage") || "ALL";
    if(this.availableLanguages?.length > 1)
    {
      this.availableLanguages?.unshift("ALL");
      if(!this.availableLanguages.includes(this.currentLanguage))
      {

        this.currentLanguage = "ALL";
      }
    } else if (this.availableLanguages?.length == 1) {
      this.currentLanguage = this.availableLanguages[0];
      this.availableLanguages = null;
    } else {
      this.currentLanguage = null;
      this.availableLanguages = null;
    }
  }

  /**
   * update list
   */
  updateLists() {
    this.lists = this.lists.filter(l => this.listTypes.includes(l.listType));

    let focusList: ParticipantList;
    for (var i = 0; i < this.listTypes.length; ++i) {
      let participantList: ParticipantList = ParticipantList.getParticipantList(this.listTypes[i], this.conference,
        this.localizationService.myLocalizationData);

      // if it is an OP or SP, filter on the current skill
      if(this.currentSkill && this.currentLanguage && (this.listTypes[i] == "op" || this.listTypes[i] == "sp"))
      {
        participantList._endpoints = _.filter(participantList._endpoints, (endpoint : IEndpoint) =>
        {
          return ((ConferenceUtil.getCategoryListFromSkillProficiencyList(endpoint?.skillSet?.categories)?.includes(this.currentSkill) || this.currentSkill == "ALL") &&
                  (endpoint?.skillSet?.languages.includes(this.currentLanguage) || this.currentLanguage == "ALL"))
        });
      }

      let existingList: ParticipantList = _.find(this.lists, {listType: this.listTypes[i]});
      if (existingList) {
        const iterateBy = existingList.listType === "publicWait" ||
                          existingList.listType === "ep" ? "rtcId" : "name";
        existingList._endpoints = _.intersectionBy(participantList._endpoints, existingList._endpoints, iterateBy);
        existingList._endpoints = _.unionBy(participantList._endpoints, existingList._endpoints, iterateBy);
      } else {
        this.lists.push(participantList);
      }
    }

    // sort the list in order from ALL, Main, sub (using the sorted weight) this
    // way the tabs appear in order
    this.lists = _.orderBy(this.lists, ["sortWeight"], ["desc"]);

    // focus the list we just transferred into
    if (focusList && this.lists.indexOf(focusList) > 0) {
      this.tabIndex = this.lists.indexOf(focusList);
    }

    // see if we were focused on a tab that no longer access.
    if (!this.lists[this.tabIndex]) {
      this.tabIndex = 0;
    }

    let updateListsTimer = setTimeout(() => {
      clearTimeout(updateListsTimer);
      this.updateLists();
    }, 1 * 1000);
  }

  getLocalizedSkillText(skill:string) {
    // TBD: Localization of these things.
    return skill == "ALL" ? "ALL Categories": skill;
  }

  getLocalizedLanguageText(lang:string) {
    // TBD: Localization of these things.
    return lang == "ALL" ? "ALL Languages":lang;
  }

  /**
   * close panel
   */
  closePanel(closeMenu = false): void {
    this.toggleParticipantPanelEmitter.emit(true);
    if (this.isMobileApp && closeMenu) {
      this.closeMenuPanel();
    }
  }

  /**
   * close menu panel
   */
  private closeMenuPanel(): void {
    this.toggleMenuPanelEmitter.emit(true);
  }

  /**
   * trigger text chat with endpoint
   * @param endpoint: IEndpoint - the endpoint to text chat with
   */
  peerChat(endpoint: IEndpoint): void {
    this.peerChatEmitter.emit(endpoint);
  }

  /**
   * trigger video chat with endpoint
   * @param endpoint: IEndpoint - the endpoint to video chat with
   */
  peerVideoChat(endpoint: IEndpoint): void {
    this.peerVideoChatEmitter.emit(endpoint);
  }

  /**
   * trigger disconnect with endpoint
   * @param endpoint: IEndpoint - the endpoint to disconnect with
   */
  disconnectPeer(endpoint: IEndpoint): void {
    this.disconnectPeerEmitter.emit(endpoint);
  }

  /**
   * trigger resume to guest sub conference
   * @param endpoint: IEndpoint - the endpoint to disconnect with
   */
  resumeSubConference(endpoint: IEndpoint): void {
    this.resumeGuestConferenceEmitter.emit(endpoint);
  }

  hangupRingingCall(phoneNumber: string): void {
    this.hangupRingingCallEmitter.emit(phoneNumber);
  }

  /**
   * trigger retrieve endpoint data
   * @param endpoint: IEndpoint - the endpoint
   */
  retrievePeer(endpoint: IEndpoint): void {
    this.retrievePeerEmitter.emit(endpoint);
  }

  /**
   * toggle operator selected
   * @param endpoint: IEndpoint - the endpoint to toggle selection
   */
  toggleTransferSelection(endpoint: IEndpoint): void {
    this.toggleTransferSelectionEmitter.emit(endpoint);
  }

  /**
   * mute or unmute the endpoint's video
   * @param endpoint: IEndpoint - the endpoint to toggle video
   */
  toggleEndpointVideo(endpoint: IEndpoint): void {
    this.toggleEndpointVideoEmitter.emit(endpoint);
  }

  /**
   * switch to list in tab layout
   * @param list: ParticipantList - the list to switch to
   */
  toggleTab(list: ParticipantList) {
    this.tabIndex = this.lists.indexOf(list);
  }

  /**
   * start monitor
   */
  startMonitor(endpoint: IEndpoint): void {
    this.startMonitorEmitter.emit(endpoint);
  }

  /**
   * exit monitor
   */
  exitMonitor(endpoint: IEndpoint): void {
    this.exitMonitorEmitter.emit(endpoint);
  }

  /**
   * Emit push to talk event
   */
  pushToTalk(event: IPushToTalkEvent) {
    this.pushToTalkEmitter.emit(event);
  }

  /**
   * toggle notepad
   */
  toggleNotepad(endpoint: IEndpoint) {
    this.toggleNotepadEmitter.emit(endpoint);
  }

  /**
   * Request to open guest info modal
   */
  openGuestInfoModal(endpoint: IEndpoint) {
    this.openGuestInfoModalEmitter.emit(endpoint);
  }

  /**
   * Request to open operator info modal
   */
  openOperatorInfoModal(endpoint: IEndpoint) {
    this.openOperatorInfoModalEmitter.emit(endpoint);
  }

  /**
   * trigger dial out popup opening
   */
  dialOut(): void {
    this.dialOutEmitter.emit();
  }

  /**
   * Emit cancel call event
   */
  cancelCall(endpoint: IEndpoint) {
    this.cancelCallEmitter.emit(endpoint);
  }

  /**
   * trigger keypad popup opening
   */
  openKeypad(): void {
    this.openKeypadEmitter.emit();
  }

  /**
   * back to menu panel
   */
  backToMenu() {
    this.closePanel();
  }

  /**
   * select a new skill or lanaguage to filter on
   */
  toggleFilter() {
    // update the cookies
    Cookie.setCookie("currentSkill", this.currentSkill);
    Cookie.setCookie("currentLanguage", this.currentLanguage);
  }

  /**
   * Return the number of participants
   */
  numberOfParticipants() {
    // For the Operator the monitoring supervisor doesn't exist
    return Companion.getConferenceService().currentActiveConference?.everyone?.filter(ep => !(ep?.transmitMode === VideoMediaConnectionMode.Broadcasting)).length || 0;
  }
}
