/**
 * Copyright Compunetix Incorporated 2016-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import {RoomType, IActiveConference, IQueueConference, IConferenceUpdate, IMonitorConference} from "./conference.interface";
import {IEndpoint, IEndpointRef} from "../endpoint/endpoint.interface";
import { Endpoint } from "../endpoint/endpoint";
import {IEndpointService} from "../endpoint/endpoint.service.interface";
import {EndpointService} from "../endpoint/endpoint.service";
import {ActiveConference, ConferenceUtil, ExpandedActiveConference, MonitorConference, QueueConference} from "./conference";
import {IChatRoom} from "../chatroom/chatroom.interface";
import {IMessage} from "../message/message.interface";
import {IConferenceService} from "./conference.service.interface";
import {AlertCode, AlertHandlerCallback, AlertHandlerCallbackOptions, AlertLevel} from "../alert/alert.interface";
import {RTCServiceConnectionEventHandler} from "./rtc.service.connection-event-handler";
import {RTCServiceStreamEventHandler} from "./rtc.service.stream-event-handler";
import {RTCServiceServerMessageHandler} from "./rtc.service.server-message-handler";
import {IRTCServiceConnectionEventHandler} from "../services/rtc.service.connection-event-handler.interface";
import {IRTCServiceFileEventHandler} from "../services/rtc.service.file-event-handler.interface";
import {IRTCServiceStreamEventHandler} from "../services/rtc.service.stream-event-handler.interface";
import {IRTCServiceServerMessageHandler} from "../services/rtc.service.server-message-handler.interface";
import {IConferenceBase} from "./conference.base.interface";
import { UserService } from "../user/user.service";
import { EasyRTCService } from "../services/rtc.service.easy";
import { ActiveConferenceSm } from "./conference-sm";
import { PeerConnectionSm } from "./peer-sm";
import { ISharedFile, ISharedFileRef } from "companion/sharedfile/sharedfile.interface";
import { RTCServiceFileEventHandler } from "./rtc.service.file-event-handler";
import { IPCStats } from "../util/stats";
import WebRTCIssueDetector, { IssueDetectorResult, IssuePayload, NetworkScores } from 'webrtc-issue-detector';

/**
 * conference methods delegate
 */
export class ConferenceService implements IConferenceService {
  private static sharedInstance: ConferenceService;

  public activeConference: string;
  public conferences: Map<string, ActiveConference> = new Map();
  // hold the state machines for conferences we are participating in. (includes monitor conferences as well)
  public conferenceSmMap: Map<string, ActiveConferenceSm> = new Map();
  // hold the peer connection state machines
  public peerSmMap: Map<string, PeerConnectionSm> = new Map()

  public queues: Map<string, QueueConference> = new Map();

  public monitors: Map<string, MonitorConference> = new Map();

  // Hold the files this client has by their unique ID
  public sharedFiles: Map<string, ISharedFile> = new Map();

  private notifyFileCompleteFn: (file: ISharedFile) => void = null;

  public preventReload: boolean = false;

  private endpointService: IEndpointService;

  public isServerTimeoutFired = false;
  public actionCompleted = true;
  public activeRecording = false;

  connectionEventHandler: IRTCServiceConnectionEventHandler;
  fileEventHandler: IRTCServiceFileEventHandler;
  serverMessageHandler: IRTCServiceServerMessageHandler;
  streamEventHandler: IRTCServiceStreamEventHandler;
  statsTimeout: NodeJS.Timeout;
  connectionIssueDetector: WebRTCIssueDetector;
  connectionIssueClears: Map<string, NodeJS.Timeout> = new Map();
  connectionMosClears: Map<string, NodeJS.Timeout> = new Map();

  get currentActiveConference(): ExpandedActiveConference {
    if(!this.activeConference) 
      return null;

    let activeRef = this.conferences.get(this.activeConference);
    if (!activeRef)
      return null;
    
    return new ExpandedActiveConference(activeRef, {
      active: EndpointService.getSharedInstance().expandEndpointRefs(activeRef.active),
      held: EndpointService.getSharedInstance().expandEndpointRefs(activeRef.held),
      recorderRtcIds: activeRef.recorderRtcIds,
      filesOffered: activeRef.filesOffered
    });
  }
  
  /**
   * get shared singleton object
   */
  static getSharedInstance(): IConferenceService {
    if (!this.sharedInstance) {
      this.sharedInstance = new ConferenceService();
      this.sharedInstance.endpointService = EndpointService.getSharedInstance();
    }
    return this.sharedInstance;
  }

  /**
   * default handler for new alerts created
   */
  static defaultAlertHandler(
    alertCode: AlertCode,
    alertText?: string,
    alertLevel?: AlertLevel,
    options?: any // unused
  ): void {
    const dateString = new Date().toISOString() + " ";
    switch (alertLevel) {
      case undefined:
      case AlertLevel.log:
      case AlertLevel.success:
      case AlertLevel.info:
        alertText ? console.log(dateString, alertCode, alertText) : console.log(dateString, alertCode);
        break;
      case AlertLevel.warning:
        alertText ? console.warn(dateString, alertCode, alertText) : console.warn(dateString, alertCode);
        break;
      case AlertLevel.error:
        alertText ? console.error(dateString, alertCode, alertText) : console.error(dateString, alertCode);
        break;
    }
    if (EasyRTCService.getSharedInstance().rtcClient.sendLogsToServer) {
      EasyRTCService.getSharedInstance().sendServerMessage("companion_web_log", alertCode);
    }
  }

  /**
   * sets new local active conference record.
   * @param conference: IConference - conference object to create
   */
  setActiveInfo(conference: IActiveConference): Readonly<ActiveConference> {
    let newConf = new ActiveConference(conference);
    this.conferences.set(conference.id, newConf);
    return newConf;
  
  }

  /**
   * create or update new local conference record
   * @param conference: IConference - conference object to create
   */
  setQueueInfo(queue: IQueueConference): Readonly<QueueConference> {
    let newConf = new QueueConference(queue);
    this.queues.set(queue.id, newConf);
    return newConf;
  }

  setMonitorInfo(monitor: IMonitorConference): Readonly<MonitorConference> {
    let newConf = new MonitorConference(monitor);
    this.monitors.set(monitor.id, newConf);
    return newConf;
  }

  /**
   * delete local conference record
   * @param id: string - id of the object to delete
   */
  deleteActive(id: string): boolean {
    if (!this.conferences.has(id)) {
      return false;
    }
    return this.conferences.delete(id);
  }

  /**
   * delete local queue record
   * @param id: string - id of the object to delete
   */
  deleteQueue(id: string): boolean {
    if (!this.queues.has(id)) {
      return false;
    }
    return this.queues.delete(id);
  }

  
  /**
   * find active conference by name
   * @param name: string - name of the conference
   */
  findActiveConferenceByName(name: string): Readonly<ActiveConference> {
    let matchedConference: ActiveConference = _.find([...this.conferences.values()], {name: name});
    return matchedConference;
  }

  /**
   * find queue conference by name
   * @param name: string - name of the conference
   */
  findQueueConferenceByName(name: string): Readonly<QueueConference> {
    let matchedConference: QueueConference = _.find([...this.queues.values()], {name: name});
    return matchedConference;
  }

  /**
   * find active conference by id
   * @param id: string - id of the conference
   */
  findActiveConferenceById(id: string): Readonly<ActiveConference> {
    return this.conferences.get(id);
  }

  findActiveConferencesByOwnerId(ownerId: string): Readonly<ActiveConference>[] {
    let matchedConference: ActiveConference[] = _.filter([...this.conferences.values()], {ownerId: ownerId});
    return matchedConference;
  }

  waitForEmptyOwnedConference(retries: number = 10): Promise<Readonly<ActiveConference>> {
    let emptyConf = this.findEmptyOwnedConference();
    if (!emptyConf && retries > 0) {
      retries -= 1;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(this.waitForEmptyOwnedConference(retries));
        }, 1000);
      });
    } else if (emptyConf) {
      return Promise.resolve(emptyConf);
    } else {
      return Promise.resolve(null);
    }
  }

  findEmptyOwnedConference(): Readonly<ActiveConference> {
    let ownedConfs: ActiveConference[] = this.findActiveConferencesByOwnerId(UserService.getSharedInstance().currentUser["_id"]);
    let matchedConference: ActiveConference = _.find([...ownedConfs], (conf: ActiveConference) => {return !conf.everyone?.length});
    return matchedConference;
  }

  countEmptyOwnedConferences(): number {
    let ownedConfs: ActiveConference[] = this.findActiveConferencesByOwnerId(UserService.getSharedInstance().currentUser["_id"]);
    let matchedConferences: ActiveConference[] = _.filter([...ownedConfs], (conf: ActiveConference) => {return !conf.everyone?.length});
    return matchedConferences.length;
  }

  countParticipatingConferences(): number {
    let matchedConferences: ActiveConference[] = _.filter([...this.conferences.values()], (conf: ActiveConference) => {
      return !!_.find(conf.everyone, (ep) => {return ep.rtcId == this.endpointService.myEndpoint.rtcId;});
    });
    return matchedConferences?.length || 0;
  }

  countMonitoringConferences(): number {
    let matchedConferences: MonitorConference[] = _.filter([...this.monitors.values()], (conf: MonitorConference) => {
      return !!_.find(conf.observers, (ep) => {return ep.rtcId == this.endpointService.myEndpoint.rtcId;});
    });
    return matchedConferences?.length || 0;
  }

  isRecording(): boolean {
    return this.activeRecording;
  }

  /**
   * find queue conference by id
   * @param id: string - id of the conference
   */
  findQueueConferenceById(id: string): Readonly<QueueConference> {
    return this.queues.get(id);
  }

  /**
   * find or create guest wait room
   */
  findOrCreateQueueConferenceByName(name: string): Readonly<QueueConference> {
    var conference: IConferenceBase = ConferenceUtil.newConferenceData(RoomType.GuestWaitRoom, {
      name: name
    });
    let queueConf = this.findQueueConferenceByName(name);
    if (!queueConf) {
      queueConf = new QueueConference(conference as IQueueConference);
    }

    return this.setQueueInfo(queueConf);
  }

  /**
   * find or create guest wait room
   */
  findOrCreateActiveConferenceByName(name: string): Readonly<ActiveConference> {
    var conference: IConferenceBase = ConferenceUtil.newConferenceData(RoomType.Conference, {
      name: name
    });
    let activeConf = this.findActiveConferenceByName(name);
    if (!activeConf) {
      activeConf = new ActiveConference(conference as IActiveConference);
    }

    return this.setActiveInfo(activeConf);
  }

  /**
   * find room type by room name
   * @param name: string - the room name
   */
  findRoomTypeByName(name: string): RoomType {
    if (!name) {
      return;
    }

    if (name === ConferenceUtil.defaultRoomName) {
      return RoomType.Default;
    } else if (name.startsWith(ConferenceUtil.GuestWaitRoomPrefix)) {
      return RoomType.GuestWaitRoom;
    } else if (name.startsWith(ConferenceUtil.OpChatRoomPrefix)) {
      return RoomType.OpChatRoom;
    } else if (name.startsWith(ConferenceUtil.OpWaitRoomPrefix)) {
      return RoomType.OpWaitRoom;
    } else if (name.startsWith(ConferenceUtil.SpChatRoomPrefix)) {
      return RoomType.SpChatRoom;
    } else if (name.startsWith(ConferenceUtil.SpWaitRoomPrefix)) {
      return RoomType.SpWaitRoom;
    } else if (name.startsWith(ConferenceUtil.conferenceRoomPrefix)) {
      return RoomType.Conference;
    } else {
      return null;
    }
  }

  /**
   * init conference
   * @param connectSuccessHandler:(rtcid: string) => void - connect success handler
   */
  init(
    rtcId: string,
    alertHandler?: AlertHandlerCallback,
    localVideoUpdateHandler?: () => void,
    remoteVideoUpdateHandler?: () => void,
    sharedFilesUpdateHandler?: (files: ISharedFile[]) => void,
    messageReceivedHandler?: (chatRoom: IChatRoom, message: IMessage) => void,
    audioCodecList?: string[],
    videoCodecList?: string[],
    secondaryVideoCodecList?: string[],
    peerConnectionCreatedHandler?: (rtcId: string, pc: any) => void,
    serverDisconnectedTimeoutHandler?: () => void
  ) {
    this.endpointService.myEndpoint.rtcId = rtcId;
    
    // Only send errors and warnings to provided alert handler.
    // Also send all alerts to our default alert handler.
    // TBH, this seems a bit contract breaking.
    if (alertHandler) {
      this.alertHandler = (
        alertCode: AlertCode,
        alertText?: string,
        alertLevel?: AlertLevel,
        options?: AlertHandlerCallbackOptions
      ) => {
        if (alertLevel === AlertLevel.error || alertLevel === AlertLevel.warning || alertLevel === AlertLevel.prominent) {
          alertHandler(alertCode, alertText, alertLevel, options);
        }
        ConferenceService.defaultAlertHandler(alertCode, alertText, alertLevel, options);
      };
    }

    if (localVideoUpdateHandler) {
      this.localVideoUpdateHandler = localVideoUpdateHandler;
    }
    if (remoteVideoUpdateHandler) {
      this.remoteVideoUpdateHandler = remoteVideoUpdateHandler;
    }
    if (sharedFilesUpdateHandler) {
      this.sharedFilesUpdateHandler = sharedFilesUpdateHandler;
    }
    if (messageReceivedHandler) {
      this.messageReceivedHandler = messageReceivedHandler;
    }
    if (peerConnectionCreatedHandler) {
      this.peerConnectionCreatedHandler = peerConnectionCreatedHandler;
    }

    this.connectionEventHandler = new RTCServiceConnectionEventHandler();
    if (serverDisconnectedTimeoutHandler) {
      this.connectionEventHandler.setServerDisconnectedTimeoutNotify(serverDisconnectedTimeoutHandler);
    }
    this.fileEventHandler = new RTCServiceFileEventHandler();
    this.serverMessageHandler = new RTCServiceServerMessageHandler();
    this.streamEventHandler = new RTCServiceStreamEventHandler();
    EasyRTCService.getSharedInstance().init(
      rtcId,
      this.connectionEventHandler,
      this.fileEventHandler,
      this.serverMessageHandler,
      this.streamEventHandler,
      this.alertHandler,
      audioCodecList,
      videoCodecList,
      secondaryVideoCodecList
    );

    // Initialize the webrtc issue detector
    this.connectionIssueDetector = new WebRTCIssueDetector({
      onIssues: (issues: IssueDetectorResult) => {
        let clearedThisRound = [];
        issues.map((issue: IssuePayload) => {
          console.log(JSON.stringify(issue));
          let candidateId = issue.iceCandidate;
          let ep = _.find(this.currentActiveConference?.active, (ep) => {
            let foundSender = !!_.find(ep?.lastStats?.senders, (sender) => {
              return sender.localCandidateId == candidateId;
            })
            if (!foundSender) {
              let foundReceiver = !!_.find(ep?.lastStats?.receivers, (receiver) => {
                return receiver.remoteCandidateId == candidateId;
              })
              return foundReceiver;
            } else {
              return foundSender;
            }
          })

          if (ep) {
            let qualityData = ep?.callQuality;
            // Clear current active conference issues so we can re-load them
            if (!qualityData) {
              qualityData = {issues: []};
            } else if (!qualityData?.issues) {
              qualityData.issues = [];
            }
            else if (!!qualityData?.issues && !clearedThisRound.includes(ep.rtcId)) {
                qualityData.issues = [];
                clearedThisRound.push(ep.rtcId);
            }
            // log the issue with the enpoint. Set a timer to clear it after 10s so they don't hang around.
            let timeout = this.connectionIssueClears.get(ep.rtcId);
            if (timeout) clearTimeout(timeout);
            
            this.connectionIssueClears.set(ep.rtcId, setTimeout(() => {
              qualityData.issues = [];
              this.connectionIssueClears.delete(ep.rtcId);
            }, 10000));
            qualityData.issues.push(issue);
            ep.callQuality = qualityData; // Update ep sub-object for UI update
          }
        })
      },
      onNetworkScoresUpdated: (scores: NetworkScores) => {
        let connId = scores.connectionId;
        let ep = _.find(this.currentActiveConference?.active, (ep) => {
          let foundSender = !!_.find(ep?.lastStats?.senders, (sender) => {
            return sender.selectedCandidatePairId == connId;
          })
          if (!foundSender) {
            let foundReceiver = !!_.find(ep?.lastStats?.receivers, (receiver) => {
              return receiver.selectedCandidatePairId == connId;
            })
            return foundReceiver;
          } else {
            return foundSender;
          }
        })

        if (ep) {
          let qualityData = ep?.callQuality;
          // Ensure we have a place to stash our scores.
          if (!qualityData) {
              qualityData = {mos: []};
          } else if (!qualityData?.mos) {
            qualityData.mos = [];
          }

          // Remove previous matching if present.
          _.remove(qualityData.mos, (scores: NetworkScores) => {
            return scores.connectionId == connId;
          })
           // log the MOS score with the enpoint. Set a timer to clear it after 10s so they don't hang around.
           let timeout = this.connectionMosClears.get(connId);
           if (timeout) clearTimeout(timeout);
           
           this.connectionMosClears.set(connId, setTimeout(() => {
             qualityData.mos = [];
             this.connectionMosClears.delete(connId);
           }, 10000));
          // log the score with the enpoint.
          qualityData.mos.push(scores);
          ep.callQuality = qualityData; // Update ep sub-object for UI update
        }
      }
    });

    // start collecting getStats() and detecting issues
    this.connectionIssueDetector.watchNewPeerConnections();

    this.updateConferenceStatsLoop(); // Start stats collection for active calls.
  }

  /**
   * set server message code handler
   */
  setServerMessageCodeHandler(messageCode: string, handler: (msgData: any) => void): void {
    this.serverMessageHandler.setServerMessageCodeHandler(messageCode, handler);
  }

  /**
   * handler for new alerts created
   */
  alertHandler(
    alertCode: AlertCode,
    alertText?: string,
    alertLevel?: AlertLevel,
    options?: AlertHandlerCallbackOptions
  ): void {
    ConferenceService.defaultAlertHandler(alertCode, alertText, alertLevel, options);
  }

  /**
   * set alert handler
   */
  setAlertHandler(handler: AlertHandlerCallback): void {
    this.alertHandler = handler;
  }

  /**
   * handler for local video update
   */
  localVideoUpdateHandler(): void {
    this.alertHandler(AlertCode.localVideoUpdate);
  }

  /**
   * set local video update handler
   */
  setLocalVideoUpdateHandler(handler: () => void): void {
    this.localVideoUpdateHandler = handler;
  }

  /**
   * handler for remote videos update
   */
  private remoteVideoUpdateHandler(): void {
    // nothing needed here
  }

  /**
   * set remote video update handler
   */
  setRemoteVideoUpdateHandler(handler: () => void): void {
    this.remoteVideoUpdateHandler = handler;
  }

  /**
   * emit remote video update event
   */
  emitRemoteVideoUpdateEvent(): void {
    if(!this.currentActiveConference && this.monitors.size < 1)
    {
      console.log("video update without active videos");
      return;
    }
    this.remoteVideoUpdateHandler();
  }

  /**
   * handler for pc created event
   */
  public peerConnectionCreatedHandler(rtcId: string, pc: any): void {
    console.log("Peer conn created listener not set");
  }

  /**
   * Emit peer connection created event.
   * Currently the only thing we use this for is watchRTC analytics start...
   */
  emitPeerConnectionCreatedEvent(rtcId: string, pc: any) {
    this.peerConnectionCreatedHandler(rtcId, pc);
  }

  /**
   * handler for shared files updated
   */
  sharedFilesUpdateHandler(files: ISharedFileRef[]): void {
    // nothin
  }

  /**
   * set shared files update handler
   */
  setSharedFilesUpdateHandler(handler: (files: ISharedFileRef[]) => void): void {
    this.sharedFilesUpdateHandler = handler;
  }

  /**
   * handler for message received
   */
  messageReceivedHandler(chatRoom: IChatRoom, message: IMessage): void {
    this.alertHandler(AlertCode.messageReceived, message.content);
  }

  /**
   * set message received handler
   */
  setMessageReceivedHandler(handler: (chatRoom: IChatRoom, message: IMessage) => void): void {
    this.messageReceivedHandler = handler;
  }

  /**
   * set listener on room occupent changes
   */
  setRoomOccupantHandler(roomOccupantHandler: (roomName: string, otherPeople: string[]) => void): void {
    EasyRTCService.getSharedInstance().setRoomOccupantListener(roomOccupantHandler);
  }s

  addSharedFile(file: ISharedFile) {
    // Store the file in the local storage for later offering to conferences.
    this.sharedFiles.set(file.id, file);
  }

  getSharedFile(id: string) : ISharedFile {
    return this.sharedFiles.get(id);
  }

  removeSharedFile(id: string) : void {
    this.sharedFiles.delete(id);
  }

  setFileCompleteNotify(callback: (file: ISharedFile) => void): void {
    this.notifyFileCompleteFn = callback;
  }

  notifyFileComplete(file: ISharedFile): void {
    if (this.notifyFileCompleteFn) {
      this.notifyFileCompleteFn(file);
    }
  }

  handleFileTransferRequest(fileId: string, requestorId: string): void {
    // Do I have it?
    let file = this.sharedFiles.get(fileId);
    if (file) {
      // FULL SEND of file blob (should be monkey-patched with UUID so we can identify it later)
      EasyRTCService.getSharedInstance().sendFiles(requestorId, [file.fileBlob]);
    }
  }

  /**
   * Decrypt encrypted parameters on the server for security reasons...
   * @param encryptedString
   * @returns
   */
  decryptEncryptedParameters(encryptedString: string): Promise<any> {
    // send this off via RTC
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      EasyRTCService.getSharedInstance().sendServerMessage("decrypt_parameters", {
        encryptedString: encryptedString
      },
      (msgType: string, msgData: any) => {
        resolve(msgData);
      },
      (errorCode: string, errorText: string) => {
        console.error("DECRYPT PROBLEM!", errorText);
        reject(new Error(errorText));
      });
    });
  }

  /**
   * create conference state machine for a conference
   */
  public createStateMachineForConference(conf : ActiveConference|MonitorConference)
  {
    let sm : ActiveConferenceSm = new ActiveConferenceSm(conf.id);
    this.conferenceSmMap.set(conf.id, sm);
  }

  /**
   * Create peerSM for peer
   * @param peerID the peer id for the SM.
   * @param confID the confId for the peer connection (can be null if the
   * connection is not associated yet).
   * @param isVoicePeer if the peer is to be connected over voice
   */
  public createPeerStateMachine(peerId : string, confId : string, isVoicePeer : boolean)
  {
    let sm : PeerConnectionSm = new PeerConnectionSm(peerId, confId, isVoicePeer);
    this.peerSmMap.set(peerId, sm);
  }


  /**
   * Get the first active conference we find that an endpoint is in 
   * (in the .everyone list of a conference)
   * returns null if the endpoint is not in any conference
   */
  public getConferenceFromEndpoint(ep : IEndpointRef) : IActiveConference
  {
    
    let foundConf = _.find([...this.conferences.values()], (conf : IActiveConference) =>
    {
      return _.some((conf.everyone), (epToCheck : IEndpointRef) => { return epToCheck.rtcId === ep.rtcId});
    });

    return foundConf;
  }

  /**
   * Get the first monitor conference we find that an endpoint is in 
   * (in the .everyone list of a conference)
   * returns null if the endpoint is not in any conference
   */
  public getMonitorFromEndpoint(ep : IEndpointRef) : IMonitorConference
  {
    
    let foundConf = _.find([...this.monitors.values()], (conf : IMonitorConference) =>
    {
      return _.some((conf.everyone), (epToCheck : IEndpointRef) => { return epToCheck.rtcId === ep.rtcId});
    });

    return foundConf;
  }

  /**
   * Fetch a conference Update
   */
  public fetchConferenceUpdate() : Promise<IConferenceUpdate>
  {
      // send this off via RTC
      return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
        EasyRTCService.getSharedInstance().sendServerMessage("fetch_conference_update", {},
        (msgType: string, msgData: any) => {
          resolve(msgData as IConferenceUpdate);
        },
        (errorCode: string, errorText: string) => {
          console.error("Fetch conference update failure!", errorText);
          reject(new Error(errorText));
        });
      });
  }

  /**
   * update conference stats
   */
  public updateConferenceStatsLoop() {
    if (this.statsTimeout) {
      clearTimeout(this.statsTimeout);
    }

    // Iterate all active conferences and find all active endpoints
    _.forEach([...this.conferences.values()], (activeConf: IActiveConference) => {
      _.forEach([...activeConf?.active], (epRef: IEndpointRef) => {
        // Update their stats. (Skip myself)
        if (epRef.rtcId != this.endpointService.myEndpoint.rtcId) {
          EasyRTCService.getSharedInstance().getPeerStatistics(epRef.rtcId, (rtcId: string, stats: IPCStats) => {
            EndpointService.getSharedInstance().updateStatsById(rtcId, stats);
          })
        }
      });
    });

    this.statsTimeout = setTimeout(() => {
      this.updateConferenceStatsLoop();
    }, 3 * 1000);
  }

  reset() {
    this.endpointService.myEndpoint.rtcId = "";
    this.localVideoUpdateHandler = null;
    this.remoteVideoUpdateHandler = null;
    this.sharedFilesUpdateHandler = null;
    this.messageReceivedHandler = null;
    this.notifyFileCompleteFn = null;
    this.peerConnectionCreatedHandler = null;
    this.connectionEventHandler.resetServerDisconnectedTimeoutNotify();
    delete this.connectionEventHandler;
    this.connectionEventHandler = null;
    delete this.fileEventHandler;
    this.fileEventHandler = null;
    delete this.serverMessageHandler;
    this.serverMessageHandler = null;
    delete this.streamEventHandler;
    this.streamEventHandler = null;
    delete this.connectionIssueDetector;
    this.connectionIssueDetector = null;
    this.conferences.clear();
    this.activeConference = null
    this.sharedFiles.clear();
    this.alertHandler = (
      alertCode: AlertCode,
      alertText?: string,
      alertLevel?: AlertLevel,
      options?: AlertHandlerCallbackOptions
      ) => {
      };
    EasyRTCService.getSharedInstance().reset();
  }


  updateMonitorStreamsToOthers() {
    _.forEach([...this.monitors.values()], (conf) => {
      // Notify everyone if we updated our stream on our end.
      EasyRTCService.getSharedInstance().updateLocalStreamToOthers(_.filter(_.map(conf.observers, (epRef) => {return epRef.rtcId}), (rtcId) => {return rtcId != this.endpointService.myEndpoint.rtcId})).catch((error: any) => {
        // Should be caught in updateLocalStreamToOthers
      });
    })
  }

  
  getActiveVideoEndpoints() : IEndpointRef[] {
    let result: IEndpointRef[] = [];
    let myId = this.endpointService.myEndpoint.rtcId;

    // Check if I have an active conference.
    if (this.currentActiveConference) {
      // Add all the non-me actives
      result = result.concat(
        _.map(_.filter(this.currentActiveConference.active, (ep: IEndpoint) => {
          return ep.rtcId !== myId;
        }), (ep) => {
          return Endpoint.toRef(ep);
        })
      );
    }

    // Look inside each monitor conference I have.
    _.forEach([...this.monitors.values()], (monitorConf) => {
      // Don't add observers if I'm a subject... This means I should not change anything on my screen.
      if (_.some(monitorConf.subjects, (subjRef) => {
        return subjRef.rtcId == myId;
      })) { // If I'm in the subjects list... add observers (for audio, gets hidden in the UI)
        result = result.concat(monitorConf.observers);
      } else if (_.some(monitorConf.observers, (obsRef) => {
        return obsRef.rtcId == myId;
      })) { // If I'm in the observers list...
        // Add all the subjects and the observers that are not me.
        result = result.concat(
          monitorConf.subjects,
          _.filter(monitorConf.observers, (obsRef) => {
            return obsRef.rtcId != myId;
          })
        );
      }
    });
    
    return _.compact(result);
  }
}
