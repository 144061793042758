/**
 * Copyright Compunetix Incorporated 2018-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender, jporter
 */
import { FlavorParameterKey, IFlavor, Languages } from "./flavor.interface";
import { ILocalization } from "./localization.interface";
/**
 * Flavor util
 */
export class FlavorUtil {
  /**
   * map from flavor to localization
   */
  public static flavorToLocalization: {
    [flavorKey: string]: string;
  };

  /**
   * map from localization to flavor
   */
  public static localizationToFlavor: {
    [localizationPath: string]: string;
  };

  /**
   * panel to toolbar toggle
   */
  public static panelToToolbarToggle: {
    [panelId: string]: string;
  };

  private static defaulLocalizationValues: ILocalization = {
    language: "en",
    mode: "call_center",
    page: {
      loadSimplifiedViewForMobileUsers: true
    },
    topnav: {
      language_selector: {
        options: {
          en: Languages.en
        },
        default: "en"
      }
    },
    loginPanel: {
      title: "Sign In",
      exitButtonText: "Exit",
      passwordFieldTitle: "Password",
      usernameFieldTitle: "Name",
      submitButtonText: "SIGN IN",
      loginText: "Log in",
      logoutText: "Log out"
    },
    reporting: {},
    toolbar: {
      leftTitle: "VCC",
      logoAriaLabel: "by",
      rightTitle: "COMPUNETIX",
      toolbar_items: {
        settings: { text: "Settings" },
        enter: { text: "Enter", altText: "Leave" },
        audio: {
          text: "Audio On",
          altText: "Audio Off"
        },
        video: {
          text: "Video On",
          altText: "Video Off"
        },
      }
    },    
    loopback_panel: {},
    video_screen: {
      pipPositionFixed: false
    },
    settings_panel: {
      title: "Settings",
      relayCandidateOnly: false,
      disableAudioOnly: false,
      audio_setting: {
        options: {
          on: {
            text: "Audio On",
            value: true
          },
          off: {
            text: "Audio Off",
            value: false
          }
        }
      },
      video_setting: {
        options: {
          on: {
            text: "Video On",
            value: true
          },
          off: {
            text: "Video Off",
            value: false
          }
        }
      },
      showCustomizedVideoMutedPlaceholder: false,
      avatarImageSrc: "/images/video-mute.png",
      virtualBackgroundMethod: "None",
      microphone_setting: {
        startTestingText: "Start Testing",
        endTestingText: "End Testing"
      },
      speaker_setting: {
        startTestingText: "Start Testing",
        endTestingText: "End Testing"
      },
      troubleshootText: "Troubleshoot",
      camera_rotation: "Camera Rotation",
      resolution_setting: {
        title: "Resolution",
        autoTitle: "Auto",
        options: [
          {
            text: "1920 x 1080",
            value: {
              width: 1920,
              height: 1080
            }
          },
          {
            text: "1280 x 720",
            value: {
              width: 1280,
              height: 720
            }
          },
          {
            text: "704 x 576",
            value: {
              width: 704,
              height: 576
            }
          },
          {
            text: "768 x 448",
            value: {
              width: 768,
              height: 448
            }
          },
          {
            text: "512 x 288",
            value: {
              width: 512,
              height: 288
            }
          },
          {
            text: "352 x 288",
            value: {
              width: 352,
              height: 288
            }
          }
        ],
        defaultPCValue: {
          width: 1280,
          height: 720
        },
        defaultMobileValue: {
          width: 704,
          height: 576
        },
        defaultPCSecondaryValue: {
          width: 1280,
          height: 720
        },
        defaultMobileSecondaryValue: {
          width: 704,
          height: 576
        }
      },
      presentation_setting: {
        sharpness_first: {
          resolution: {
            width: 1920,
            height: 1080
          },
          framerate: 3
        },
        motion_first: {
          resolution: {
            width: 1280,
            height: 720
          },
          framerate: 30
        }
      },
      bandwidth_setting: {
        title: "Bandwidth",
        autoTitle: "Auto",
        options: [
          {
            text: "4096",
            value: 4096
          },
          {
            text: "3072",
            value: 3072
          },
          {
            text: "2560",
            value: 2560
          },
          {
            text: "2048",
            value: 2048
          },
          {
            text: "1536",
            value: 1536
          },
          {
            text: "1024",
            value: 1024
          },
          {
            text: "768",
            value: 768
          },
          {
            text: "512",
            value: 512
          },
          {
            text: "384",
            value: 384
          },
          {
            text: "256",
            value: 256
          },
          {
            text: "128",
            value: 128
          }
        ],
        defaultPCValue: 1024,
        defaultMobileValue: 384
      },
      runTesterOnFirstVisit: false,
    },
    participant_panel: {
      maxParticipantsToConnect: 2,
      listTypes: {
        publicWait : "Queue",
        op : "Agents",
        sp : "Administrative"
      },
      sortByEnterTime: true,
    },
    presenceStatus: {
      offline: "Offline",
      online: "Online",
      available: "Available",
      alone_in_conf: "Alone/Wait",
      busy: "Connected",
      invisible: "Invisible",
      away: "Away",
      work: "Work",
      connecting: "Connecting",
      ringing: "Ringing",
      onhold: "On Hold",
      ready: "Ready",
      readyTimelapse: 10,
      custom1: "",
      custom2: "",
      custom3: "",
      custom4: ""
    },
    sharedFolder_panel: {
      idle: "Waiting to Transmit",
      started: "Start Transmitting",
      transmitting: "Transmitting...",
      finished: "Successfully Transmitted",
      failed: "Failed. Please try again.",
    },
    record_panel: {
      audioModeText: "both",
      videoModeText: "both",
    },
    footer: {
      copyright: "2016-[CURRENT_YEAR] Compunetix. All Rights Reserved",
    },
    errorMessages: {
      ADD_TO_CONFERENCE_FAIL:"Failed to join conference.",
      BROWSER_NOT_SUPPORT:"The browser [CURRENT_BROWSER] you are using does not support audiovisual communications. Please use [SUPPORTED_BROWSERS] for a better experience.",
      CALLBACK_FAILED:"Failed to request callback.",
      CALL_DROPPED_NOTIFICATION_TITLE:"Attention!",
      CALL_DROPPED_NOTIFICATION_MESSAGE:"Your call has dropped.",
      CALL_DROPPED_NOTIFICATION_CONFIRM:"Continue",
      CANCEL:"Cancel",
      CONFIRM_TO_INSTALL_BROWSER:"Proceed to install a compatible browser.",
      EMAIL_INVALID:"Invalid email address.",
      FAIL:"Fail",
      FAIL_TO_GAIN_MEDIA_ACCESS:"Failed to gain media access. Please check your device settings.",
      GEO_ACCESS_NOT_PERMIT:"Geolocation was not permitted.",
      GUEST_OFFLINE_MESSAGE: "Your session has finished.\nPlease close or refresh the page.",
      INVALID_RECORDING_OPTIONS: "Not starting recording with no audio or video!",
      LEAVE_WARNING: "Are you sure you want to leave?",
      LOGIN_FAILED: "Login Failed.",
      LOST_SERVER_CONNECTION: "Lost connection to signaling server.",
      MEDIA_CONNECTION_LOST:"Your media connection has been lost.",
      NO_LOCATION:"No location was detected.",
      PASSWORD_REQUIRED:"Password Required",
      SUCCESS:"Success",
      TIMEOUT_ACKNOWLEDGED:"You have been disconnected due to server timeout.",
      USERNAME_REQUIRED:"Username Required",
      WAIT: "Please wait...",
      VIDEO_MUTED_PLACEHOLDER_IMAGE_LIMIT: "The Video Muted PlaceHolder file size is limited to 1 MB. Please upload a smaller file.",
      VIRTUAL_BACKGROUND_IMAGE_LIMIT: "The Virtual Background file size is limited to 1 MB. Please upload a smaller file."
    }
  };
  
  public static defaultLocalizationOverridesDe: Partial<ILocalization> = {
    language: "de",
    topnav: {
      language_selector: {
        options: {
          en: Languages.en,
          de: Languages.de
        },
        default: "de"
      }
    },
    loginPanel: {
      title: "Anmelden",
      exitButtonText: "Ausfahrt",
      passwordFieldTitle: "Passwort",
      usernameFieldTitle: "Name",
      submitButtonText: "ANMELDEN",
      loginText: "Anmelden",
      logoutText: "Abmelden"
    },
    toolbar: {
      leftTitle: "VCC",
      logoAriaLabel: "von",
      rightTitle: "COMPUNETIX",
      toolbar_items: {
        settings: { text: "Einstellungen" },
        enter: { text: "Eintreten", altText: "Verlassen" },
        audio: {
          text: "Audio An",
          altText: "Audio aus"
        },
        video: {
          text: "Video An",
          altText: "Video aus"
        },
      }
    },
    settings_panel: {
      title: "Einstellungen",
      audio_setting: {
        options: {
          on: {
            text: "Audio An",
          },
          off: {
            text: "Audio aus",
          }
        }
      },
      video_setting: {
        options: {
          on: {
            text: "Video An",
          },
          off: {
            text: "Video aus",
          }
        }
      },
      microphone_setting: {
        startTestingText: "Test starten",
        endTestingText: "Test beenden"
      },
      speaker_setting: {
        startTestingText: "Test starten",
        endTestingText: "Test beenden"
      },
      troubleshootText: "Fehlerbehebung",
      camera_rotation: "Kamerarotation",
      resolution_setting: {
        title: "Auflösung",
        autoTitle: "Automatisch",
      },
      bandwidth_setting: {
        title: "Bandbreite",
        autoTitle: "Automatisch",
      },
    },
    participant_panel: {
      listTypes: {
        publicWait : "Warteschlange",
        op : "Agenten",
        sp : "Verwaltung"
      },
    },
    presenceStatus: {
      offline: "Offline",
      online: "Online",
      available: "Verfügbar",
      alone_in_conf: "Allein/Warten",
      busy: "Verbunden",
      invisible: "Unsichtbar",
      away: "Abwesend",
      work: "Arbeit",
      connecting: "Verbindungsaufbau",
      ringing: "Klingelt",
      onhold: "In der Warteschleife",
      ready: "Bereit",
    },
    sharedFolder_panel: {
      idle: "Warten auf Übertragung",
      started: "Übertragung starten",
      transmitting: "Übertragung...",
      finished: "Erfolgreich übertragen",
      failed: "Fehlgeschlagen. Bitte versuchen Sie es erneut.",
    },
    footer: {
      copyright: "2016-[CURRENT_YEAR] Compunetix. Alle Rechte vorbehalten",
    },
    errorMessages: {
      ADD_TO_CONFERENCE_FAIL:"Beitritt zur Konferenz fehlgeschlagen.",
      BROWSER_NOT_SUPPORT:"Der von Ihnen verwendete Browser [CURRENT_BROWSER] unterstützt keine audiovisuelle Kommunikation. Bitte verwenden Sie [SUPPORTED_BROWSERS] für ein besseres Erlebnis.",
      CALLBACK_FAILED:"Rückrufanforderung fehlgeschlagen.",
      CALL_DROPPED_NOTIFICATION_TITLE:"Achtung!",
      CALL_DROPPED_NOTIFICATION_MESSAGE:"Ihr Anruf wurde unterbrochen.",
      CALL_DROPPED_NOTIFICATION_CONFIRM:"Fortfahren",
      CANCEL:"Abbrechen",
      CONFIRM_TO_INSTALL_BROWSER:"Fahren Sie mit der Installation eines kompatiblen Browsers fort.",
      EMAIL_INVALID:"Ungültige E-Mail-Adresse.",
      FAIL:"Fehlgeschlagen",
      FAIL_TO_GAIN_MEDIA_ACCESS:"Medienzugriff konnte nicht erlangt werden. Bitte überprüfen Sie Ihre Geräteeinstellungen.",
      GEO_ACCESS_NOT_PERMIT:"Geolokalisierung war nicht zulässig.",
      GUEST_OFFLINE_MESSAGE: "Ihre Sitzung ist beendet.\nBitte schließen oder aktualisieren Sie die Seite.",
      LEAVE_WARNING: "Möchten Sie die Seite wirklich verlassen?",
      LOGIN_FAILED: "Anmeldung fehlgeschlagen.",
      LOST_SERVER_CONNECTION: "Verbindung zum Signalserver verloren.",
      MEDIA_CONNECTION_LOST:"Ihre Medienverbindung wurde unterbrochen.",
      NO_LOCATION:"Es wurde kein Standort erkannt.",
      PASSWORD_REQUIRED:"Passwort erforderlich",
      SUCCESS:"Erfolgreich",
      TIMEOUT_ACKNOWLEDGED:"Ihre Verbindung wurde aufgrund eines Server-Timeouts getrennt.",
      USERNAME_REQUIRED:"Benutzername erforderlich",
      WAIT: "Bitte warten..."
    }
  }
  
  public static defaultLocalizationOverridesEs: Partial<ILocalization> = {
    language: "es",
    topnav: {
      language_selector: {
        options: {
          en: Languages.en,
          es: Languages.es
        },
        default: "es"
      }
    },
    loginPanel: {
      title: "Iniciar sesión",
      exitButtonText: "Salir",
      passwordFieldTitle: "Contraseña",
      usernameFieldTitle: "Nombre",
      submitButtonText: "INICIAR SESIÓN",
      loginText: "Iniciar sesión",
      logoutText: "Cerrar sesión"
    },
    toolbar: {
      leftTitle: "VCC",
      logoAriaLabel: "de",
      rightTitle: "COMPUNETIX",
      toolbar_items: {
        settings: { text: "Configuración" },
        enter: { text: "Ingresar", altText: "Salir" },
        audio: {
          text: "Audio activado",
          altText: "Audio desactivado"
        },
        video: {
          text: "Video activado",
          altText: "Video desactivado"
        },
      }
    },
    settings_panel: {
      title: "Configuración",
      audio_setting: {
        options: {
          on: {
            text: "Audio activado",
          },
          off: {
            text: "Audio desactivado",
          }
        }
      },
      video_setting: {
        options: {
          on: {
            text: "Video activado",
          },
          off: {
            text: "Video desactivado",
          }
        }
      },
      microphone_setting: {
        startTestingText: "Iniciar prueba",
        endTestingText: "Finalizar prueba"
      },
      speaker_setting: {
        startTestingText: "Iniciar prueba",
        endTestingText: "Finalizar prueba"
      },
      troubleshootText: "Solucionar problemas",
      camera_rotation: "Rotación de la cámara",
      resolution_setting: {
        title: "Resolución",
        autoTitle: "Automático",
      },
      bandwidth_setting: {
        title: "Ancho de banda",
        autoTitle: "Automático",
      },
    },
    participant_panel: {
      listTypes: {
        publicWait : "Cola",
        op : "Agentes",
        sp : "Administrativo"
      },
    },
    presenceStatus: {
      offline: "Sin conexión",
      online: "En línea",
      available: "Disponible",
      alone_in_conf: "Solo/Esperar",
      busy: "Conectado",
      invisible: "Invisible",
      away: "Ausente",
      work: "Trabajando",
      connecting: "Conectando",
      ringing: "Llamando",
      onhold: "En espera",
      ready: "Listo",
    },
    sharedFolder_panel: {
      idle: "Esperando para transmitir",
      started: "Iniciar transmisión",
      transmitting: "Transmitiendo...",
      finished: "Transmitido correctamente",
      failed: "Error. Inténtelo de nuevo.",
    },
    footer: {
      copyright: "2016-[CURRENT_YEAR] Compunetix. Todos Derechos reservados",
    },
    errorMessages: {
      ADD_TO_CONFERENCE_FAIL:"No se pudo unir a la conferencia.",
      BROWSER_NOT_SUPPORT:"El navegador [CURRENT_BROWSER] que está utilizando no admite comunicaciones audiovisuales. Utilice [SUPPORTED_BROWSERS] para disfrutar de una mejor experiencia.",
      CALLBACK_FAILED:"No se pudo solicitar la devolución de llamada.",
      CALL_DROPPED_NOTIFICATION_TITLE:"¡Atención!",
      CALL_DROPPED_NOTIFICATION_MESSAGE:"Su llamada se ha interrumpido.",
      CALL_DROPPED_NOTIFICATION_CONFIRM:"Continuar",
      CANCEL:"Cancelar",
      CONFIRM_TO_INSTALL_BROWSER:"Continúe con la instalación de un navegador compatible.",
      EMAIL_INVALID:"Dirección de correo electrónico no válida.",
      FAIL:"Error",
      FAIL_TO_GAIN_MEDIA_ACCESS:"No se pudo obtener acceso a los medios. Por favor, verifique la configuración de su dispositivo.",
      GEO_ACCESS_NOT_PERMIT:"No se permitió la geolocalización.",
      GUEST_OFFLINE_MESSAGE: "Su sesión ha finalizado.\nPor favor, cierre o actualice la página.",
      LEAVE_WARNING: "¿Está seguro de que desea salir?",
      LOGIN_FAILED: "Error al iniciar sesión.",
      LOST_SERVER_CONNECTION: "Se perdió la conexión con el servidor de señalización.",
      MEDIA_CONNECTION_LOST:"Se perdió su conexión multimedia.",
      NO_LOCATION:"No se detectó ninguna ubicación.",
      PASSWORD_REQUIRED:"Se requiere contraseña",
      SUCCESS:"Éxito",
      TIMEOUT_ACKNOWLEDGED:"Se ha desconectado debido al tiempo de espera del servidor.",
      USERNAME_REQUIRED:"Se requiere nombre de usuario",
      WAIT: "Por favor, espere..."
    }
  }
  
  public static defaultLocalizationOverridesFr: Partial<ILocalization> = {
    language: "fr",
    topnav: {
      language_selector: {
        options: {
          en: Languages.en,
          fr: Languages.fr
        },
        default: "fr"
      }
    },
    loginPanel: {
      title: "Se connecter",
      exitButtonText: "Quitter",
      passwordFieldTitle: "Mot de passe",
      usernameFieldTitle: "Nom",
      submitButtonText: "SE CONNECTER",
      loginText: "Se connecter",
      logoutText: "Se déconnecter"
    },
    toolbar: {
      leftTitle: "VCC",
      logoAriaLabel: "par",
      rightTitle: "COMPUNETIX",
      toolbar_items: {
        settings: { text: "Paramètres" },
        enter: { text: "Entrer", altText: "Quitter" },
        audio: {
          text: "Audio activé",
          altText: "Audio désactivé"
        },
        video: {
          text: "Vidéo activée",
          altText: "Vidéo désactivée"
        },
      }
    },
    settings_panel: {
      title: "Paramètres",
      audio_setting: {
        options: {
          on: {
            text: "Audio activé",
          },
          off: {
            text: "Audio désactivé",
          }
        }
      },
      video_setting: {
        options: {
          on: {
            text: "Vidéo activée",
          },
          off: {
            text: "Vidéo désactivée",
          }
        }
      },
      microphone_setting: {
        startTestingText: "Démarrer les tests",
        endTestingText: "Terminer les tests"
      },
      speaker_setting: {
        startTestingText: "Démarrer les tests",
        endTestingText: "Terminer les tests"
      },
      troubleshootText: "Dépannage",
      camera_rotation: "Rotation de la caméra",
      resolution_setting: {
        title: "Résolution",
        autoTitle: "automatique",
      },
      bandwidth_setting: {
        title: "Bande passante",
        autoTitle: "automatique",
      },
    },
    participant_panel: {
      listTypes: {
        publicWait : "File d'attente",
        op : "Agents",
        sp : "Administratif"
      },
    },
    presenceStatus: {
      offline: "Hors ligne",
      online: "En ligne",
      available: "Disponible",
      alone_in_conf: "Seul/Attendre",
      busy: "Connecté",
      invisible: "Invisible",
      away: "Absent",
      work: "Travail",
      connecting: "Connexion",
      ringing: "Sonnerie",
      onhold: "En attente",
      ready: "Prêt",
    },
    sharedFolder_panel: {
      idle: "En attente de transmission",
      started: "Démarrer la transmission",
      transmitting: "Transmission en cours...",
      finished: "Transmission réussie",
      failed: "Échec. Veuillez réessayer.",
    },
    footer: {
      copyright: "2016-[CURRENT_YEAR] Compunetix. Tous droits réservés",
    },
    errorMessages: {
      ADD_TO_CONFERENCE_FAIL:"Échec de la participation à la conférence.",
      BROWSER_NOT_SUPPORT:"Le navigateur [CURRENT_BROWSER] que vous utilisez ne prend pas en charge les communications audiovisuelles. Veuillez utiliser [SUPPORTED_BROWSERS] pour une meilleure expérience.",
      CALLBACK_FAILED:"Échec de la demande de rappel.",
      CALL_DROPPED_NOTIFICATION_TITLE:"Attention!",
      CALL_DROPPED_NOTIFICATION_MESSAGE:"Votre appel a été interrompu.",
      CALL_DROPPED_NOTIFICATION_CONFIRM:"Continuer",
      CANCEL:"Annuler",
      CONFIRM_TO_INSTALL_BROWSER:"Procéder à l'installation d'un navigateur compatible.",
      EMAIL_INVALID:"Adresse e-mail non valide.",
      FAIL:"Échec",
      FAIL_TO_GAIN_MEDIA_ACCESS:"Échec de l'accès aux médias. Veuillez vérifier les paramètres de votre appareil.",
      GEO_ACCESS_NOT_PERMIT:"La géolocalisation n'a pas été autorisée.",
      GUEST_OFFLINE_MESSAGE: "Votre session est terminée.\nVeuillez fermer ou actualiser la page.",
      LEAVE_WARNING: "Êtes-vous sûr de vouloir quitter?",
      LOGIN_FAILED: "Échec de la connexion.",
      LOST_SERVER_CONNECTION: "Connexion au serveur de signalisation perdue.",
      MEDIA_CONNECTION_LOST:"Votre connexion multimédia a été perdue.",
      NO_LOCATION:"Aucun emplacement n'a été détecté.",
      PASSWORD_REQUIRED:"Mot de passe requis",
      SUCCESS:"Réussite",
      TIMEOUT_ACKNOWLEDGED:"Vous avez été déconnecté en raison d'un dépassement de délai du serveur.",
      USERNAME_REQUIRED:"Nom d'utilisateur requis",
      WAIT: "Veuillez patienter..."
    }
  }

  public static defaultLocalizationOverridesIt: Partial<ILocalization> = {
    language: "it",
    topnav: {
      language_selector: {
        options: {
          en: Languages.en,
          it: Languages.it
        },
        default: "it"
      }
    },
    loginPanel: {
      title: "Accedi",
      exitButtonText: "Esci",
      passwordFieldTitle: "Password",
      usernameFieldTitle: "Nome",
      submitButtonText: "ACCEDI",
      loginText: "Accedi",
      logoutText: "Esci"
    },
    toolbar: {
      leftTitle: "VCC",
      logoAriaLabel: "par",
      rightTitle: "COMPUNETIX",
      toolbar_items: {
        settings: { text: "Impostazioni" },
        enter: { text: "Invio", altText: "Esci" },
        audio: {
          text: "Audio attivato",
          altText: "Audio disattivato"
        },
        video: {
          text: "Video attivato",
          altText: "Video disattivato"
        },
      }
    },
    settings_panel: {
      title: "",
      audio_setting: {
        options: {
          on: {
            text: "Audio attivato",
          },
          off: {
            text: "Audio disattivato",
          }
        }
      },
      video_setting: {
        options: {
          on: {
            text: "Video attivato",
          },
          off: {
            text: "Video disattivato",
          }
        }
      },
      microphone_setting: {
        startTestingText: "Avvia test",
        endTestingText: "Termina test"
      },
      speaker_setting: {
        startTestingText: "Avvia test",
        endTestingText: "Termina test"
      },
      troubleshootText: "Risoluzione dei problemi",
      camera_rotation: "Rotazione della telecamera",
      resolution_setting: {
        title: "Risoluzione",
        autoTitle: "Automatico",
      },
      bandwidth_setting: {
        title: "Larghezza di banda",
        autoTitle: "Automatico",
      },
    },
    participant_panel: {
      listTypes: {
        publicWait : "Coda",
        op : "Agenti",
        sp : "Amministrativo"
      },
    },
    presenceStatus: {
      offline: "Offline",
      online: "Online",
      available: "Disponibile",
      alone_in_conf: "Solo/Attesa",
      busy: "Connesso",
      invisible: "Invisibile",
      away: "Fuori",
      work: "Lavoro",
      connecting: "Connessione in corso",
      ringing: "Squillo in corso",
      onhold: "In attesa",
      ready: "Pronto",
    },
    sharedFolder_panel: {
      idle: "In attesa di trasmissione",
      started: "Avvia trasmissione",
      transmitting: "Trasmissione in corso...",
      finished: "Trasmissione riuscita",
      failed: "Non riuscito. Riprova.",
    },
    footer: {
      copyright: "2016-[CURRENT_YEAR] Compunetix. Tutti i diritti riservati",
    },
    errorMessages: {
      ADD_TO_CONFERENCE_FAIL:"Impossibile partecipare alla conferenza.",
      BROWSER_NOT_SUPPORT:"Il browser [CURRENT_BROWSER] che stai utilizzando non supporta le comunicazioni audiovisive. Utilizza [SUPPORTED_BROWSERS] per un'esperienza migliore.",
      CALLBACK_FAILED:"Impossibile richiedere la richiamata.",
      CALL_DROPPED_NOTIFICATION_TITLE:"Attenzione!",
      CALL_DROPPED_NOTIFICATION_MESSAGE:"La chiamata è caduta.",
      CALL_DROPPED_NOTIFICATION_CONFIRM:"Continua",
      CANCEL:"Annulla",
      CONFIRM_TO_INSTALL_BROWSER:"Procedi con l'installazione di un browser compatibile.",
      EMAIL_INVALID:"Indirizzo e-mail non valido.",
      FAIL:"Non riuscito",
      FAIL_TO_GAIN_MEDIA_ACCESS:"Impossibile ottenere l'accesso ai media. Controlla le impostazioni del tuo dispositivo.",
      GEO_ACCESS_NOT_PERMIT:"La geolocalizzazione non è consentita.",
      GUEST_OFFLINE_MESSAGE: "La sessione è terminata.\nChiudi o aggiorna la pagina.",
      LEAVE_WARNING: "Sei sicuro di voler uscire?",
      LOGIN_FAILED: "Accesso non riuscito",
      LOST_SERVER_CONNECTION: "Connessione al server di segnalazione persa.",
      MEDIA_CONNECTION_LOST:"La connessione multimediale è stata persa.",
      NO_LOCATION:"Nessuna posizione rilevata.",
      PASSWORD_REQUIRED:"Password richiesta",
      SUCCESS:"Riuscito",
      TIMEOUT_ACKNOWLEDGED:"Sei stato disconnesso a causa del timeout del server.",
      USERNAME_REQUIRED:"Nome utente richiesto",
      WAIT: "Attendi..."
    }
  }
  
  public static defaultLocalizationOverridesJa: Partial<ILocalization> = {
    language: "ja",
    topnav: {
      language_selector: {
        options: {
          en: Languages.en,
          ja: Languages.ja
        },
        default: "ja"
      }
    },
    loginPanel: {
      title: "サインイン",
      exitButtonText: "終了",
      passwordFieldTitle: "パスワード",
      usernameFieldTitle: "名前",
      submitButtonText: "サインイン",
      loginText: "ログイン",
      logoutText: "ログアウト"
    },
    toolbar: {
      leftTitle: "COMPUNETIX",
      logoAriaLabel: "による",
      rightTitle: "VCC",
      toolbar_items: {
        settings: { text: "設定" },
        enter: { text: "入力", altText: "退出" },
        audio: {
          text: "オーディオオン",
          altText: "オーディオオフ"
        },
        video: {
          text: "ビデオオン",
          altText: "ビデオオフ"
        },
      }
    },
    settings_panel: {
      title: "設定",
      audio_setting: {
        options: {
          on: {
            text: "オーディオオン",
          },
          off: {
            text: "オーディオオフ",
          }
        }
      },
      video_setting: {
        options: {
          on: {
            text: "ビデオオン",
          },
          off: {
            text: "ビデオオフ",
          }
        }
      },
      microphone_setting: {
        startTestingText: "テスト開始",
        endTestingText: "テスト終了"
      },
      speaker_setting: {
        startTestingText: "テスト開始",
        endTestingText: "テスト終了"
      },
      troubleshootText: "トラブルシューティング",
      camera_rotation: "カメラの回転",
      resolution_setting: {
        title: "解決",
        autoTitle: "自動",
      },
      bandwidth_setting: {
        title: "帯域幅",
        autoTitle: "自動",
      },
    },
    participant_panel: {
      listTypes: {
        publicWait : "キュー",
        op : "エージェント",
        sp : "管理"
      },
    },
    presenceStatus: {
      offline: "オフライン",
      online: "オンライン",
      available: "利用可能",
      alone_in_conf: "単独 / 待機",
      busy: "接続済み",
      invisible: "非表示",
      away: "離席中",
      work: "作業中",
      connecting: "接続中",
      ringing: "呼び出し中",
      onhold: "保留中",
      ready: "準備完了",
    },
    sharedFolder_panel: {
      idle: "送信待機中",
      started: "送信開始",
      transmitting: "送信中 ...",
      finished: "正常に送信されました",
      failed: "失敗しました。もう一度お試しください。",
    },
    footer: {
      copyright: "2016-[CURRENT_YEAR] Compunetix. 無断転載禁止",
    },
    errorMessages: {
      ADD_TO_CONFERENCE_FAIL:"会議に参加できませんでした。",
      BROWSER_NOT_SUPPORT:"使用しているブラウザ [CURRENT_BROWSER] はオーディオビジュアル通信をサポートしていません。より良い体験のために、[SUPPORTED_BROWSERS] を使用してください。",
      CALLBACK_FAILED:"コールバックをリクエストできませんでした。",
      CALL_DROPPED_NOTIFICATION_TITLE:"注意！",
      CALL_DROPPED_NOTIFICATION_MESSAGE:"通話が切れました。",
      CALL_DROPPED_NOTIFICATION_CONFIRM:"続行",
      CANCEL:"キャンセル",
      CONFIRM_TO_INSTALL_BROWSER:"互換性のあるブラウザのインス​​トールに進みます。",
      EMAIL_INVALID:"無効なメール アドレスです。",
      FAIL:"失敗",
      FAIL_TO_GAIN_MEDIA_ACCESS:"メディア アクセスを取得できませんでした。デバイスの設定を確認してください。",
      GEO_ACCESS_NOT_PERMIT:"位置情報は許可されませんでした。",
      GUEST_OFFLINE_MESSAGE: "セッションが終了しました。\nページを閉じるか更新してください。",
      LEAVE_WARNING: "本当に終了してもよろしいですか？",
      LOGIN_FAILED: "ログインに失敗しました。",
      LOST_SERVER_CONNECTION: "シグナリング サーバーへの接続が失われました。",
      MEDIA_CONNECTION_LOST:"メディア接続が失われました。",
      NO_LOCATION:"場所が検出されませんでした。",
      PASSWORD_REQUIRED:"パスワードが必要です。",
      SUCCESS:"成功",
      TIMEOUT_ACKNOWLEDGED:"サーバーのタイムアウトにより切断されました。",
      USERNAME_REQUIRED:"ユーザー名が必要です。",
      WAIT: "お待ちください ..."
    }
  }
  
  public static defaultLocalizationOverridesZh: Partial<ILocalization> = {
    language: "zh",
    topnav: {
      language_selector: {
        options: {
          en: Languages.en,
          zh: Languages.zh
        },
        default: "zh"
      }
    },
    loginPanel: {
      title: "登录",
      exitButtonText: "退出",
      passwordFieldTitle: "密码",
      usernameFieldTitle: "姓名",
      submitButtonText: "登录",
      loginText: "登录",
      logoutText: "注销"
    },
    toolbar: {
      leftTitle: "COMPUNETIX",
      logoAriaLabel: "的",
      rightTitle: "VCC",
      toolbar_items: {
        settings: { text: "设置" },
        enter: { text: "输入", altText: "离开" },
        audio: {
          text: "音频开启",
          altText: "音频关闭"
        },
        video: {
          text: "视频开启",
          altText: "视频关闭"
        },
      }
    },
    settings_panel: {
      title: "设置",
      audio_setting: {
        options: {
          on: {
            text: "音频开启",
          },
          off: {
            text: "音频关闭",
          }
        }
      },
      video_setting: {
        options: {
          on: {
            text: "视频开启",
          },
          off: {
            text: "视频关闭",
          }
        }
      },
      microphone_setting: {
        startTestingText: "开始测试",
        endTestingText: "结束测试"
      },
      speaker_setting: {
        startTestingText: "开始测试",
        endTestingText: "结束测试"
      },
      troubleshootText: "故障排除",
      camera_rotation: "摄像头旋转",
      resolution_setting: {
        title: "分辨率",
        autoTitle: "自动的",
      },
      bandwidth_setting: {
        title: "带宽”",
        autoTitle: "自动的",
      },
    },
    participant_panel: {
      listTypes: {
        publicWait : "队列",
        op : "代理",
        sp : "管理"
      },
    },
    presenceStatus: {
      offline: "离线",
      online: "在线",
      available: "可用",
      alone_in_conf: "单独 / 等待",
      busy: "已连接",
      invisible: "不可见",
      away: "离开",
      work: "工作",
      connecting: "连接中",
      ringing: "振铃中",
      onhold: "暂停中",
      ready: "就绪",
    },
    sharedFolder_panel: {
      idle: "等待传输",
      started: "开始传输",
      transmitting: "传输中...",
      finished: "传输成功",
      failed: "失败。请重试。",
    },
    footer: {
      copyright: "2016-[CURRENT_YEAR] Compunetix. 保留所有权利",
    },
    errorMessages: {
      ADD_TO_CONFERENCE_FAIL:"无法加入会议。",
      BROWSER_NOT_SUPPORT:"您使用的浏览器 [CURRENT_BROWSER] 不支持视听通信。请使用 [SUPPORTED_BROWSERS] 获得更好的体验。",
      CALLBACK_FAILED:"无法请求回拨。",
      CALL_DROPPED_NOTIFICATION_TITLE:"注意！",
      CALL_DROPPED_NOTIFICATION_MESSAGE:"您的通话已中断。",
      CALL_DROPPED_NOTIFICATION_CONFIRM:"继续",
      CANCEL:"取消",
      CONFIRM_TO_INSTALL_BROWSER:"继续安装兼容的浏览器。",
      EMAIL_INVALID:"电子邮件地址无效。",
      FAIL:"失败",
      FAIL_TO_GAIN_MEDIA_ACCESS:"无法获取媒体访问权限。请检查您的设备设置。",
      GEO_ACCESS_NOT_PERMIT:"不允许地理定位。",
      GUEST_OFFLINE_MESSAGE: "您的会话已结束。\n请关闭或刷新页面。",
      LEAVE_WARNING: "您确定要离开吗？",
      LOGIN_FAILED: "登录失败。",
      LOST_SERVER_CONNECTION: "与信令服务器的连接丢失。",
      MEDIA_CONNECTION_LOST:"您的媒体连接已丢失。",
      NO_LOCATION:"未检测到位置。",
      PASSWORD_REQUIRED:"需要密码",
      SUCCESS:"成功",
      TIMEOUT_ACKNOWLEDGED:"由于服务器超时，您已断开连接。",
      USERNAME_REQUIRED:"需要用户名",
      WAIT: "请稍候 ..."
    }
  }
  
  static getDefaultLocalizationValues(languageCode: string = "en"): ILocalization {
    let result = FlavorUtil.defaulLocalizationValues;
    switch(languageCode) {
      case "de": {
        result = {...result, ...FlavorUtil.defaultLocalizationOverridesDe};
        break;
      }
      case "es": {
        result = {...result, ...FlavorUtil.defaultLocalizationOverridesEs};
        break;
      }
      case "fr": {
        result = {...result, ...FlavorUtil.defaultLocalizationOverridesFr};
        break;
      }
      case "it": {
        result = {...result, ...FlavorUtil.defaultLocalizationOverridesIt};
        break;
      }
      case "ja": {
        result = {...result, ...FlavorUtil.defaultLocalizationOverridesJa};
        break;
      }
      case "zh": {
        result = {...result, ...FlavorUtil.defaultLocalizationOverridesZh};
        break;
      }
      case "en": {
        // Same as default.
        break;
      }
    }
  
    return result;
  }

  public static register() {
    this.flavorToLocalization = {};
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.mode_mode]] = ".mode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.mode_groupId]] = ".groupId";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_enabled]] = ".page";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_simplified_view_for_mobile_user]] = ".page.loadSimplifiedViewForMobileUsers";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_title]] = ".page.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_font_color]] = ".page.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_font_family]] = ".page.fontFamily";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_font_size]] = ".page.fontSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_button_prefer_sharp_edge]] =
      ".page.preferSharpEdgeButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_background_color]] = ".page.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_background_image]] = ".page.backgroundImage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_redirect_url_on_leave]] = ".page.redirectUrlOnLeave";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_full_screen_mode]] = ".page.fullScreenMode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_enabled]] = ".topnav";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_font_color]] = ".topnav.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_background_color]] =
      ".topnav.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_language_selector_options]] =
      ".topnav.language_selector.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_language_selector_default]] =
      ".topnav.language_selector.default";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_menu_items]] = ".topnav.menu";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_enabled]] = ".loginPanel";
    this.flavorToLocalization["login_panel_enabled"] = ".loginPanel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_title]] = ".loginPanel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_exit_button]] =
      ".loginPanel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_email_field_title]] =
      ".loginPanel.emailFieldTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_password_field_title]] =
      ".loginPanel.passwordFieldTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_username_field_title]] =
      ".loginPanel.usernameFieldTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_submit_button_text]] =
      ".loginPanel.submitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_help_message_1]] =
      ".loginPanel.helpMessage1";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_help_message_2]] =
      ".loginPanel.helpMessage2";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_font_color]] =
      ".loginPanel.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_background_color]] =
      ".loginPanel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_submit_button_color]] =
      ".loginPanel.submitButtonColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_forgot_username_button_text]] =
      ".loginPanel.forgotUsernameButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_forgot_password_button_text]] =
      ".loginPanel.forgotPasswordButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_send_button_text]] =
      ".loginPanel.sendButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_cancel_button_text]] =
      ".loginPanel.cancelButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_openidc_url]] =
      ".loginPanel.loginWithOpenIDCUrl";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_openidc_button]] =
      ".loginPanel.loginWithOpenIDCButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_desktop_only_text]] =
      ".loginPanel.desktopOnlyText";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_login_as_available]] =
      ".loginPanel.loginAsAvailable";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_enabled]] = ".toolbar";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_font_color]] = ".toolbar.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_font_hover_color]] = ".toolbar.hoverColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_font_active_color]] =
      ".toolbar.activeColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_font_family]] = ".toolbar.fontFamily";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_background_color]] =
      ".toolbar.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_border_color]] = ".toolbar.borderColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_left]] = ".toolbar.leftTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_right]] = ".toolbar.rightTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_color]] = ".toolbar.titleColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_logo]] = ".toolbar.logo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_logo_aria_label]] = ".toolbar.logoAriaLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_settings_toggle]] =
      ".toolbar.toolbar_items.settings";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_enter_toggle]] =
      ".toolbar.toolbar_items.enter";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_enter_toggle_host_only]] =
      ".toolbar.toolbar_items.enter.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_toggle]] =
      ".toolbar.toolbar_items.lock";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_toggle_host_only]] =
      ".toolbar.toolbar_items.lock.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle]] =
      ".toolbar.toolbar_items.chat";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle_host_only]] =
      ".toolbar.toolbar_items.chat.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_incoming_queue_toggle]] =
      ".toolbar.toolbar_items.incomingQueue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_incoming_queue_toggle_host_only]] =
      ".toolbar.toolbar_items.incomingQueue.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_operators_toggle]] =
      ".toolbar.toolbar_items.operators";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_operators_toggle_host_only]] =
      ".toolbar.toolbar_items.operators.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle]] =
      ".toolbar.toolbar_items.shareScreen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle_host_only]] =
      ".toolbar.toolbar_items.shareScreen.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_content_share_toggle]] =
      ".toolbar.toolbar_items.contentShare";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_content_share_toggle_host_only]] =
      ".toolbar.toolbar_items.contentShare.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_optimize_for_video_toggle]] =
      ".toolbar.toolbar_items.optimizeForVideo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle]] =
      ".toolbar.toolbar_items.inspector";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle_host_only]] =
      ".toolbar.toolbar_items.inspector.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle]] =
      ".toolbar.toolbar_items.record";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle_host_only]] =
      ".toolbar.toolbar_items.record.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_audio_toggle]] =
      ".toolbar.toolbar_items.audio";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_video_toggle]] =
      ".toolbar.toolbar_items.video";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_full_screen_toggle]] =
      ".toolbar.toolbar_items.fullscreen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_video_aspect_toggle]] =
      ".toolbar.toolbar_items.videoAspect";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle]] =
      ".toolbar.toolbar_items.map";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle_host_only]] =
      ".toolbar.toolbar_items.map.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_dashboard_open]] =
      ".toolbar.toolbar_items.dashboard";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_virtual_background_toggle]] =
      ".toolbar.toolbar_items.virtualBackground";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle]] =
      ".toolbar.toolbar_items.snapshot";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle_host_only]] =
      ".toolbar.toolbar_items.snapshot.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_folder_toggle]] =
      ".toolbar.toolbar_items.sharedFolder";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_folder_toggle_host_only]] =
      ".toolbar.toolbar_items.sharedFolder.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_enabled]] = ".title_screen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_font_color]] =
      ".title_screen.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_background_color]] =
      ".title_screen.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_title]] = ".title_screen.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_logo]] = ".title_screen.logo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.queue_screen_enabled]] = ".queue_screen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.queue_screen_list]] =
      ".queue_screen.list";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_enabled]] = ".connect_screen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_font_color]] =
      ".connect_screen.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_font_size]] =
      ".connect_screen.fontSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_top_position]] =
      ".connect_screen.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_background_color]] =
      ".connect_screen.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_title]] =
      ".connect_screen.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_logo]] =
      ".connect_screen.logo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_logo_size]] =
      ".connect_screen.logoSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_image]] =
      ".connect_screen.connectingImage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_subtitle]] =
      ".connect_screen.subtitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button]] =
      ".connect_screen.connectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_text_color]] =
      ".connect_screen.connectButtonTextColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_background_color]] =
      ".connect_screen.connectButtonBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_bottom]] =
      ".connect_screen.connectPanelBottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_background_color]] =
      ".connect_screen.connectPanelBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_info_visible]] =
      ".connect_screen.connectInfoVisible";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_waittime_label]] =
      ".connect_screen.connectPanelWaitTimeLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_position_label]] =
      ".connect_screen.connectPanelPositionLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_visible]] =
      ".connect_screen.callBackButtonVisible";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_visible]] =
      ".connect_screen.dropCallButtonVisible";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button]] =
      ".connect_screen.dropCallButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_text_color]] =
      ".connect_screen.dropCallButtonTextColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_background_color]] =
      ".connect_screen.dropCallButtonBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button]] =
      ".connect_screen.callBackButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_text_color]] =
      ".connect_screen.callBackButtonTextColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_background_color]] =
      ".connect_screen.callBackButtonBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_phone_number_text]] =
      ".connect_screen.callBackPhoneNumberText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_send_request_Button]] =
      ".connect_screen.callBackSendRequestButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_cancel_Button]] =
      ".connect_screen.callBackCancelButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_close_browser_label]] =
      ".connect_screen.callBackCloseBrowserMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_reject_camera_permission_label]] =
      ".connect_screen.guestRejectCameraPermissionMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_auto_full_screen]] =
      ".connect_screen.connectButtonAutoFullScreen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_hidden]] =
      ".connect_screen.connectButtonHidden";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_use_rectangle_button]] =
      ".connect_screen.useRectangleButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_status_label]] =
      ".connect_screen.connectingStatusLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_status_audio]] =
      ".connect_screen.connectingStatusAudio";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_onhold_status_label]] =
      ".connect_screen.onholdStatusLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_image]] =
      ".connect_screen.waitImage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_video]] =
      ".connect_screen.waitVideo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_url]] =
      ".connect_screen.waitScreenEmbeddedUrl";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_title]] =
    ".connect_screen.waitScreenEmbeddedTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_style]] =
    ".connect_screen.waitScreenEmbeddedStyle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_background_color]] =
      ".connect_screen.waitBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_auto_connect]] =
      ".connect_screen.autoConnect";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_no_operator_available_label]] =
      ".connect_screen.noOperatorAvailableLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_info_position_top]] =
      ".connect_screen.waitTop";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_info_position_width]] =
      ".connect_screen.waitWidth";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_invisible_status_label]] =
      ".connect_screen.invisibleStatusLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_color]] =
      ".connect_screen.connectingLabelColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_size]] =
      ".connect_screen.connectingLabelSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_background_color]] =
      ".connect_screen.connectingLabelBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_voice_call_screen_title]] =
      ".connect_screen.voiceCallScreenTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_auto_leave]] =
      ".connect_screen.autoLeave";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_auto_answer]] =
      ".connect_screen.autoAnswer";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless]] =
      ".connect_screen.touchless";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_message]] =
      ".connect_screen.touchless_message";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection]] =
      ".connect_screen.touchless_detection";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_ratio]] =
      ".connect_screen.touchless_detection_ratio";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_movement]] =
      ".connect_screen.touchless_detection_movement";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_confidence]] =
      ".connect_screen.touchless_detection_confidence";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_autodrop]] =
      ".connect_screen.touchless_autodrop";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_autodrop_message]] =
      ".connect_screen.touchless_autodrop_message";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_mobile_footer]] =
      ".connect_screen.mobileFooter";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_enabled]] = ".video_screen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_pip_position_fixed
      ]] =
      ".c";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_mic_mute_warning_message]] =
      ".video_screen.micMuteWarningMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_font_color]] =
      ".video_screen.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_background_color]] =
      ".video_screen.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_data_title]] = ".video_screen.dataTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_show_names]] = ".video_screen.showNames";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.loopback_panel_enabled]] = ".loopback_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_enabled]] = ".settings_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_title]] = ".settings_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_relay_candidate_only]] =
      ".settings_panel.relayCandidateOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_disable_audio_only]] =
      ".settings_panel.disableAudioOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_audio_codecs]] =
      ".settings_panel.audio_setting.codecs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_audio_toggle]] =
      ".settings_panel.audio_setting.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_primary_video_codecs]] =
      ".settings_panel.video_setting.codecs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_secondary_video_codecs]] =
      ".settings_panel.secondary_video_setting.codecs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_video_toggle]] =
      ".settings_panel.video_setting.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_microphone_test_toggle]] =
      ".settings_panel.microphone_setting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_speaker_test_toggle]] =
      ".settings_panel.speaker_setting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_default_volume]] =
      ".settings_panel.default_volume";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_camera_rotation]] =
      ".settings_panel.camera_rotation";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_add_secondary_camera_text]] = ".addSecondaryCameraText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_remove_secondary_camera_text]] = ".removeSecondaryCameraText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_title]] =
      ".settings_panel.resolution_setting.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_auto_title]] =
      ".settings_panel.resolution_setting.autoTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_options]] =
      ".settings_panel.resolution_setting.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_pc_value]] =
      ".settings_panel.resolution_setting.defaultPCValue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_mobile_value]] =
      ".settings_panel.resolution_setting.defaultMobileValue";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_pc_secondary_value]
    ] = ".settings_panel.resolution_setting.defaultPCSecondaryValue";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_mobile_secondary_value]
    ] = ".settings_panel.resolution_setting.defaultMobileSecondaryValue";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_sharpness_first_resolution]
    ] = ".settings_panel.presentation_setting.sharpness_first.resolution";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_sharpness_first_framerate]
    ] = ".settings_panel.presentation_setting.sharpness_first.framerate";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_motion_first_resolution]
    ] = ".settings_panel.presentation_setting.motion_first.resolution";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_motion_first_framerate]
    ] = ".settings_panel.presentation_setting.motion_first.framerate";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_title]] =
      ".settings_panel.bandwidth_setting.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_auto_title]] =
      ".settings_panel.bandwidth_setting.autoTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_options]] =
      ".settings_panel.bandwidth_setting.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_default_pc_value]] =
      ".settings_panel.bandwidth_setting.defaultPCValue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_default_mobile_value]] =
      ".settings_panel.bandwidth_setting.defaultMobileValue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_device_names]] = ".deviceNames";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_troubleshoot_text]] = ".troubleshootText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_font_color]] =
      ".settings_panel.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_background_color]] =
      ".settings_panel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_top]] = ".settings_panel.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bottom]] = ".settings_panel.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_left]] = ".settings_panel.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_right]] = ".settings_panel.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_width]] = ".settings_panel.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_run_tester_on_first_visit]] =
      ".settings_panel.runTesterOnFirstVisit";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_show_customized_video_muted_placeholder]] =
      ".settings_panel.showCustomizedVideoMutedPlaceholder";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_avatar_image_src]] =
      ".settings_panel.avatarImageSrc";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_virtual_background_method]] =
      ".settings_panel.virtualBackgroundMethod";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_video_aspect_title]] =
      ".settings_panel.videoAspectTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_video_aspect_default]] = ".settings_panel.videoAspectDefault";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_enabled]] = ".diagnostic_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_on_screen_inbound_quality_guages]] =
      ".diagnostic_panel.onScreenInboundQualityGauges";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_on_screen_outbound_quality_guages]] =
      ".diagnostic_panel.onScreenOutboundQualityGauges";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_info_dialog_quality_gauges]] =
      ".diagnostic_panel.infoDialogQualityGauges";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_watchrtc_active]] =
      ".diagnostic_panel.watchRtcActive";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_watchrtc_api_key]] =
      ".diagnostic_panel.watchRtcApiKey";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_enabled]] = ".chat_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_title]] = ".chat_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_with_api_user]] = ".chat_panel.chatWithAPIUser";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_with_api_user_title]] =
      ".chat_panel.chatWithAPIUserTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_disable_chat_with_web_user]] =
      ".chat_panel.disableChatWithWebUser";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_exit_button]] =
      ".chat_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_edit_button]] =
      ".chat_panel.editButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_done_button]] =
      ".chat_panel.doneButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_back_button]] =
      ".chat_panel.backbuttonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_start_chat_button]] =
      ".chat_panel.startChatButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_send_message_title]] =
      ".chat_panel.sendMessageFieldTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_font_color]] =
      ".chat_panel.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_font_size]] =
      ".chat_panel.fontSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_font_family]] =
      ".chat_panel.fontFamily";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_background_color]] =
      ".chat_panel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_top]] = ".chat_panel.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_bottom]] = ".chat_panel.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_left]] = ".chat_panel.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_right]] = ".chat_panel.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_width]] = ".chat_panel.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_add_participant_button_tooltip]] =
      ".chat_panel.addParticipantButtonTooltip";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_add_participant_button_text]] =
      ".chat_panel.addParticipantButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_start_group_chat_button]] =
      ".chat_panel.startGroupChatButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_disabled_message]] =
      ".chat_panel.chatDisabledText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_option]] =
      ".chat_panel.chatAudioAlertOption";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_type]] =
      ".chat_panel.chatAudioAlertType";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_frequency]] =
      ".chat_panel.chatAudioAlertFrequency";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_duration]] =
      ".chat_panel.chatAudioAlertDuration";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_interval]] =
      ".chat_panel.chatAudioAlertInterval";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_silence_chat_audio_alerts]] =
      ".chat_panel.silenceChatAudioAlerts.text";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_silence_chat_audio_alerts_host_only]] =
      ".chat_panel.silenceChatAudioAlertsHostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_enabled]] = ".participant_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_exit_button]] =
      ".participant_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_connect_button]] =
      ".participant_panel.connectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_disconnect_button]] =
      ".participant_panel.disconnectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_start_monitor_button]] =
      ".participant_panel.startMonitorButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_whisper_button]] =
      ".participant_panel.whisperButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_exit_monitor_button]] =
      ".participant_panel.exitMonitorButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_select_button]] =
      ".participant_panel.selectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_unselect_button]] =
      ".participant_panel.unselectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_hold_button]] =
      ".participant_panel.holdButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_resume_button]] =
      ".participant_panel.resumeButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_take_over_button]] =
      ".participant_panel.takeOverButtonText";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_transfer_to_queue_button_disabled]] =
      ".participant_panel.transferToQueueButtonDisabled";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_transfer_to_queue_button]] =
      ".participant_panel.transferToQueueButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_chat_with_conjunction_text]] =
      ".participant_panel.chatWithConjunctionText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_hold_conference_button]] =
      ".participant_panel.holdConferenceButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_resume_conference_button]] =
      ".participant_panel.resumeGuestConferenceButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_callback_confirm_button]] =
      ".participant_panel.callbackConfirmButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_callback_handle_external_button]] =
      ".participant_panel.callbackHandleExternalText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_participant_status]] =
      ".participant_panel.participantStatusText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_list_type]] =
      ".participant_panel.listTypes";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_list_sort_by_enter_time]] =
      ".participant_panel.sortByEnterTime";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_list_show_offline_endpoints]] =
      ".participant_panel.showOfflineEndpoints";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_list_show_offline_users]] =
      ".participant_panel.showOfflineUsers";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_font_color]] =
      ".participant_panel.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_background_color]] =
      ".participant_panel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_top]] = ".participant_panel.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_bottom]] =
      ".participant_panel.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_left]] =
      ".participant_panel.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_right]] =
      ".participant_panel.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_width]] =
      ".participant_panel.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_max_participants_to_connect]] =
      ".participant_panel.maxParticipantsToConnect";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_disable_operator_guest_connect]] =
      ".participant_panel.disableOperatorGuestConnect";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_hide_guests_with_device_problems]] =
      ".participant_panel.hideGuestsWithDeviceProblems";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_enabled]] = ".presenceStatus";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_offline]] =
      ".presenceStatus.offline";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_online]] = ".presenceStatus.online";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_available]] =
      ".presenceStatus.available";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_alone_in_conf]] =
      ".presenceStatus.alone_in_conf";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_busy]] = ".presenceStatus.busy";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_invisible]] =
      ".presenceStatus.invisible";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_away]] = ".presenceStatus.away";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_work]] = ".presenceStatus.work";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_connecting]] =
      ".presenceStatus.connecting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_ringing]] =
      ".presenceStatus.ringing";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_onhold]] = ".presenceStatus.onhold";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_ready]] =
      ".presenceStatus.ready";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_ready_timelapse]] =
      ".presenceStatus.readyTimelapse";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_custom1]] =
      ".presenceStatus.custom1";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_custom2]] =
      ".presenceStatus.custom2";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_custom3]] =
      ".presenceStatus.custom3";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_custom4]] =
      ".presenceStatus.custom4";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.content_share_panel_enabled]] = ".contentShare_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.content_share_panel_cursor_image]] = ".contentShare_panel.cursorImage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.content_share_panel_cursor_px]] = ".contentShare_panel.cursorPx";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_enabled]] =
      ".sharedFolder_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_title]] =
      ".sharedFolder_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_conference_files_title]] =
      ".sharedFolder_panel.conference_files";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_remove_conf_text]] =
      ".sharedFolder_panel.remove_conf";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_request_file_text]] =
      ".sharedFolder_panel.request_file";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_request_all_text]] =
      ".sharedFolder_panel.request_all";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_local_files_title]] =
      ".sharedFolder_panel.local_files";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_remove_local_text]] =
      ".sharedFolder_panel.remove_local";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_offer_file_text]] =
      ".sharedFolder_panel.offer_file";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_load_file_text]] =
      ".sharedFolder_panel.load_file";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_idle]] =
      ".sharedFolder_panel.idle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_started]] =
      ".sharedFolder_panel.started";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_transmitting]
    ] = ".sharedFolder_panel.transmitting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_finished]] =
      ".sharedFolder_panel.finished";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_failed]] =
      ".sharedFolder_panel.failed";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_autosave]] =
      ".sharedFolder_panel.autosave";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_enabled]] = ".record_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_title]] = ".record_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_warning_message]] =
      ".record_panel.warningMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_confirm_button]] =
      ".record_panel.confirmButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_audio_mode_text]] =
      ".record_panel.audioModeText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_video_mode_text]] =
      ".record_panel.videoModeText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_disabled_text]] =
      ".record_panel.disabledText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_local_only_text]] =
      ".record_panel.localOnlyText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_remote_only_text]] =
      ".record_panel.remoteOnlyText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_both_sides_text]] =
      ".record_panel.bothSidesText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_exit_button]] =
      ".record_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_auto_record]] =
      ".record_panel.autoRecord";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_send_to_ftp]] =
      ".record_panel.sendToFTP";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_audio_mode]] =
      ".record_panel.audioMode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_video_mode]] =
      ".record_panel.videoMode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_filename_pattern]] =
      ".record_panel.filenamePattern";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_enabled]] = ".lock_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_auto_lock]] = ".lock_panel.autoLock";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_modalTitle]] =
      ".lock_panel.modalTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_exitText]] =
      ".lock_panel.exitText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_submitText]] =
      ".lock_panel.submitText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_submitBgColor]] =
      ".lock_panel.submitBgColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_submitTextColor]] =
      ".lock_panel.submitTextColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_backgroundColor]] =
      ".lock_panel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_textColor]] =
      ".lock_panel.textColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_entryPlaceholder]] =
      ".lock_panel.entryPlaceholder";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_enabled]] = ".footer";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_font_color]] = ".footer.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_font_family]] = ".footer.fontFamily";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_background_color]] =
      ".footer.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_border_color]] = ".footer.borderColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_copyright]] = ".footer.copyright";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_menu]] = ".footer.menu";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_social_menu]] = ".footer.social_menu";

    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.reporting_enabled]] = ".reporting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.reporting_max_preview]] = ".reporting.maxPreview";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.reporting_max_download]] = ".reporting.maxDownload";

    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.error_message_enabled]] = ".errorMessages";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.error_message_list]] = ".errorMessages";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ice_config_enabled]] = ".iceConfigs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ice_config_list]] = ".iceConfigs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.snapshot_panel_enabled]] = ".snapshot_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.snapshot_panel_video_mode]] =
      ".snapshot_panel.videoMode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.snapshot_panel_file_name_pattern]] =
      ".snapshot_panel.filenamePattern";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.take_photo_enabled]] = ".take_photo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.take_photo_resolution]] = ".take_photo.resolution";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.take_photo_framerate]] = ".take_photo.frameRate";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_enabled]] = ".map_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_hide_by_default]] =
      ".map_panel.hideByDefault";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_access_geo_title]] =
      ".map_panel.accessGeoTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_default_latitude]] =
      ".map_panel.defaultLatitude";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_default_longitude]] =
      ".map_panel.defaultLongitude";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_default_zoom]] = ".map_panel.defaultZoom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.notepad_enabled]] = ".notepad";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.notepad_template]] = ".notepad.template";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_enabled]] = ".ring_tone_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_title]] = ".ring_tone_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_confirm_button]] =
      ".ring_tone_panel.confirmButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_confirm_voice_button]] =
      ".ring_tone_panel.confirmVoiceButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_exit_button]] =
      ".ring_tone_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_duration]] =
      ".ring_tone_panel.duration";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_enabled]] = ".twilioConfig";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_number]] = ".twilioConfig.number";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_allow_dial_out]] = ".twilioConfig.allowOperatorDialout";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_error_message]] = ".twilioConfig.errorMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_active]] = ".twilioConfig.gatherActive";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_message]] = ".twilioConfig.gatherMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_message_delay]] = ".twilioConfig.gatherMessageDelay";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_timeout]] = ".twilioConfig.gatherTimeout";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_pin_length]] = ".twilioConfig.pinLength";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.custom_css_enabled]] = ".customCSS";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.custom_css_apply_ada_styles]] = ".customCSS.applyADAStyles";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.custom_css_content]] = ".customCSS.content";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.rtc_config_enabled]] = ".rtcConfig";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.rtc_config_bundle_policy]] = ".rtcConfig.bundlePolicy";

    // register localization to flavor
    this.localizationToFlavor = {};
    _.forEach(this.flavorToLocalization, (path: string, flavorKey: string) => {
      this.localizationToFlavor[path] = flavorKey;
    });
    // register panel to toolbar toggle
    this.panelToToolbarToggle = {};
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.settings_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_settings_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.lock_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.chat_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.participant_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_incoming_queue_toggle] ||
      FlavorParameterKey[FlavorParameterKey.toolbar_items_operators_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.share_screen_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.content_share_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_content_share_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.record_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.map_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.snapshot_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle];
  }
  public static setLocalizationValueByFlavorKey(localizationData: ILocalization, flavorKey: string, value: any): void {
    if (!localizationData || !flavorKey) {
      return;
    }
    if (!this.flavorToLocalization) {
      this.register();
    }
    let path: string = this.flavorToLocalization[flavorKey];
    if (!path) {
      return;
    }
    if (Array.isArray(value) && _.isEmpty(value)) {
      value = undefined;
    }
    let pathList: string[] = _.compact(path.split("."));
    var current: any = localizationData;
    let childPath: string;
    while (pathList.length) {
      childPath = pathList.shift();
      if (value == null && current[childPath] == null) {
        break;
      }
      if (flavorKey.endsWith("_host_only") && typeof value !== "boolean") {
        break;
      }
      if (pathList.length > 0) {
        if (current[childPath] == null || typeof current[childPath] !== "object") {
          current[childPath] = {};
        }
        current = current[childPath];
      } else {
        if (value == null) {
          current[childPath] = undefined;
          if (flavorKey.endsWith("_enabled") && this.panelToToolbarToggle[flavorKey]) {
            this.setLocalizationValueByFlavorKey(localizationData, this.panelToToolbarToggle[flavorKey], value);
          }
        } else if (flavorKey.endsWith("_enabled")) {
          current[childPath] = value ? current[childPath] || {enabled: true} : null;
        } else if (flavorKey === "topnav_menu_items" || flavorKey.endsWith("_menu")) {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forOwn(value, (key: string, v: string) => {
            current[childPath].push({ url: key, text: v });
          });
        } else if (flavorKey === "settings_panel_resolution_options") {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forEach(value, (resolution: any) => {
            if (resolution.width && resolution.height) {
              current[childPath].push({
                text: resolution.width + " x " + resolution.height,
                value: {
                  width: resolution.width,
                  height: resolution.height,
                },
              });
            }
          });
        } else if (flavorKey === "settings_panel_bandwidth_options") {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forEach(value, (bandwidth: string) => {
            if (!bandwidth || isNaN(Number(bandwidth))) {
              return;
            }
            current[childPath].push({ text: bandwidth, value: Number(bandwidth) });
          });
        } else if (flavorKey === "ice_config_list") {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forEach(value, (ice_server: any) => {
            if (!ice_server.host) {
              return;
            }
            if (ice_server.username || ice_server.authSecret) {
              current[childPath].push({
                url: ice_server.host,
                username: ice_server.username,
                credential: ice_server.password,
                authSecret: ice_server.authSecret
              });
            } else {
              current[childPath].push({ url: ice_server.host });
            }
          });
        } else if (value.hasOwnProperty("trueValue")) {
          if (flavorKey === "settings_panel_audio_toggle" || flavorKey === "settings_panel_video_toggle") {
            current[childPath] = {};
            current[childPath]["on"] = {};
            current[childPath]["on"].text = value.trueValue;
            current[childPath]["on"].value = true;
            current[childPath]["off"] = {};
            current[childPath]["off"].text = value.falseValue;
            current[childPath]["off"].value = false;
          } else if (
            flavorKey === "settings_panel_microphone_test_toggle" ||
            flavorKey === "settings_panel_speaker_test_toggle"
          ) {
            current[childPath] = {};
            current[childPath].startTestingText = value.trueValue;
            current[childPath].endTestingText = value.falseValue;
          } else if (value.trueValue) {
            current[childPath] = {};
            current[childPath].text = value.trueValue;
            current[childPath].altText = value.falseValue;
          }
        } else {
          current[childPath] = value;
        }
      }
    }
  }
  public static setFlavorValuesByLocalizationData(
    flavor: IFlavor,
    currentDataEntry: any,
    currentEntryPath: string = ""
  ) {
    if (!flavor || !currentDataEntry) {
      return;
    }
    if (!this.flavorToLocalization) {
      this.register();
    }
    for (var property in currentDataEntry) {
      if (currentDataEntry.hasOwnProperty(property)) {
        if (
          this.localizationToFlavor[currentEntryPath + "." + property] &&
          flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]]
        ) {
          flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value =
            currentDataEntry[property];
        }
        if (typeof currentDataEntry[property] === "object") {
          if (flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]]) {
            if (property.toLowerCase().endsWith("menu")) {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = {};
                _.forEach(currentDataEntry[property], (menu: { text: string; url: string }) => {
                  flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value[menu.text] =
                    menu.url;
                });
              }
            } else if (currentEntryPath.endsWith("toolbar_items")) {
              flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = {
                trueValue: currentDataEntry[property] ? currentDataEntry[property].text : null,
                falseValue: currentDataEntry[property] ? currentDataEntry[property].altText : null,
              };
              this.setFlavorValuesByLocalizationData(
                flavor,
                currentDataEntry[property],
                currentEntryPath + "." + property
              );
            } else if (
              currentEntryPath + "." + property === ".settings_panel.audio_setting.options" ||
              currentEntryPath + "." + property === ".settings_panel.video_setting.options"
            ) {
              flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = {
                trueValue: currentDataEntry[property]["on"].text,
                falseValue: currentDataEntry[property]["off"].text,
              };
            } else if (
              currentEntryPath + "." + property === ".settings_panel.microphone_setting" ||
              currentEntryPath + "." + property === ".settings_panel.speaker_setting"
            ) {
              flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = {
                trueValue: currentDataEntry[property].startTestingText,
                falseValue: currentDataEntry[property].endTestingText,
              };
            } else if (currentEntryPath + "." + property === ".settings_panel.resolution_setting.options") {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = [];
                _.forEach(currentDataEntry[property], (resolution: any) => {
                  flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value.push({
                    width: resolution.value.width,
                    height: resolution.value.height,
                  });
                });
              }
            } else if (currentEntryPath + "." + property === ".settings_panel.bandwidth_setting.options") {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = [];
                _.forEach(currentDataEntry[property], (bandwidth: any) => {
                  flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value.push(
                    bandwidth.value
                  );
                });
              }
            } else if (currentEntryPath + "." + property === ".iceConfigs") {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = [];
                _.forEach(currentDataEntry[property], (iceServer: any) => {
                  flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value.push({
                    host: iceServer.url,
                    username: iceServer.username,
                    password: iceServer.credential,
                    authSecret: iceServer.authSecret
                  });
                });
              }
            } else {
              this.setFlavorValuesByLocalizationData(
                flavor,
                currentDataEntry[property],
                currentEntryPath + "." + property
              );
            }
          } else {
            this.setFlavorValuesByLocalizationData(
              flavor,
              currentDataEntry[property],
              currentEntryPath + "." + property
            );
          }
        }
      }
    }
  }
  public static resolve(obj: any, path: string): any {
    let pathList: string[] = _.compact(path.split("."));
    var current = obj;
    while (pathList.length) {
      if (typeof current !== "object") {
        return undefined;
      }
      current = current[pathList.shift()];
    }
    return current;
  }
  public static iterate(obj: any, stack: string): void {
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] === "object") {
          this.iterate(obj[property], stack + "." + property);
        } else {
          console.error("flavor-util missing", property + "   " + obj[property], stack + "." + property);
        }
      }
    }
  }

  /**
   * convert localization data to flavor
   */
  static convertLocalizationToFlavor(flavorTemplate: IFlavor, data: ILocalization): IFlavor {
    let flavor: IFlavor = flavorTemplate;
    FlavorUtil.setFlavorValuesByLocalizationData(flavor, data);
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.mode_enabled]].value = true;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.page_enabled]].value = true;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.topnav_enabled]].value = true;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.toolbar_enabled]].value = true;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.login_enabled]].value = true;

    flavor.parameters[FlavorParameterKey[FlavorParameterKey.chat_panel_enabled]].value = !!data.chat_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.connect_screen_enabled]].value = !!data.connect_screen;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.content_share_panel_enabled]].value = !!data.contentShare_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_enabled]].value = !!data.diagnostic_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.footer_enabled]].value = !!data.footer;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.ice_config_enabled]].value = !!data.iceConfigs;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.lock_panel_enabled]].value = !!data.lock_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.loopback_panel_enabled]].value = !!data.loopback_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.map_panel_enabled]].value = !!data.map_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.notepad_enabled]].value = !!data.notepad;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.participant_panel_enabled]].value = !!data.participant_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.presence_status_enabled]].value = !!data.presenceStatus;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.queue_screen_enabled]].value = !!data.queue_screen;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.record_panel_enabled]].value = !!data.record_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.reporting_enabled]].value = true;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_enabled]].value = !!data.ring_tone_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.rtc_config_enabled]].value = !!data.rtcConfig;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.settings_panel_enabled]].value = !!data.settings_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.share_folder_panel_enabled]].value = !!data.sharedFolder_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.snapshot_panel_enabled]].value = !!data.snapshot_panel;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.take_photo_enabled]].value = !!data.take_photo;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.twilio_enabled]].value = !!data.twilioConfig;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.title_screen_enabled]].value = !!data.title_screen;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.video_screen_enabled]].value = !!data.video_screen;

    flavor.parameters[FlavorParameterKey[FlavorParameterKey.custom_css_enabled]].value = !!data.customCSS;
    flavor.parameters[FlavorParameterKey[FlavorParameterKey.error_message_enabled]].value = true;
    return flavor;
  }
}
