/**
 * Copyright Compunetix Incorporated 2016-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import * as _ from "lodash";
import { IEndpoint, PresenceStatus, PresenceState, IEndpointRef } from "../endpoint/endpoint.interface";
import { Endpoint } from "../endpoint/endpoint";
import { IActiveConference, IConferenceExpanded, IConferenceRef, IExpandedActiveConference, IExpandedMonitorConference, IExpandedQueueConference, IMonitorConference, IQueueConference, RoomType } from "./conference.interface";
import { Hash } from "../util/hash";
import { ISkillTags, ICategory, ISkillSet, ISkillProficiency } from "../skills/skill.interface";
import { IConferenceBase } from "./conference.base.interface";
import { UUID } from "../util/uuid";
import { ISharedFileRef } from "companion/companion";

export class ConferenceUtil {
  static OpChatRoomPrefix = "room-op-chat-";
  static SpChatRoomPrefix = "room-sp-chat-";

  static OpWaitRoomPrefix = "room-op-wait-";
  static SpWaitRoomPrefix = "room-sp-wait-";

  static GuestWaitRoomPrefix = "room-guests-wait-";
  static conferenceRoomPrefix = "conference-";
  static monitorRoomPrefix = "montor-";
  static defaultRoomName = "DEFAULT";

  /**
   * get connection string of room
   * @param room: IConference - room to generate connection string
   */
  static getConnectionString(room: IConferenceBase, skillTags?: ISkillTags): string {
    switch (room.roomType) {
      case RoomType.OpChatRoom:
        return ConferenceUtil.OpChatRoomPrefix + Hash.hashTextToHEX(room.ownerId).substr(0, 8);
      case RoomType.OpWaitRoom:
        return ConferenceUtil.OpWaitRoomPrefix + Hash.hashTextToHEX(room.ownerId).substr(0, 8);
      case RoomType.SpChatRoom:
        return ConferenceUtil.SpChatRoomPrefix + Hash.hashTextToHEX(room.ownerId).substr(0, 8);
      case RoomType.SpWaitRoom:
        return ConferenceUtil.SpWaitRoomPrefix + Hash.hashTextToHEX(room.ownerId).substr(0, 8);
      case RoomType.GuestWaitRoom:
        return ConferenceUtil.GuestWaitRoomPrefix + this.getQueueName(room.groupId, skillTags);
      case RoomType.Conference:
        return ConferenceUtil.conferenceRoomPrefix + room.groupId + "-" + room.id;
      case RoomType.Monitor:
        return ConferenceUtil.monitorRoomPrefix + room.groupId + "-" + room.id;
      case RoomType.Default:
      default:
        return ConferenceUtil.defaultRoomName;
    }
  }

  static getIdFromNameString(roomType: RoomType, roomName: string): string {
    let preceedingPrefixCount = 0;
    switch (roomType) {
      case RoomType.OpChatRoom:
        preceedingPrefixCount = ConferenceUtil.OpChatRoomPrefix.split("-").length - 1;
        break;
      case RoomType.OpWaitRoom:
        preceedingPrefixCount = ConferenceUtil.OpWaitRoomPrefix.split("-").length - 1;
        break;
      case RoomType.SpChatRoom:
        preceedingPrefixCount = ConferenceUtil.SpChatRoomPrefix.split("-").length - 1;
        break;
      case RoomType.SpWaitRoom:
        preceedingPrefixCount = ConferenceUtil.SpWaitRoomPrefix.split("-").length - 1;
        break;
      case RoomType.GuestWaitRoom:
        preceedingPrefixCount = ConferenceUtil.GuestWaitRoomPrefix.split("-").length - 1;
        break;
      case RoomType.Conference:
        preceedingPrefixCount = ConferenceUtil.conferenceRoomPrefix.split("-").length - 1;
        break;
      case RoomType.Monitor:
        preceedingPrefixCount = ConferenceUtil.monitorRoomPrefix.split("-").length - 1;
        break;
      case RoomType.Default:
      default:
        // do nothing.
    }
    if (preceedingPrefixCount) {
      return roomName.split("-")[preceedingPrefixCount];
    } else {
      return null;
    }
  }

  static getQueueName(groupId: string, skillTags?: ISkillTags): string {
    let queueName: string = groupId;
    if (!skillTags || (!skillTags?.category && !skillTags?.language)) {
      queueName += "-GENERIC";
    } else {

      if (skillTags.category) {
        queueName += "-" + skillTags.category;
      }
      if (skillTags.language) {
        queueName += "-" + skillTags.language;
      }
    }
    return queueName;
  }

  /**
   * Get the skill tags from the queue name
   * queue name is groupId-Category-Lanaguage
   */
  static getSkillTagsFromQueueRoomName(queueRoomName: string) : ISkillTags
  {
    // Only works on GuestWaitRoom
    if (queueRoomName.startsWith(ConferenceUtil.GuestWaitRoomPrefix)) {
      // option1 (room, guests, wait, groupid, queuecat, queueLang)
      // option2 (room, guests, wait, groupid, generic)
      let nameSplit = queueRoomName.split("-");
      
      if(nameSplit.length < 6 || nameSplit[4] === "GENERIC")
      {
        return null; // No skill tags from GENERIC
      }

      return { category : nameSplit[4], language: nameSplit[5]};
    } else {
      return null;
    }
  }

  static includesVoiceEndpoint(endpoints: IEndpoint[]) {
    return endpoints && _.some(endpoints, (ep) => {
      return ep.isVoiceEp;
    })
  }

  static includesVoiceEndpointRef(endpoints: IEndpointRef[]) {
    return endpoints && _.some(endpoints, (ep) => {
      return ep.isVoiceEp;
    })
  }

  static isVoiceConferenceRef(conference: IActiveConference) {
    return this.includesVoiceEndpointRef(conference?.active);
  }

  static isVoiceConference(conference: IExpandedActiveConference) {
    return this.includesVoiceEndpoint(conference?.active);
  }

  
  static isVoiceOnlyConference(conference: IExpandedActiveConference, excludedRtcIds:string[] = []): boolean {
    let voice = ConferenceUtil.isVoiceConference(conference);

    if (voice) {
      let nonVoiceTarget = false;
      conference.active.forEach((ep) => {
        if (!ep.isVoiceEp && !excludedRtcIds.includes(ep.rtcId)) {
          nonVoiceTarget = true;
        }
      });
      // Do we not have non-voices but are connected and don't have a master?
      return !nonVoiceTarget;
    }
    return false;
  }

  static isDialOutRinging(conference: IExpandedActiveConference): boolean {
    return ConferenceUtil.isDialOutRingingAmong(conference.active);
  }

  static isDialOutRingingAmong(eps: Readonly<IEndpoint>[]) {
    return eps.some(ep => ep.isVoiceEp && ep.status === PresenceStatus.ringing);
  }

  static newConferenceData(roomType: RoomType, data?:IConferenceBase) : IQueueConference | IActiveConference | IMonitorConference {
    let groupCheck = data?.groupId;
    if (!groupCheck) {
      if (!data?.name) {
        groupCheck = null;
      } else if (data.name.startsWith(ConferenceUtil.GuestWaitRoomPrefix)) { // only fetch group from queue rooms
        groupCheck = this.getIdFromNameString(roomType, data?.name);
      }
    }
    let base: IConferenceBase = {
      groupId: groupCheck,
      id: data?.id || UUID.UUID(),
      lastUpdateTimestamp: new Date(),
      name: data?.name,
      ownerName: data?.ownerName,
      ownerId: data?.ownerId,
      parentId: data?.parentId,
      roomType: roomType
    }
    switch(roomType) {
      case RoomType.Conference:
      case RoomType.Default:
      case RoomType.OpChatRoom:
      case RoomType.SpChatRoom:
        return {...base, chatRooms: [], active: [], held: [], recorderRtcIds: []} as IActiveConference;
      case RoomType.Monitor:
        return {...base, chatRooms: [], subjects: [], observers: []} as IMonitorConference;
      default:
        return {...base, chatRooms: [], publicWaitList: [], operators: [], reps: []} as IQueueConference;
    }
  }

  /**
   * Construct a new reference object from the base data of the differnet types.
   */
  static newConferenceInstance(data: IConferenceRef): QueueConference | ActiveConference | IMonitorConference {
    switch(data?.roomType) {
      case RoomType.Conference:
      case RoomType.Default:
      case RoomType.OpChatRoom:
      case RoomType.SpChatRoom:
        return new ActiveConference(data as IActiveConference);
      case RoomType.Monitor:
        return new MonitorConference(data as IMonitorConference);
      default:
        return new QueueConference(data as IQueueConference);
    }
  }

  static getCategoriesListFromCategorySubskills(categorySubskills: ICategory[]): string[] {
    let categoriesList: string[] = [];
    try {
      if (categorySubskills) {
        _.forEach(categorySubskills, (childCategory: ICategory) => {
          let categoryToList: string[] = [];
          categoryToList = this.getCategoryListFromCategory(childCategory);
          categoriesList.push(...categoryToList);
        });
      }
    }
    catch (err) {
      console.error("getCategoriesListFromCategorySubskills error", err);
    };
    return categoriesList;
  }

  static getCategoryListFromCategory(category: ICategory): string[] {
    let categoryList: string[] = [];
    try {
      categoryList.push(category.name);
      if (category.subskills && !_.isEmpty(category.subskills)) {
        _.forEach(category.subskills, (childCategory: ICategory) => {
          let childCategoryList: string[] = [];
          childCategoryList = this.getCategoryListFromCategory(childCategory);
          categoryList.push(...childCategoryList);
        });
      }
    }
    catch (err) {
      console.error("getCategoryListFromCategory error", err);
    };
    return categoryList;
  }

  static getTransferrableCategoriesList(categorySubskills: ICategory[], opCategoryList: ISkillProficiency[], endpointCategory: string): ISkillProficiency[] {
    let categoriesList: ISkillProficiency[] = [];
    try {
      if (categorySubskills) {
        _.forEach(categorySubskills, (childCategory: ICategory) => {
          let categoryToList: ISkillProficiency[] = [];
          // Always allow top level category
          categoryToList = this.getTransferrableCategoryList(childCategory, opCategoryList, endpointCategory, true);
          categoriesList.push(...categoryToList);
        });
      }
    }
    catch (err) {
      console.error("getTransferrableCategoriesList error", err);
    };
    return categoriesList;
  }

  static getTransferrableCategoryList(category: ICategory, opCategoryList: ISkillProficiency[], endpointCategory: string, includeInList: boolean): ISkillProficiency[] {
    let categoryList: ISkillProficiency[] = [];
    try {
      if (includeInList) {
        // use default level of 1 as place holder even though this proficiencyLevel is not used when displaying the list of categories that can be transferred to
        categoryList.push({ skill: category.name, proficiencyLevel: 1 });
      }
      if (category.subskills && !_.isEmpty(category.subskills)) {
        let includeChildInList: boolean = false;
        if (opCategoryList && ConferenceUtil.getCategoryListFromSkillProficiencyList(opCategoryList).includes(category.name)) {
          // If agent/op supports this category, he is allowed to transfer the kiosk/caller to any of the subskills under this category
          includeChildInList = true;
        }
        _.forEach(category.subskills, (childCategory: ICategory) => {
          if (endpointCategory && endpointCategory == childCategory.name) {
            // If kiosk/caller endpoint category is one of the subskills, then we allow it to be transferred to its sibling subskills
            includeChildInList = true;
          }
        });
        _.forEach(category.subskills, (childCategory: ICategory) => {
          let childCategoryList: ISkillProficiency[] = [];
          childCategoryList = this.getTransferrableCategoryList(childCategory, opCategoryList, endpointCategory, includeChildInList);
          categoryList.push(...childCategoryList);
        });
      }
    }
    catch (err) {
      console.error("getTransferrableCategoryList error", err);
    };
    return categoryList;
  }

  static getCategoryListFromSkillProficiencyList(skillProfiencies: ISkillProficiency[]): string[] {
    let categoriesList: string[] = [];
    try {
      if (skillProfiencies) {
        categoriesList = skillProfiencies.map(skillProficiency => skillProficiency.skill);
      }
    }
    catch (err) {
      console.error("getCategoryListFromSkillProficiencyList error", err);
    };
    return categoriesList;
  }

  static getSkillSetCategoryToString(skillSet: ISkillSet): string {
    let categoryString: string = "";
    if (skillSet?.categories) {
      if (skillSet?.categories.length > 0) {
        skillSet?.categories.forEach((skillProficiency: ISkillProficiency) => {
          categoryString = categoryString + "{" + skillProficiency.skill + ", " + skillProficiency.proficiencyLevel + "}, "
        });
        categoryString = categoryString.substring(0, categoryString.length - 2);
      }
    }
    return categoryString;
  }

  static getSkillSetLanguageToString(skillSet: ISkillSet): string {
    return (skillSet && skillSet?.languages) ? skillSet?.languages?.join(",") : "";
  }

}

export class Conference implements IConferenceBase {
  groupId?: string;
  id?: any;
  lastUpdateTimestamp?: Date;
  name?: string;
  ownerId?: string;
  ownerName?: string;
  parentId?: number;
  roomType?: RoomType = RoomType.Default;
  chatRooms?: string[];

  constructor(data?: IConferenceBase) {
      this.groupId = data?.groupId;
      this.id = data?.id || UUID.UUID();
      this.lastUpdateTimestamp = data?.lastUpdateTimestamp || new Date();
      this.name = data?.name;
      this.ownerId = data?.ownerId;
      this.ownerName = data?.ownerName;
      this.parentId = data?.parentId;
      this.roomType = data?.roomType || RoomType.Default;
      this.chatRooms = data?.chatRooms || [] as string[];
  }

}

export class ActiveConference extends Conference implements IActiveConference {
  active: IEndpointRef[];
  held: IEndpointRef[];
  recorderRtcIds: string[];
  filesOffered: ISharedFileRef[];

  constructor(data?: IActiveConference) {
    super(data);
    this.active = data?.active || [] as IEndpointRef[];
    this.held = data?.held || [] as IEndpointRef[];
    this.recorderRtcIds = data?.recorderRtcIds || [] as string[];
    this.filesOffered = data?.filesOffered || [] as ISharedFileRef[];
  }

  get everyone(): IEndpointRef[] {
    return _.concat(this.active, this.held);
  }
}

export class MonitorConference extends Conference implements IMonitorConference {
  subjects: IEndpointRef[];
  observers: IEndpointRef[];

  constructor(data?: IMonitorConference) {
    super(data);
    this.subjects = data?.subjects || [] as IEndpointRef[];
    this.observers = data?.observers || [] as IEndpointRef[];
  }

  get everyone(): IEndpointRef[] {
    return _.concat(this.subjects, this.observers);
  }
}

export class QueueConference extends Conference implements IQueueConference {
  publicWaitList?: IEndpointRef[];
  operators?: IEndpointRef[];
  reps?: IEndpointRef[];

  constructor(data?: IQueueConference) {
    super(data);
    this.publicWaitList = data?.publicWaitList || [] as IEndpointRef[];
    this.operators = data?.operators || [] as IEndpointRef[];
    this.reps = data?.reps || [] as IEndpointRef[];
  }

  get everyone(): IEndpointRef[] {
    return _.concat(this.publicWaitList, this.operators, this.reps);
  }
}

export class ExpandedActiveConference extends Conference implements IExpandedActiveConference {
  active: IEndpoint[];
  held: IEndpoint[];
  recorderRtcIds: string[];
  filesOffered: ISharedFileRef[];
  constructor(data?: IConferenceBase, options?: {active?: IEndpoint[], held?: IEndpoint[], recorderRtcIds?: string[], filesOffered?: ISharedFileRef[]}) {
    super(data);
    this.active = options?.active || [] as IEndpoint[];
    this.held = options?.held || [] as IEndpoint[];
    this.recorderRtcIds = options?.recorderRtcIds || [] as string[];
    this.filesOffered = options?.filesOffered || [] as ISharedFileRef[];
  }

  get everyone(): IEndpoint[] {
    return _.concat(this.active, this.held);
  }

  toRef(): ActiveConference {
    let ref: ActiveConference = new ActiveConference(ConferenceUtil.newConferenceData(this.roomType, this as IConferenceBase) as IActiveConference);
    ref.active = _.map(this.active, (ep) => {return Endpoint.toRef(ep)});
    ref.held = _.map(this.held, (ep) => {return Endpoint.toRef(ep)});
    ref.recorderRtcIds = this.recorderRtcIds;
    ref.filesOffered = _.clone(this.filesOffered);
    return ref;
  }
}

export class ExpandedMonitorConference extends Conference implements IExpandedMonitorConference {
  subjects: IEndpoint[];
  observers: IEndpoint[];
  
  constructor(data?: IConferenceBase, options?: {subjects?: IEndpoint[], observers?: IEndpoint[]}) {
    super(data);
    this.subjects = options?.subjects || [] as IEndpoint[];
    this.observers = options?.observers || [] as IEndpoint[];
  }

  get everyone(): IEndpoint[] {
    return _.concat(this.subjects, this.observers);
  }

  toRef(): MonitorConference {
    let ref: MonitorConference = new MonitorConference(ConferenceUtil.newConferenceData(this.roomType, this as IConferenceBase) as IMonitorConference);
    ref.subjects = _.map(this.subjects, (ep) => {return Endpoint.toRef(ep)});
    ref.observers = _.map(this.observers, (ep) => {return Endpoint.toRef(ep)});
    return ref;
  }
}

export class ExpandedQueueConference extends Conference implements IExpandedQueueConference {
  publicWaitList?: IEndpoint[];
  operators?: IEndpoint[];
  reps?: IEndpoint[];

  constructor(data?: IConferenceBase, options?: {publicWaitList?: IEndpoint[], operators?: IEndpoint[], reps?: IEndpoint[]}) {
    super(data);
    this.publicWaitList = options?.publicWaitList || [] as IEndpoint[];
    this.operators = options?.operators || [] as IEndpoint[];
    this.reps = options?.reps || [] as IEndpoint[];
  }
  

  get everyone(): IEndpoint[] {
    return _.concat(this.publicWaitList, this.operators, this.reps);
  }

  toRef(): QueueConference {
    let ref: QueueConference = new QueueConference(ConferenceUtil.newConferenceData(this.roomType, this as IConferenceBase) as IQueueConference);
    ref.publicWaitList = _.map(this.publicWaitList, (ep) => {return Endpoint.toRef(ep)});
    ref.operators = _.map(this.operators, (ep) => {return Endpoint.toRef(ep)});
    ref.reps = _.map(this.reps, (ep) => {return Endpoint.toRef(ep)});

    return ref;
  }
}
