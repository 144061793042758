/**
 * Copyright Compunetix Incorporated 2017-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { IParameter, ParameterType, Group } from "companion";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup, AbstractControl, FormArray, Validators } from "@angular/forms";
import { format } from "date-fns";

@Injectable()
/**
 * config methods delegate
 */
export class FormService {
  static collectParameterValues(parameters: { [key: string]: IParameter }): { [key: string]: any } {
    let parameterValues: { [key: string]: any } = {};
    parameterValues = _.mapValues(parameters, (parameter: IParameter) => {
      if (parameter.type === ParameterType[ParameterType.date]) {
        return format(parameter.value, "yyyy-MM-dd");
      } else {
        return parameter.value;
      }
    });
    return parameterValues;
  }

  /**
   * create form group
   */
  static toFormGroup(parameters: { [key: string]: IParameter }): FormGroup {
    let sections: { [sectionKey: string]: IParameter[] } = {};
    let allParameterKeys: string[] = _.keys(parameters);
    let sectionParameterKeys: string[] = _.filter(allParameterKeys, (key: string) => {
      return _.endsWith(key, "_enabled");
    });
    let sectionKeys: string[] = _.map(sectionParameterKeys, (key: string) => {
      return key.substring(0, key.lastIndexOf("_enabled"));
    });
    _.forOwn(parameters, (parameter: IParameter) => {
      let sectionKey: string = _.find(sectionKeys, (key: string) => {
        return _.startsWith(parameter.key, key);
      });
      sectionKey = sectionKey || parameter.key;
      sections[sectionKey] = sections[sectionKey] || [];
      sections[sectionKey].push(parameter);
    });
    let groupControls: { [key: string]: FormGroup } = {};
    _.forOwn(sections, (section_parameters: IParameter[], sectionKey: string) => {
      let sectionControls: { [key: string]: AbstractControl } = {};
      _.forEach(section_parameters, (parameter: IParameter) => {
        // construct validators
        let validators = [];
        if (parameter.type === ParameterType[ParameterType.email]) {
          validators.push(Validators.email);
        }
        if (parameter.required) {
          validators.push(Validators.required);
        }
        if (parameter.maxLength != null) {
          validators.push(Validators.maxLength(parameter.maxLength));
        }
        // construct Form Controls
        if (
          parameter.type === ParameterType[ParameterType.list] ||
          parameter.type === ParameterType[ParameterType.auto_complete_list]
        ) {
          let listControls: AbstractControl[] = [];
          if (parameter.value != null) {
            _.forEach(parameter.value, (v: string) => {
              listControls.push(new FormControl(v || "", validators));
            });
          }
          sectionControls[parameter.key] = new FormArray(listControls);
        } else if (
          parameter.type === ParameterType[ParameterType.key_value_list] ||
          parameter.type === ParameterType[ParameterType.checkbox_list]
        ) {
          let keyValueGroups: FormGroup[] = [];
          if (parameter.value != null) {
            _.forOwn(parameter.value, (v: string, k: string) => {
              let keyValueControls: { [key: string]: AbstractControl } = {};
              keyValueControls["key"] = new FormControl(k || "", validators);
              keyValueControls["value"] = new FormControl({value: v || "", disabled: parameter.disabled}, validators);
              keyValueGroups.push(new FormGroup(keyValueControls));
            });
          }
          sectionControls[parameter.key] = new FormArray(keyValueGroups);
        } else if (parameter.type === ParameterType[ParameterType.true_false_value]) {
          let trueFalseValueControls: { [key: string]: AbstractControl } = {};
          trueFalseValueControls["trueValue"] = new FormControl(parameter.value ? parameter.value.trueValue || "" : "", validators);
          trueFalseValueControls["falseValue"] = new FormControl(
            parameter.value ? parameter.value.falseValue || "" : "", validators
          );
          sectionControls[parameter.key] = new FormGroup(trueFalseValueControls);
        } else if (parameter.type === ParameterType[ParameterType.account]) {
          let accountControls: { [key: string]: AbstractControl } = {};
          accountControls["host"] = new FormControl(parameter.value ? parameter.value.host || "" : "", validators);
          accountControls["username"] = new FormControl(parameter.value ? parameter.value.username || "" : "", validators);
          accountControls["password"] = new FormControl(parameter.value ? parameter.value.password || "" : "", validators);
          sectionControls[parameter.key] = new FormGroup(accountControls);
        } else if (parameter.type === ParameterType[ParameterType.size]) {
          let sizeControls: { [key: string]: AbstractControl } = {};
          sizeControls["width"] = new FormControl(parameter.value ? parameter.value.width || "" : "", validators);
          sizeControls["height"] = new FormControl(parameter.value ? parameter.value.height || "" : "", validators);
          sectionControls[parameter.key] = new FormGroup(sizeControls);
        } else if (parameter.type === ParameterType[ParameterType.account_list]) {
          let listControls: AbstractControl[] = [];
          if (parameter.value != null) {
            _.forEach(parameter.value, (v: any) => {
              let accountControls: { [key: string]: AbstractControl } = {};
              accountControls["host"] = new FormControl(v ? v.host || "" : "", validators);
              accountControls["username"] = new FormControl(v ? v.username || "" : "", validators);
              accountControls["password"] = new FormControl(v ? v.password || "" : "", validators);
              accountControls["authSecret"] = new FormControl(v ? v.authSecret || "" : "", validators);
              listControls.push(new FormGroup(accountControls));
            });
          }
          sectionControls[parameter.key] = new FormArray(listControls);
        } else if (parameter.type === ParameterType[ParameterType.third_party_auth_list]) {
          let listControls: AbstractControl[] = [];
          if (parameter.value != null) {
            _.forEach(parameter.value, (v: any) => {
              let thirdPartyAuthControls: { [key: string]: AbstractControl } = {};
              _.forEach(ThirdPartyAuthControlKey, (value: any, key: any) => {
                if (_.isNumber(value)) {
                  return;
                }
                thirdPartyAuthControls[value] = new FormControl({
                  value: v ? v[value] || "" : "",
                  disabled: parameter.disabled,
                }, validators);
              });
              listControls.push(new FormGroup(thirdPartyAuthControls));
            });
          }
          sectionControls[parameter.key] = new FormArray(listControls);
        } else if (parameter.type === ParameterType[ParameterType.size_list]) {
          let listControls: AbstractControl[] = [];
          if (parameter.value != null) {
            _.forEach(parameter.value, (v: any) => {
              let sizeControls: { [key: string]: AbstractControl } = {};
              sizeControls["width"] = new FormControl(v ? v.width || "" : "", validators);
              sizeControls["height"] = new FormControl(v ? v.height || "" : "", validators);
              listControls.push(new FormGroup(sizeControls));
            });
          }
          sectionControls[parameter.key] = new FormArray(listControls);
        } else if (parameter.type === ParameterType[ParameterType.queue_screen_list]) {
          let listControls: AbstractControl[] = [];
          if (parameter.value != null) {
            _.forEach(parameter.value, (v: any) => {
              let queueControls: { [key: string]: AbstractControl } = {};
              queueControls["langCode"] = new FormControl(v ? v.langCode || "" : "", validators);
              queueControls["languageText"] = new FormControl(v ? v.languageText || "" : "", validators);
              queueControls["categoryText"] = new FormControl(v ? v.categoryText || "" : "", validators);
              queueControls["startButtonText"] = new FormControl(v ? v.startButtonText || "" : "", validators);
              queueControls["queueCat"] = new FormControl(v ? v.queueCat || "" : "", validators);
              queueControls["queueLang"] = new FormControl(v ? v.queueLang || "" : "", validators);
              listControls.push(new FormGroup(queueControls));
            });
          }
          sectionControls[parameter.key] = new FormArray(listControls);
        } else if (parameter.type === ParameterType[ParameterType.remote_desktop_conn_list]) {
          let listControls: AbstractControl[] = [];
          if (parameter.value != null) {
            _.forEach(parameter.value, (v: any) => {
              let remoteDesktopControls: { [key: string]: AbstractControl } = {};
              remoteDesktopControls["remoteIdentity"] = new FormControl(v ? v.remoteIdentity || "" : "", validators);
              remoteDesktopControls["hostname"] = new FormControl(v ? v.hostname || "" : "", validators);
              remoteDesktopControls["port"] = new FormControl(v ? v.port || "" : "", validators);
              remoteDesktopControls["username"] = new FormControl(v ? v.username || "" : "", validators);
              remoteDesktopControls["password"] = new FormControl(v ? v.password || "" : "", validators);
              listControls.push(new FormGroup(remoteDesktopControls));
            });
          }
          sectionControls[parameter.key] = new FormArray(listControls);
        }
        else if (parameter.type === ParameterType[ParameterType.checkbox]) {
          sectionControls[parameter.key] = new FormControl(parameter.value || false, validators);
        } else {
          sectionControls[parameter.key] = new FormControl(parameter.value != null ? parameter.value : "", validators);
        }
      });
      
      groupControls[sectionKey] = new FormGroup(sectionControls);
    });
    return new FormGroup(groupControls);
  }

  /**
   * update value by Form values
   */
  static updateParametersByForm(form: FormGroup, parameters: { [key: string]: IParameter }): void {
    _.forOwn(form.value, (sectionData: any, sectionKey: string) => {
      _.forOwn(sectionData, (value: any, key: string) => {
        if (!parameters[key]) {
          return;
        }
        if (
          value != null &&
          (parameters[key].type === ParameterType[ParameterType.list] ||
            parameters[key].type === ParameterType[ParameterType.auto_complete_list])
        ) {
          _.remove(value, (v: string) => {
            return !v || v.length === 0;
          });
          parameters[key].value = value;
        } else if (
          value != null &&
          (parameters[key].type === ParameterType[ParameterType.key_value_list] ||
            parameters[key].type === ParameterType[ParameterType.checkbox_list])
        ) {
          _.remove(value, (keyValuePair: { key: string; value?: string }) => {
            return !keyValuePair.key || keyValuePair.key.length === 0;
          });
          parameters[key].value = {};
          _.forEach(value, (keyValuePair: { key: string; value?: string }) => {
            parameters[key].value[keyValuePair.key] = keyValuePair.value;
          });
        } else if (value != null && parameters[key].type === ParameterType[ParameterType.true_false_value]) {
          _.forOwn(value, (v: string, k: string) => {
            parameters[key].value = parameters[key].value || {};
            parameters[key].value[k] = v;
          });
        } else if (value != null && parameters[key].type === ParameterType[ParameterType.third_party_auth_list]) {
          _.forEach(value, (authAccount: any) => {
            authAccount["callbackUrl"] = Group.generateThirdPartyCallbackUrl(authAccount, parameters[key]["groupId"]);
          });
          parameters[key].value = value;
        } else if (value != null && parameters[key].type === ParameterType[ParameterType.remote_desktop_conn_list]) {
          parameters[key].value = value;
        } else if (value != null && parameters[key].type === ParameterType[ParameterType.category_subskills_new]) {
          parameters[key].value = value;
        } else {
          parameters[key].value = value;
        }
      });
    });
  }

  /**
   * update form value by JSON
   */
  static updateFormValueByJSON(form: FormGroup, parameters: { [key: string]: IParameter }, newFormValue: any): void {
    let sections: { [sectionKey: string]: IParameter[] } = {};
    let allParameterKeys: string[] = _.keys(parameters);
    let sectionParameterKeys: string[] = _.filter(allParameterKeys, (key: string) => {
      return _.endsWith(key, "_enabled");
    });
    let sectionKeys: string[] = _.map(sectionParameterKeys, (key: string) => {
      return key.substring(0, key.lastIndexOf("_enabled"));
    });
    _.forOwn(parameters, (parameter: IParameter) => {
      let sectionKey: string = _.find(sectionKeys, (key: string) => {
        return _.startsWith(parameter.key, key);
      });
      sectionKey = sectionKey || parameter.key;
      sections[sectionKey] = sections[sectionKey] || [];
      sections[sectionKey].push(parameter);
    });
    _.forOwn(sections, (section_parameters: IParameter[], sectionKey: string) => {
      let formGroup: FormGroup = form.controls[sectionKey] as FormGroup;
      _.forEach(section_parameters, (parameter: IParameter) => {
        let formArray: FormArray = formGroup.controls[parameter.key] as FormArray;
        if (
          parameter.type === ParameterType[ParameterType.list] ||
          parameter.type === ParameterType[ParameterType.auto_complete_list]
        ) {
          formArray.controls = [];
          if (newFormValue[sectionKey][parameter.key]) {
            for (var i = 0; i < newFormValue[sectionKey][parameter.key].length; ++i) {
              formArray.controls.push(new FormControl(""));
            }
          }
        } else if (
          parameter.type === ParameterType[ParameterType.key_value_list] ||
          parameter.type === ParameterType[ParameterType.checkbox_list]
        ) {
          formArray.controls = [];
          if (newFormValue[sectionKey][parameter.key]) {
            for (var i = 0; i < newFormValue[sectionKey][parameter.key].length; ++i) {
              let keyValueControls: { [key: string]: AbstractControl } = {};
              keyValueControls["key"] = new FormControl("");
              keyValueControls["value"] = new FormControl("");
              formArray.controls.push(new FormGroup(keyValueControls));
            }
          }
        } else if (parameter.type === ParameterType[ParameterType.account_list]) {
          formArray.controls = [];
          if (newFormValue[sectionKey][parameter.key]) {
            for (var i = 0; i < newFormValue[sectionKey][parameter.key].length; ++i) {
              let accountControls: { [key: string]: AbstractControl } = {};
              accountControls["host"] = new FormControl("");
              accountControls["username"] = new FormControl("");
              accountControls["password"] = new FormControl("");
              accountControls["authSecret"] = new FormControl("");
              formArray.controls.push(new FormGroup(accountControls));
            }
          }
        } else if (parameter.type === ParameterType[ParameterType.third_party_auth_list]) {
          formArray.controls = [];
          if (newFormValue[sectionKey][parameter.key]) {
            for (var i = 0; i < newFormValue[sectionKey][parameter.key].length; ++i) {
              let thirdPartyAuthControls: { [key: string]: AbstractControl } = {};
              _.forEach(ThirdPartyAuthControlKey, (value: any, key: any) => {
                if (_.isNumber(value)) {
                  return;
                }
                thirdPartyAuthControls[value] = new FormControl("");
              });
              formArray.controls.push(new FormGroup(thirdPartyAuthControls));
            }
          }
        } else if (parameter.type === ParameterType[ParameterType.size_list]) {
          formArray.controls = [];
          if (newFormValue[sectionKey][parameter.key]) {
            for (var i = 0; i < newFormValue[sectionKey][parameter.key].length; ++i) {
              let sizeControls: { [key: string]: AbstractControl } = {};
              sizeControls["width"] = new FormControl("");
              sizeControls["height"] = new FormControl("");
              formArray.controls.push(new FormGroup(sizeControls));
            }
          }
        } else if (parameter.type === ParameterType[ParameterType.queue_screen_list]) {
          formArray.controls = [];
          if (newFormValue[sectionKey][parameter.key]) {
            for (var i = 0; i < newFormValue[sectionKey][parameter.key].length; ++i) {
              let queueControls: { [key: string]: AbstractControl } = {};
              queueControls["langCode"] = new FormControl("");
              queueControls["languageText"] = new FormControl("");
              queueControls["categoryText"] = new FormControl("");
              queueControls["startButtonText"] = new FormControl("");
              queueControls["queueCat"] = new FormControl("");
              queueControls["queueLang"] = new FormControl("");
              formArray.controls.push(new FormGroup(queueControls));
            }
          }
        } else if (parameter.type === ParameterType[ParameterType.hidden]) {
          newFormValue[sectionKey][parameter.key] = form.value[sectionKey][parameter.key];
        }
      });
    });
    form.setValue(newFormValue);
  }
}

enum ThirdPartyAuthControlKey {
  protocol,
  provider,
  clientID,
  authorizationUrl,
  issuer,
  tokenUrl,
  // loginUrl,
  // logoutUrl,
  // cert,
  // signatureAlgorithm,
  secret,
  groupId,
  defaultUrl,
}
