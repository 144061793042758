<!--
    Copyright Compunetix Incorporated 2016-2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
  -->
<!--USER CONTROL  -->
<div class="conference-settings">
  <!--USER CONTROL-->
  <div id="user-control" class="user-control">
    <div class="text-center">
      <button
        tabindex
        class="btn btn-link text-default"
        [attr.aria-label]="audioButtonAriaLabel"
        (click)="audioButtonFunction()"
      >
        <i
          class="{{audioIconClass}} icon-large"
          aria-hidden="true"
        ></i>
        <br>
        <label>
          {{audioButtonText}}
        </label>
      </button>
    </div>
    <div class="text-center">
      <button
        tabindex
        class="btn btn-link text-default"
        [attr.aria-label]="videoButtonAriaLabel"
        (click)="videoButtonFunction()"
      >
        <i
          class="{{videoIconClass}} icon-large"
          aria-hidden="true"
        ></i>
        <br>
        <label>
          {{videoButtonText}}
        </label>
      </button>
    </div>
  </div>

  <!--MEDIA DEVICE SETTINGS  -->
  <div id="media-device-settings" class="media-device-settings">
    <div>
        <span
          dropdown
          class="full-width"
        >
          <button
            tabindex="0"
            aria-label="Dropdown List for Microphone Device Selection"
            class="btn btn-primary full-width dropdown-toggle testing-button"
            id="mic-dropdown"
            dropdownToggle
          >
            <i
              class="fas fa-microphone icon-large"
              aria-hidden="true"
            ></i>
            <label
              class="cursor-pointer"
              *ngIf="rtcService.rtcClient.selectedMicOption"
            >
              {{rtcService.rtcClient.selectedMicOption.label | deviceNameTranslate:
              localizationService.myLocalizationData.language}}
            </label>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu bg-white full-width"
            aria-labelledby="mic-dropdown"
          >
            <li *ngFor="let micOption of deviceService.micOptions">
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectDevice('mic',micOption)"
              >
                {{micOption.label | deviceNameTranslate: localizationService.myLocalizationData.language}}
              </a>
            </li>
          </ul>
        </span>
      <span class="full-width testing" *ngIf="!isMobileApp">
          <button
            tabindex="0"
            *ngIf="!micStartTesting"
            class="btn btn-small btn-default"
            aria-label="Start Microphone Testing Button"
            (click)="toggleMicTesting()"
          >
            {{localizationService.myLocalizationData.settings_panel.microphone_setting.startTestingText}}
            <i class="far fa-play-circle"></i>
          </button>
          <button
            tabindex="0"
            *ngIf="micStartTesting"
            class="btn btn-small btn-default"
            aria-label="Stop Microphone Testing Button"
            (click)="toggleMicTesting()"
          >
            {{localizationService.myLocalizationData.settings_panel.microphone_setting.endTestingText}}
            <i class="far fa-stop-circle"></i>
          </button>
          <div class="float-right">
            <volume-meter
              *ngIf="micStartTesting"
              [id]="mic-volume-meter"
              [height]='5'
              [stream]='rtcService.rtcClient.cameraStream'
            ></volume-meter>
          </div>
        </span>
    </div>
    <div>
        <span
          dropdown
          class="full-width"
        >
          <button
            tabindex="0"
            aria-label="Dropdown List for Camera Device Selection"
            class="btn btn-primary full-width dropdown-toggle testing-button"
            id="camera-dropdown"
            dropdownToggle
          >
            <i
              class="fas fa-video icon-large"
              aria-hidden="true"
            ></i>
            <label
              class="cursor-pointer"
              *ngIf="rtcService.rtcClient.selectedPrimaryCameraOption"
            >
              {{rtcService.rtcClient.selectedPrimaryCameraOption.label | deviceNameTranslate:
              localizationService.myLocalizationData.language}}
            </label>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu bg-white full-width"
            aria-labelledby="camera-dropdown"
          >
            <li *ngFor="let cameraOption of deviceService.cameraOptions">
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectDevice('camera',cameraOption)"
              >
                {{cameraOption.label | deviceNameTranslate: localizationService.myLocalizationData.language}}
              </a>
            </li>
          </ul>
        </span>
      <span class="full-width secondary-camera" [hidden]="true">
          <button
            tabindex="0"
            class="btn btn-small btn-default"
            (click)="addSecondaryCamera()"
          >
            {{localizationService.myLocalizationData.settings_panel.addSecondaryCameraText || "Add Secondary Camera Source"}}
          </button>
        </span>
      <span
        class="full-width"
        [hidden]="!rtcService.rtcClient.secondaryCameraEnabled"
      >
          <button
            tabindex
            class="btn btn-small btn-default"
            (click)="removeSecondaryCamera()"
          >
            {{localizationService.myLocalizationData.settings_panel.removeSecondaryCameraText || "Remove Secondary Camera Source"}}
          </button>
        </span>
      <span
        dropdown
        class="full-width"
        *ngIf="rtcService.rtcClient.secondaryCameraEnabled"
      >
          <button
            tabindex="0"
            aria-label="Dropdown List for the second camera device selection"
            class="btn btn-primary full-width dropdown-toggle testing-button"
            id="secondary-camera-dropdown"
            dropdownToggle
          >
            <i
              class="fas fa-video icon-large"
              aria-hidden="true"
            ></i>
            <label
              class="cursor-pointer"
              *ngIf="rtcService.rtcClient.selectedSecondaryCameraOption"
            >
              {{rtcService.rtcClient.selectedSecondaryCameraOption.label | deviceNameTranslate:
              localizationService.myLocalizationData.language}}
            </label>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu bg-white full-width"
            aria-labelledby="secondary-camera-dropdown"
          >
            <li *ngFor="let cameraOption of deviceService.cameraOptions">
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectDevice('secondary-camera',cameraOption)"
              >
                {{cameraOption.label | deviceNameTranslate: localizationService.myLocalizationData.language}}
              </a>
            </li>
          </ul>
        </span>
      <span class="full-width testing"  *ngIf="!isMobileApp">
          <button
            tabindex="0"
            class="btn btn-small btn-default"
            aria-label="Open Troubleshooter Button"
            (click)="openTroubleshoot()"
          >
            {{localizationService.myLocalizationData.settings_panel.troubleshootText || 'Troubleshoot'}}
            <i class="far fa-play-circle"></i>
          </button>
        </span>
      <span class="full-width camera-rotation"  *ngIf="ngIf_CameraRotation">
          {{localizationService.myLocalizationData.settings_panel.camera_rotation || "Camera Rotation"}}
        <select
          [(ngModel)]="cameraRotations[rtcService.rtcClient.selectedPrimaryCameraOption.deviceId]"
          (ngModelChange)="setCameraRotation()"
          name="cameraRotation"
          class="form-control"
        >
              <option value="0">0°</option>
              <option value="-90">-90°</option>
              <option value="90">90°</option>
              <option value="180">180°</option>
          </select>
        </span>
    </div>
    <div>
        <span
          dropdown
          class="full-width">
          <button
            tabindex="0"
            aria-label="Dropdown List for Speaker Device Selection"
            class="btn btn-primary full-width dropdown-toggle testing-button"
            id="speaker-dropdown"
            dropdownToggle
          >
            <i
              class="fas fa-volume-up icon-large"
              aria-hidden="true"
            ></i>
            <label
              class="cursor-pointer"
            >
              {{rtcService.rtcClient.selectedSpeakerOption.label | deviceNameTranslate:
              localizationService.myLocalizationData.language}}
            </label>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu bg-white full-width"
            aria-labelledby="speaker-dropdown"
          >
            <li *ngFor="let speakerOption of deviceService.speakerOptions">
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectDevice('speaker',speakerOption)"
              >
                {{speakerOption.label | deviceNameTranslate: localizationService.myLocalizationData.language}}
              </a>
            </li>
          </ul>
        </span>
      <span class="full-width testing"  *ngIf="!isMobileApp">
          <button
            tabindex
            *ngIf="!speakerStartTesting"
            aria-label="Start Speaker Testing Button"
            class="btn btn-small btn-default"
            (click)="toggleSpeakerTesting()"
          >
            {{localizationService.myLocalizationData.settings_panel.speaker_setting.startTestingText}}
            <i class="far fa-play-circle"></i>
          </button>
          <button
            tabindex
            *ngIf="speakerStartTesting"
            aria-label="Stop Speaker Testing Button"
            class="btn btn-small btn-default"
            (click)="toggleSpeakerTesting()"
          >
            {{localizationService.myLocalizationData.settings_panel.speaker_setting.endTestingText}}
            <i class="far fa-stop-circle"></i>
          </button>
        </span>
      <span class="full-width volume">
          <i class="fa fa-volume-down"></i>
          <ngx-slider
            class="settings-slider"
            [(value)]="videoVolume"
            [options]="sliderOptions"
            (valueChange)="sliderChanged(videoVolume)">
          </ngx-slider>
          <i class="fa fa-volume-up"></i>
        </span>
      </div>

      <div
        *ngIf="localizationService.myLocalizationData.take_photo && !isKioskUnlocked && !isGuest()"
        class="btn-group row"
        role="group"
        aria-label="Enable cropper checkbox"
      >
        <label class="" for="cropper-box">Enable photo edit after capture</label>
        <input
          type="checkbox"
          class="btn btn-check"
          id="cropper-box"
          autocomplete="off"
          [checked]="cropperEnabled"
          (change)="toggleCropper()"
        >
      </div>

      <div class="">
        <span
          dropdown
          class="full-width"
        >
          <button
            tabindex="0"
            aria-label="Dropdown List for Video Resolution"
            class="btn btn-default full-width dropdown-toggle"
            id="resolution-dropdown"
            dropdownToggle
          >
            {{localizationService.myLocalizationData.settings_panel.resolution_setting.title +' (' +
          (rtcService.rtcClient.userSelectedPrimaryResolution | resolutionName) +')'}}
            <span class="caret"></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu bg-white full-width"
            aria-labelledby="resolution-dropdown"
          >
            <li>
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectPrimaryResolution()"
              >
                {{localizationService.myLocalizationData.settings_panel.resolution_setting.autoTitle || 'auto'}}
              </a>
            </li>
            <li *ngFor="let resolutionOption of rtcService.rtcClient.resolutionOptions">
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectPrimaryResolution(resolutionOption)"
              >
                {{resolutionOption | resolutionName: localizationService.myLocalizationData.language}}
              </a>
            </li>
          </ul>
        </span>
    </div>
    <div [hidden]="!rtcService.rtcClient.secondaryCameraEnabled">
        <span
          dropdown
          class="full-width"
        >
          <button
            tabindex="0"
            aria-label="Dropdown List for Secondary Camera Video Resolution"
            class="btn btn-default full-width dropdown-toggle"
            id="resolution-dropdown"
            dropdownToggle
          >
            {{(localizationService.myLocalizationData.settings_panel.resolution_setting.secondaryTitle || 'Secondary Resolution') +' (' +
          (rtcService.rtcClient.userSelectedSecondaryResolution | resolutionName) +')'}}
            <span class="caret"></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu bg-white full-width"
            aria-labelledby="resolution-dropdown"
          >
            <li>
              <a
                tabindex
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectSecondaryResolution()"
              >
                {{localizationService.myLocalizationData.settings_panel.resolution_setting.autoTitle || 'auto'}}
              </a>
            </li>
            <li *ngFor="let resolutionOption of rtcService.rtcClient.resolutionOptions">
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectSecondaryResolution(resolutionOption)"
              >
                {{resolutionOption | resolutionName: localizationService.myLocalizationData.language}}
              </a>
            </li>
          </ul>
        </span>
    </div>
    <div>
        <span
          dropdown
          class="full-width"
        >
          <button
            tabindex="0"
            aria-label="Dropdown List for Bandwidth"
            class="btn btn-default full-width dropdown-toggle"
            id="bandwidth-dropdown"
            dropdownToggle
            [ngClass]="{'disabled':started}"
          >
            {{localizationService.myLocalizationData.settings_panel.bandwidth_setting.title + ' (' +
          (rtcService.rtcClient.userSelectedVideoBandwidth ||
            localizationService.myLocalizationData.settings_panel.bandwidth_setting.autoTitle || 'auto') + ')'}}
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu bg-white full-width"
            aria-labelledby="bandwidth-dropdown"
          >
            <li>
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectBandwidth()"
              >
                {{localizationService.myLocalizationData.settings_panel.bandwidth_setting.autoTitle || 'auto'}}
              </a>
            </li>
            <li *ngFor="let bandwidthOption of rtcService.rtcClient.bandwidthOptions">
              <a
                tabindex="0"
                class="btn dropdown-item"
                (click)="selectBandwidth(bandwidthOption)"
              >
                {{bandwidthOption}}
              </a>
            </li>
          </ul>
        </span>
    </div>

    <!--VIDEO ASPECT-->
    <div class="video-aspect">
        <span
          dropdown
          class="full-width"
        >
          <button
            tabindex
            class="btn btn-default full-width dropdown-toggle"
            id="video-aspect-dropdown"
            dropdownToggle
          >
             {{localizationService.myLocalizationData.settings_panel.videoAspectTitle || 'Video Aspect'}} ({{videoAspect}})
            <span class="caret"></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu bg-white full-width"
            aria-labelledby="video-aspect-dropdown"
          >
            <li>
              <a
                tabindex
                class="btn dropdown-item"
                (click)="updateVideoAspect(VideoAspectEnum.fill)"
              >
                Fill
              </a>
            </li>
            <li>
              <a
                tabindex
                class="btn dropdown-item"
                (click)="updateVideoAspect(VideoAspectEnum.fit)"
              >
                Fit
              </a>
            </li>
          </ul>
        </span>
    </div>
  </div>
  <div *ngIf="showCustomizedVideoMutedPlaceholder">
    <label for="videoMutedPlaceholder"><b>Customized Video Muted Placeholder</b></label>
    <input
      class="form-control"
      type="text"
      [(ngModel)]="rtcService.rtcClient.avatarImageSrc"
    >
    <input
      type="file"
      (change)="uploadVideoMutedPlaceholder($event)"
      placeholder="Video Muted Placeholder"
      accept=".jpg,.jpeg,.png,.gif"
      id="videoMutedPlaceholder"
    >
    <button
      *ngIf="rtcService.rtcClient.avatarImageSrc"
      type="button"
      (click)="removeVideoMutedPlaceholder()"
      class="btn btn-danger"
    >Remove Video Muted Placeholder</button>
    <div *ngIf="rtcService.rtcClient.avatarImageSrc">
      <img
        [attr.src]="rtcService.rtcClient.avatarImageSrc"
        crossorigin="anonymous"
        alt="Video Muted Placeholder"
      >
    </div>
  </div>
  <div
    *ngIf="videoFilter && !isMobileDevice()"
    id="virtual-background-adjustment"
  >
    <div class="text-center">
      <button
        tabindex
        class="btn btn-link text-default"
        [attr.aria-label]="virtualBackgroundButtonAriaLabel"
        (click)="virtualBackgroundButtonFunction()"
      >
        <i
          class="{{virtualBackgroundIconClass}} icon-large"
          aria-hidden="true"
        ></i>
        <br>
        <label>
          {{virtualBackgroundButtonText}}
        </label>
      </button>
    </div>
    <div hidden>
      <h4>Virtual Background Adjustment</h4>
      <div>
        <label for="multiplier"><b>Multiplier</b> - The larger the value, the larger the size of the layers, and more
          accurate the model at the cost of speed. A smaller value results in a smaller model and faster prediction time
          but lower accuracy.</label>
        <input
          type="range"
          list="multiplier-options"
          step="0.25"
          min="0.5"
          max="1.00"
          [(ngModel)]="videoFilter.multiplier"
          class="slider form-control"
          id="multiplier"
        >
        <datalist id="multiplier-options">
          <option value="0.25">
          <option value="0.50">
          <option value="0.75">
          <option value="1.00">
        </datalist>
      </div>
      <div>
        <label for="stride"><b>OutputStride</b> - The smaller the value, the larger the output resolution, and more
          accurate the model at the cost of speed. A larger value results in a smaller model and faster prediction time
          but lower accuracy.</label>
        <input
          type="range"
          list="stride-options"
          min="8"
          max="32"
          [(ngModel)]="videoFilter.stride"
          class="slider form-control"
          id="stride"
        >
        <datalist id="stride-options">
          <option value="8">
          <option value="16">
          <option value="32">
        </datalist>
      </div>
      <div>
        <label for="quantBytes"><b>QuantBytes</b>- This argument controls the bytes used for weight quantization</label>
        <input
          type="range"
          list="quantBytes-options"
          min="1"
          max="4"
          [(ngModel)]="videoFilter.quantBytes"
          class="slider form-control"
          id="quantBytes"
        >
        <datalist id="quantBytes-options">
          <option value="1">
          <option value="2">
          <option value="4">
        </datalist>
      </div>
      <div>
        <label for="internalResolution"><b>Internal Resolution</b>- The internal resolution percentage that the input is resized to before inference. The larger the internalResolution the more accurate the model at the cost of slower prediction times. </label>
        <input
          type="range"
          list="internalResolution-options"
          min="0.25"
          max="1.00"
          step="0.25"
          [(ngModel)]="videoFilter.internalResolution"
          class="slider form-control"
          id="internalResolution"
        >
        <datalist id="internalResolution-options">
          <option value="0.25">
          <option value="0.50">
          <option value="0.75">
          <option value="1.00">
        </datalist>
      </div>
      <div>
        <label for="segmentationThreshold"><b>Segmentation Threshold</b> - a higher value will create a tighter crop around a person but may result in some pixels being that are part of a person being excluded from the returned segmentation mask.</label>
        <input
          type="range"
          step="0.1"
          min="0"
          max="1"
          [(ngModel)]="videoFilter.segmentationThreshold"
          class="slider form-control"
          id="segmentationThreshold"
        >
      </div>
    </div>
    <div *ngIf="videoFilter" [hidden]="!videoFilter.isActive">
      <h4>Virtual Background Adjustment</h4>
      <div>
        <label for="qualitySetting"><b>Performance &lt; - &gt; Quality </b> </label>
        <input
          type="range"
          step="1"
          min="1"
          max="4"
          [(ngModel)]="videoFilter.qualitySetting"
          class="slider form-control"
          id="qualitySetting"
        >
      </div>
    </div>
    <div *ngIf="videoFilter" [hidden]="!videoFilter.isActive">
      <h4>Virtual Background Adjustment</h4>
      <div>
        <label for="backgroundBlurAmount"><b>Background Blur Amount</b> - How many pixels in the background blend into
          each other</label>
        <input
          type="range"
          step="1"
          min="0"
          max="30"
          [(ngModel)]="videoFilter.backgroundBlurAmount"
          class="slider form-control"
          id="backgroundBlurAmount"
        >
      </div>
      <div>
        <label for="edgeBlurAmount"><b>Edge Blur Amount</b> - How many pixels to blur on the edge between the person and
          the background by</label>
        <input
          type="range"
          step="1"
          min="0"
          max="20"
          [(ngModel)]="videoFilter.edgeBlurAmount"
          class="slider form-control"
          id="edgeBlurAmount"
        >
      </div>
      <label for="virtualBackgroundImage"><b>Virtual Background Image</b></label>
      <input
        class="form-control"
        type="text"
        [(ngModel)]="videoFilter.virtualBackgroundImage"
      >
      <input
        #virtualBgSelect
        type="file"
        (change)="uploadVirtualBackgroundImage($event)"
        placeholder="Virtual Background Image"
        accept=".jpg,.jpeg,.png,.gif"
        id="virtualBackgroundImage"
      >
      <button
        *ngIf="videoFilter.virtualBackgroundImage"
        type="button"
        (click)="removeVirtualBackgroundImage()"
        class="btn btn-danger"
      >Remove Virtual Background Image</button>
      <div *ngIf="videoFilter.virtualBackgroundImage">
        <img
          [attr.src]="videoFilter.virtualBackgroundImage"
          crossorigin="anonymous"
          alt="Virtual Background Image"
        >
      </div>
    </div>
  </div>

  <!--SEND LOGS  -->
  <div id="send-logs" class="send-logs" *ngIf="(store.changes | async).settings.CollectLogs.enabled">
    <div class="logs-title">Logs Collector Settings</div>
    <div class="text-center">
      <div class="logs-switches">
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            class="custom-control-input"
            id="statistics"
            [checked]="(store.changes | async).settings.CollectLogs.webrtcStatistics"
            (change)="setLogsPermissions('statistics')"
          >
          <label class="custom-control-label" for="statistics">WebRTC Statistics</label>
        </div>
      </div>

      <button
        class="btn btn-success"
        style="width: 100%"
        (click)="sendLogs()"
      >SEND LOGS</button>
    </div>
  </div>

  <!--  TROUBLESHOOT-->
  <div *ngIf="!isMobileApp" id="troubleshoot-control" class="troubleshoot-control">
    <div class="text-center">
      <button
        tabindex
        class="btn"
        (click)="openTroubleshoot()"
      >
        <i
          class="fa fa-medkit icon-large"
          aria-hidden="true"
        ></i>
        <br>
        <label>
          {{localizationService.myLocalizationData.settings_panel.troubleshootText || 'Troubleshoot'}}
        </label>
      </button>
    </div>
  </div>

</div>
