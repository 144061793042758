/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { Component, Output, Input, EventEmitter, ViewChild, ElementRef, OnInit } from "@angular/core";
import {
  IUser,
  IUserService,
  Companion,
  ILocalization,
  IEndpointService,
  AlertLevel,
  Alert,
  AlertCode
} from "companion";
import { LocalizationService } from "../localization/localization.service";
import { RestService } from "../shared/services/rest.service";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { UserManagementService } from "../user-management/user-management.service";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "login-form",
  templateUrl: "./login-form.template.html",
})
export class LoginFormComponent implements OnInit {

  @Input("localizationData")
  localizationData: ILocalization;

  /**
   * user model for the form
   */
  userModel: IUser;
  /**
   * flag if form is submitted
   */
  submitted: boolean = false;
  /**
   * flag indicating we are trying to recover an account
   * shows the account email form to send recovery help to
   */
  recoveryInProgress: boolean = false;

  /**
   * Flag indicating we forgot our username
   */
  forgotUsername: boolean = false;

  /**
   * Flag indicating we forgot our password
   */
  resetPassword: boolean = false;

  /**
   * flag if login failed
   */
  loginFailed = false;
  /**
   * error message
   */
  errorMessage = "";
  /**
   * login event trigger
   */
  @Output("login") loginEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * email address used for recovering a forgotten username.
   */
  accountEmail: string;

  /**
   * user service from companion lib
   */
  userService: IUserService = Companion.getUserService();

  /**
   * endpoint service from companion lib
   */
  endpointService: IEndpointService = Companion.getEndpointService();

  @ViewChild("usernameField")
  usernameField: ElementRef;
  @ViewChild("emailAddressField")
  emailAddressField: ElementRef;

  @ViewChild("LoginForm")
  loginForm: NgForm;

  // retains the page we are on so we can get back to it upon password reset
  passwordResetCallback: ActionType;

  /**
   * component constructor
   */
  constructor(
    private restService: RestService,
    private router: Router,
    public localizationService: LocalizationService,
    private userManagementService: UserManagementService
  ) {
    this.userModel = this.userService.currentUser;
  }

  ngOnInit() {
    const urlTree = this.router.parseUrl(this.router.url);
    var sitepathitems = urlTree.toString().split("/");
    if (sitepathitems.findIndex((element) => { return element === "operators"; }) > 0) {
      this.passwordResetCallback = ActionType.OpenVCC;
    }
    else if (sitepathitems.findIndex((element) => { return element === "dashboard"; }) > 0) {
      this.passwordResetCallback = ActionType.OpenDashboard;
    }
    else {
      this.passwordResetCallback = ActionType.OpenMyAccount;
    }
  }

  /**
   * submit event handler
   */
  onSubmit(): void {
    if(this.submitted)
    {
      // prevent double submit
      return;
    }
    this.submitted = true;
    this.loginFailed = false;
    this.userModel.username = this.userModel.username.trim();
    let password: string = this.userModel.password;
    // check audio context ready for auto recording
    Companion.getRTCClient().prepareAudioContext();
    let loginMethod: (user: IUser, password?: string) => Promise<IUser>;
    if (this.userService.currentUser.isAuthenticated) {
      this.userModel = this.userService.currentUser;
      loginMethod = this.restService.loginWithoutPassword.bind(this.restService);
    } else {
      loginMethod = this.restService.login.bind(this.restService);
    }
    loginMethod(this.userModel, password)
    .then((result: IUser) => {
      this.submitted = false;
      this.userModel = result;
      this.userModel.password = null;
      _.assignIn(this.userService.currentUser, this.userModel);
    })
    .then(() => {
      let checkForTwoFactorAuthPromise = this.userService.currentUser.isTwoFactorAuthRequired
        ? this.userManagementService.promptForTwoFactorAuthTokenValidation()
        : Promise.resolve(true);
      return checkForTwoFactorAuthPromise.then((twoFactorVerified: boolean) => {
        if (twoFactorVerified) {
          return this.userManagementService.getUserBasicInfo();
        } else {
          return Promise.reject(new Error("TWO_FACTOR_AUTH_FAILED"));
        }
      });
    })
    .then(() => {
      if (this.userService.currentUser.requirePasswordReset) {
        alert("This password has expired and requires a reset!");
        (window as any).location = "/resetPassword/" + this.userService.currentUser.passwordResetToken;
        return Promise.reject(new Error("Require Password Reset"));
      } else if (this.userService.currentUser.isDisabled) {
        alert("This account has been disabled, please contact your system administrator.");
        this.submitted = false;
        this.loginFailed = true;
        this.errorMessage = "ACCOUNT_DISABLED";
        let error: any = {};
        error.message = "ACCOUNT_DISABLED";
        return Promise.reject(error);
      } else if (this.userService.currentUser.isLocked) {
        alert("This account has been locked out for to many password attempts.");
        this.submitted = false;
        this.loginFailed = true;
        this.errorMessage = "ACCOUNT_LOCKED";
        let error: any = {};
        error.message = "ACCOUNT_LOCKED";
        return Promise.reject(error);
      } else {
        return this.userManagementService.getMyPermissions();
      }
    })
    .then(() => {
      this.userModel.isAuthenticated = true;
      this.userService.currentUser.isAuthenticated = true;
    })
    .catch((err: any) => {
      this.submitted = false;
      this.loginFailed = true;
      this.errorMessage = err.message ? err.message : "LOGIN_FAILED";
      return Promise.reject(err);
    })
    .then(() => {
      return this.userManagementService.getUserWithRolesAndGroupsById(this.userService.currentUser["_id"]);
    })
    .then(() => {
      this.loginEmitter.emit();
    })
    .catch((err: Error) => {
      this.loginEmitter.emit(err.message ? err.message : "LOGIN_FAILED");
    });
  }

  addRoutePathParam(urlString: string) : string {
    if (urlString.startsWith('/')) {
      urlString = window.location.origin + urlString;
    }
    let url = new URL(urlString);
    let params = new URLSearchParams(url.search)
    params.set("returnTo", this.router.url);
    url.search = params.toString();
    return url.toString();
  }

  loginOpenIDC() { 
    // Trigger proper nav to loaded login URL route.
    window.location.href = this.addRoutePathParam(this.localizationData.loginPanel.loginWithOpenIDCUrl);
  }

  /**
   * get persence status by name
   */
  getPresenceStatusByName(): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/getPresenceStatusByName", { name: this.userModel.username })
      .subscribe(
        (data: string) => {
          resolve(data);
        },
        (error: any) => {
          Companion.getConferenceService().alertHandler(
            AlertCode.getPresenceStatusByNameFailed,
            error ? error.message : undefined
          );
          reject(error);
        }
      );
    });
  }
  
  /**
   * mouseover event handler on submit button
   */
  onMouseEnter(e: any) {
    let target: HTMLElement = e.target;
    target.style.backgroundColor = this.localizationData.loginPanel.submitButtonColor;
  }

  /**
   * mouseleave event handler on submit button
   */
  onMouseLeave(e: any) {
    let target: HTMLElement = e.target;
    target.style.backgroundColor = this.localizationData.loginPanel.submitButtonColor;
  }

  /**
   * User clicked the 'forgot username' link
   * @return false to not trigger a form submit
   */
  showForgotUsername(): boolean {
    this.submitted = false;
    this.recoveryInProgress = true;
    this.resetPassword = false;
    this.forgotUsername = true;
    this.updateView("");
    this.recoveryFocus();

    return false;
  }

  /**
   * User clicked the forgot password link
   */
  showForgotPassword(): boolean {
    this.submitted = false;
    this.recoveryInProgress = true;
    this.resetPassword = true;
    this.forgotUsername = false;
    this.updateView("");
    this.recoveryFocus();

    return false;
  }

  /**
   * Forgot username or passowrd form submitted.
   * @return false to not trigger a form submit
   */
  forgotUsernameSubmitted(): boolean {
    this.submitted = true;
    if (this.forgotUsername) {
      this.restService
        .post("/forgotUsername", { email: this.accountEmail })
        .subscribe(
          (data: string) => {
            this.submitted = false;
          },
          (error: any) => {
            this.submitted = false;
          }
        );

      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert("USERNAME_SENT_SUCCESS", "Username is sent to " + this.accountEmail, AlertLevel.success),
      });
    }

    if (this.resetPassword) {
      this.restService
        .post("/requestResetLinkForEmail", {
          email: this.accountEmail,
          theme: this.localizationService.myLocalizationData.style,
          queryParams: ("?passwordResetCallback=" + this.passwordResetCallback)
        })
        .subscribe(
          (data: string) => {
            this.submitted = false;
          },
          (error: any) => {
            this.submitted = false;
          }
        );

      Dispatcher.dispatch(ActionType.Alert, {
        alert: new Alert(
          "PASSWORD_RESET_SENT_SUCCESS",
          "Password reset link is sent to " + this.accountEmail,
          AlertLevel.success
        ),
      });
    }

    this.recoveryInProgress = false;
    return false;
  }

  /**
   * The user opted not to submit the form.
   * @return false to not trigger a form submit
   */
  forgotUsernameCancelled(): boolean {
    this.submitted = false;
    this.recoveryInProgress = false;
    this.defaultFocus();
    return false;
  }

  /**
   * update view with displaying error message or reset form
   * @param errorMsg?: string
   */
  updateView(errorMsg?: string) {
    if (!errorMsg) {
      this.submitted = false;
      this.loginFailed = false;
      this.errorMessage = "";
    } else {
      this.submitted = false;
      this.loginFailed = true;
      this.errorMessage = this.localizationService.myLocalizationData?.errorMessages?.[errorMsg] || errorMsg;
    }
  }

  /**
   * reset the form.
   */
  resetForm() {
    this.loginForm.form.markAsPristine();
    this.userModel.username = "";
    this.userModel.password = "";
    this.submitted = false;
    this.recoveryInProgress = false;
    this.forgotUsername = false;
    this.resetPassword = false;
    this.loginFailed = false;
    this.errorMessage = "";
  }

  defaultFocus() {
    let setFocusTimer = setTimeout(() => {
      clearTimeout(setFocusTimer);
      this.usernameField.nativeElement.focus();
    }, 500);
  }
  recoveryFocus() {
    let setFocusTimer = setTimeout(() => {
      clearTimeout(setFocusTimer);
      this.emailAddressField.nativeElement.focus();
    }, 500);
  }

  logout() {
    this.userService.currentUser.isAuthenticated = false;
    Dispatcher.dispatch(ActionType.LogOut);
  }
}
